import React from 'react'
import moment from 'moment'
import { DatePicker } from '../controls'
import { FILTER_DATE_RANGE } from '../../core/constants/app'
import { FormComboBox } from '../formControl'
import clsx from 'clsx'
import { endADay, startADay } from '../../utils/dates'

export const options = [
	{
		value: FILTER_DATE_RANGE.ALLTIME,
		label: FILTER_DATE_RANGE.ALLTIME,
		text: FILTER_DATE_RANGE.ALLTIME,
		startDate: null,
		endDate: null,
		index: 9,
	},
	{
		value: FILTER_DATE_RANGE.TODAY,
		label: FILTER_DATE_RANGE.TODAY,
		text: FILTER_DATE_RANGE.TODAY,
		startDate: startADay().format(),
		endDate: endADay().format(),
		index: 1,
	}, {
		value: FILTER_DATE_RANGE.YESTERDAY,
		label: FILTER_DATE_RANGE.YESTERDAY,
		text: FILTER_DATE_RANGE.YESTERDAY,
		startDate: startADay().subtract(1, 'days').startOf('day').format(),
		endDate: endADay().subtract(1, 'days').endOf('day').format(),
		index: 2,
	}, {
		value: FILTER_DATE_RANGE.THISWEEK,
		label: FILTER_DATE_RANGE.THISWEEK,
		text: FILTER_DATE_RANGE.THISWEEK,
		startDate: startADay().day('Sunday').format(),
		endDate: endADay().format(),
		index: 3,
	}, {
		value: FILTER_DATE_RANGE.LASTWEEK,
		label: FILTER_DATE_RANGE.LASTWEEK,
		text: FILTER_DATE_RANGE.LASTWEEK,
		startDate: startADay().day(-7).format(),
		endDate: endADay().day('Sunday').format(),
		index: 4,
	}, {
		value: FILTER_DATE_RANGE.THISMONTH,
		label: FILTER_DATE_RANGE.THISMONTH,
		text: FILTER_DATE_RANGE.THISMONTH,
		startDate: startADay().date(1).format(),
		endDate: endADay().format(),
		index: 5,
	}, {
		value: FILTER_DATE_RANGE.LASTMONTH,
		label: FILTER_DATE_RANGE.LASTMONTH,
		text: FILTER_DATE_RANGE.LASTMONTH,
		startDate: startADay().subtract(1, 'month').startOf('month').format(),
		endDate: endADay().subtract(1, 'month').endOf('month').format(),
		index: 6,
	}, {
		value: FILTER_DATE_RANGE.THISYEAR,
		label: FILTER_DATE_RANGE.THISYEAR,
		text: FILTER_DATE_RANGE.THISYEAR,
		startDate: startADay().startOf('year').format(),
		endDate: endADay().format(),
		index: 7,
	}, {
		value: FILTER_DATE_RANGE.LASTYEAR,
		label: FILTER_DATE_RANGE.LASTYEAR,
		text: FILTER_DATE_RANGE.LASTYEAR,
		startDate: startADay().subtract(1, 'year').startOf('year').format(),
		endDate: endADay().subtract(1, 'year').endOf('year').format(),
		index: 8,
	},
	{
		value: FILTER_DATE_RANGE.CUSTOM,
		label: FILTER_DATE_RANGE.CUSTOM,
		text: FILTER_DATE_RANGE.CUSTOM,
		startDate: startADay().format(),
		endDate: endADay().format(),
		index: 10,
	},
]

const FilterDateRange = ({ onChange, wrapperClass, ...props }) => {
	const [custom, setCustom] = React.useState({
		active: false,
		data: null,
	})

	const handleChange = (valueToChange) => {
		const data = options.find(el => el.value === valueToChange)
		if (valueToChange === FILTER_DATE_RANGE.CUSTOM) {
			setCustom({ ...custom, active: true, data })
		} else {
			setCustom({ ...custom, active: false, data: null })
		}

		if (typeof onChange === 'function') onChange(data)
	}
	

	const startDateOnChange = (date, customData) => {
		const newData = {
			...customData,
			data: {
				...customData.data,
				startDate: startADay(new Date(date)).format(),
			},
		}
		setCustom({ ...newData })
		if (typeof onChange === 'function') onChange({ ...newData.data })
	}

	const endDateOnChange = (date) => {
		const newData = {
			...custom,
			data: {
				...custom.data,
				endDate: endADay(new Date(date)).format(),
			},
		}
		setCustom(newData)
		if (typeof onChange === 'function') onChange({ ...newData.data })
	}

	const getOptionsStartDate = () => {
		return {
			dateFormat: 'm-d-Y',
			defaultDate: moment(custom.data.startDate || new Date()).toISOString(),
			onChange: (date) => {
				startDateOnChange(date, custom)
			},
		}
	}

	const getOptionsEndDate = () => {
		return {
			dateFormat: 'm-d-Y',
			defaultDate: moment(custom.data.endDate || new Date()).toISOString(),
			onChange: (date) => {
				endDateOnChange(date, custom)
			},
		}
	}

	return (
		<div
			className={clsx(wrapperClass, 'filter-date-range', {
				'd-block d-sm-grid': custom.active
			})}
		>
			<FormComboBox
				label={() => (
					<small className='text-secondary fs-sub text-uppercase fw-bold'>
						Date range
					</small>
				)}
				options={options}
				title='STATUS'
				fluid
				onChange={handleChange}
				{...props}
			/>
			{custom.active && (
				<span className='date-range hidden'>
					<DatePicker
						key='start-date'
						label='Start date'
						style={{ minHeight: '45px' }}
						options={getOptionsStartDate()}
					/>
					<span>to</span>
					<DatePicker
						key='end-date'
						label='End date'
						style={{ minHeight: '45px' }}
						options={getOptionsEndDate()}
					/>
				</span>
			)}

		</div>
	)
}

export default FilterDateRange
