import React, { useState } from 'react'
import SearchIcon from "../../../../assets/images/icons/search-icon.svg"
import { Col, Image, Row } from 'react-bootstrap';
import { FilterCompanies } from '../../../filter';
import SearchBox from '../../../searchBox';
import useContainerContext from '../../../containerContext';
import { useApi } from '../../../../hooks';

const Filters = ({ isScreenBiggerThanMD }) => {
    const [isShowFilter, setIsShowFilter] = useState(isScreenBiggerThanMD)
    const { setWorkflow, handleSearch, companies, setCurrentVendor, currentVendor } = useContainerContext()
    const { workflowService } = useApi();
    
    const onClickSearchIcon = () => {
        setIsShowFilter(!isShowFilter);
    }

    const handleOnChangeVendor = async (companyId) => {
        const vendor = companies.find(company => company.company === companyId);
        setCurrentVendor(vendor);

        const data = await workflowService.getWorkflow({ companyId });
        setWorkflow(data);
    }

	React.useEffect(() => {
        setIsShowFilter(isScreenBiggerThanMD)
    }, [isScreenBiggerThanMD])

    return (
        <div>
            <React.Fragment>
                <div>
                    {
                        !isScreenBiggerThanMD && (
                            <div className='d-flex justify-content-end position-relative' style={{ top: "-30px" }}>
                                <Image onClick={onClickSearchIcon} src={SearchIcon} alt="" height={20} width={20} />
                            </div>
                        )
                    }
                    {
                        isShowFilter && (
                            <div className='pt-4 workflow'>
                                <Row>
                                    <Col md={6}>
                                        <SearchBox autoComplete='off' onChangeMode onChange={handleSearch} />
                                    </Col>

                                    <Col md={3}>
                                        <FilterCompanies
                                            fluid
                                            isWorkflow
                                            placeholder='SELECT'
                                            className='light'
                                            size='medium'
                                            value={currentVendor?.company}
                                            onChange={(companyId) => handleOnChangeVendor(companyId)}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        )
                    }
	        	</div>
            </React.Fragment>
        </div>
    )
}

export default Filters