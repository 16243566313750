import React from 'react'
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import PaymentMethod from './payment'
import './payment.scss'

import { stripe as stripeConfig } from '../../core/config'
const stripePromise = loadStripe(stripeConfig.stripeKey)

const Index = ({ data, isInvoice = true }) => {
	return (
		<Elements stripe={stripePromise}>
			<ElementsConsumer>
				{({ stripe, elements }) => {
					if (!elements) return null
					return (
						<PaymentMethod
							stripe={stripe}
							elements={elements}
							data={data}
							isInvoice={isInvoice}
						/>
					)
				}}
			</ElementsConsumer>
		</Elements>
	)
}
export default Index
