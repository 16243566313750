import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { useQuery } from 'react-query'

import { useApi } from '../../hooks'
import { FormCheck } from '../formControl'

const FilterCompanies = ({ value, onChange, isCloseMenu }) => {
  const { mainService } = useApi()
	const [show, setShow] = useState(false)
  const [companies, setCompanies] = useState([])
	const [selected, setSelected] = useState(value || 'all')

	const Icon = show ? FaChevronUp : FaChevronDown

  useQuery(['companies'],
    () => mainService.getCompanies({ projection: { name: 1 } }), {
      onSuccess: data => setCompanies([{ _id: 'all', name: 'all' }, ...data.companies]),
      refetchOnWindowFocus: false
  })

	useEffect(() => {
		if (isCloseMenu) setShow(false)
	}, [isCloseMenu])

	useEffect(() => {
		if (value !== selected) setSelected(value)
	}, [selected, value])

	return (
		<div>
			<div className='p-2 border rounded'>
				<div
					className='text-dark fs-small d-flex justify-content-between'
					onClick={() => setShow(!show)}
				>
					Vendor
					<Icon className='ms-2' />
				</div>

				<div
					className={clsx('py-2', {
						'd-none': !show,
					})}
				>
					{companies.map(({ _id, name }) => {
						return (
							<div key={_id} className='fs-small d-flex align-items-center justify-content-between py-2'>
								<div>{name}</div>
								<span className='d-inline-block' style={{ width: '20px' }}>
									<FormCheck
										checked={selected === _id}
										readOnly={selected === _id}
										onChange={() => { onChange(_id) }}
									/>
								</span>
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}

export default FilterCompanies
