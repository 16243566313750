import * as Yup from 'yup'

export const schemaInvite = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
	firstName: Yup.string().required('First Name must Required'),
	lastName: Yup.string().required('Last Name must Required'),
})

export const schemaUpdate = Yup.object().shape({
	firstName: Yup.string().required('First Name must Required'),
	lastName: Yup.string().required('Last Name must Required'),
})
