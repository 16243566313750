import React from 'react'
import { Image } from 'react-bootstrap'
import WisetackLogoBig from '../../assets/images/wisetack_big.svg'
import { numberFormatCurrency } from '../../utils/helpers'
import { calcMonthlyPaymentWisetack } from '../../utils/invoice'
import { useApi } from '../../hooks'
import useAppContext from '../app'
import { toast } from 'react-toastify'

export const WisetackInfo = props => {
	const { setIsLoading } = useAppContext()
	const { record, isModal = false } = props
	const { total = 0, currency = 'usd', _id } = record
	const { paymentService } = useApi()

	const handleSeeOptions = () => {
		setIsLoading(true)
		paymentService.createWisetackLink({ recordId: _id })
			.then((response) => {
				const { paymentLink } = response
				window.location.href = paymentLink
			})
			.catch((error) => {
				toast.error(error.message || 'Something went wrong.')
			})
			.finally(() => {
				setIsLoading(false)
			})
	}

	return (
		<>
			<div className='wt-logo'>
				<Image src={WisetackLogoBig} alt='' />
				<p>Financing options to help you afford the work you need.</p>
			</div>
			{isModal && (
				<div className='wt-amount'>
					<p>
						<strong>Your Total Due</strong>
					</p>
					<p className='price'>{numberFormatCurrency(currency, total)}</p>
				</div>
			)}
			<div className='wt-headline'>
				{!isModal && (
					<p className='text-center'>
						<strong>Pay over time with Wisetack.</strong>
					</p>
				)}
				<p>
					<strong>
						Finance your payment starting at{' '}
						{numberFormatCurrency(currency || 'usd', calcMonthlyPaymentWisetack(record))}
						/month.*
					</strong>
				</p>
			</div>
			<div className='wt-info'>
				<p>-Instant qualification</p>
				<p>-Rates from 0% APR, no hidden fees</p>
				<p>-Checking eligibility will not impact your credit score</p>
			</div>
			<div className='wt-cta'>
				<button
					type='button'
					className='fw-bold text-uppercase btn btn-primary'
					onClick={handleSeeOptions}
				>
					See Options
				</button>
			</div>
			<p className='legal'>
				*All loans are subject to credit approval. Your terms my vary. Wisetack
				loans are issued by Hatch Bank, member FDIC. See www.wisetack.com/faqs
			</p>
		</>
	)
}

export default WisetackInfo
