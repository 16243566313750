import useTable from './useTable'
import HeaderCell from './HeaderCell'
import Row from './Row'
import { getHeaderKey, getRowKey, getColumnKey } from './utils'
import { Table } from 'react-bootstrap'
import clsx from 'clsx'

const TableConsumer = () => {
	const { data, columns, options } = useTable()
	const { rowKey, stickyHeader, rowComponent, onRowClick, className, fixed = false, ...opts } = options

	return (
		<div>
			<Table
				className={clsx(className, {
				'table-layout-fixed': fixed
				})}
				{...opts}
			>
				<colgroup>
					{columns.map(column => (
						<col
							key={getColumnKey(column.id)}
							width={column.width}
						/>
					))}
				</colgroup>
				<thead tw='bg-gray-50'>
					<tr>
						{columns.map(column => (
							<HeaderCell
								key={getHeaderKey(column.id)}
								scope='col'
								sticky={stickyHeader}
								column={column}
							/>
						))}
					</tr>
				</thead>
				<tbody>
					{data.map((row, index) => (
						<Row key={getRowKey(row[rowKey])} rowData={row} index={index} />
					))}
				</tbody>
			</Table>
		</div>
	)
}

export default TableConsumer
