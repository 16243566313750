import clsx from 'clsx'
import { Dropdown } from 'react-bootstrap'

const Select = ({
	options = [],
	selected,
	onChange,
	size,
	fluid,
	className = '',
	itemProps = {},
	toggleProps = {},
  menuProps={},
	...props
}) => {
	const selectedOpt = options.find(opt => opt.value === selected)
	return (
		<div>
			<Dropdown
				className={clsx(className, {
					'medium': size === 'medium',
					'fluid': fluid === true,
				})}
				{...props}
			>
				<Dropdown.Toggle {...toggleProps} className='form-group' role='button'>
					<span className='text-capitalize'>{selectedOpt?.label}</span>
				</Dropdown.Toggle>

				<Dropdown.Menu {...menuProps} className='overflow-auto shadow border-0'>
					{options.map(({ value, label, renderLabel }) => {
						const active = value === selected
						const { className: classNameItem, ...otherItemProps } = itemProps
						return (
							<Dropdown.Item
								active={active}
								className={clsx('fs-small dropdown-item text-break text-wrap', classNameItem)}
								key={`key-option-${value}`}
								onClick={() => {
									onChange(value)	
								}}
								{...otherItemProps}
							>
								{renderLabel ? renderLabel(active) : label}
							</Dropdown.Item>
						)
					})}
				</Dropdown.Menu>
			</Dropdown>
		</div>
	)
}

export default Select
