import { FILTER_PAYMENT_STATUS } from '../../core/constants/filter';
import { FormComboBox } from '../formControl';

const FilterPaymentStatus = ({ label = 'Payment Status', ...props}) => {
	return (
		<div>
			<FormComboBox
				label={() => (
					<small className='text-secondary fs-sub text-uppercase fw-bold'>
						{label}
					</small>
				)}
				options={FILTER_PAYMENT_STATUS}
				title='PAYMENT'
				{...props}
			/>
		</div>
	)
};

export default FilterPaymentStatus;
