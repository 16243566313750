import { Link, useLocation } from 'react-router-dom'
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap'
import { useMutation } from 'react-query'
import qs from 'qs'

import schema from './signup.schema'
import { FormInput, FormPassword } from '../../formControl'
import { useApi, useYupForm, useLoading } from '../../../hooks'

import toast from '../../toast'
import LayoutAuth from '../../LayoutAuth'

const SignUpUser = () => {
	const { mainService } = useApi()
  const location = useLocation()
  const query = qs.parse(location.search.slice(1))

	const { isLoading, mutate, isSuccess } = useMutation(mainService.signupUser, {
		onError: error => {
			toast.error({ title: 'Signup', body: error.message })
		},
	})

	useLoading(isLoading)

	const useForm = useYupForm(schema, {
		firstName: '',
		lastName: '',
		email: '',
		password: '',
		userInvitationToken: query?.token
	})

	const handleRegister = data => {
		mutate(data)
	}

	const {
		formState: { errors },
		register,
		handleSubmit,
	} = useForm

	return (
		<LayoutAuth>
			<Container style={{ maxWidth: '350px' }} className='flex-grow-1'>
				<Row className='py-5 text-center login h-100 align-items-center'>
					<Col sm={12} className='my-0 mx-auto'>
						<strong>Sign up</strong>
						{isSuccess && (
							<div>
								<p className='fs-sub mt-4 pt-3'>
									Success! Your account has been created. Check your email
									address to confirm your account setup.
								</p>

								<p className='mt-4'>
									Please,{' '}
									<Link to='/login' className='fw-bold text-uppercase fs-small'>
										Log In
									</Link>{' '}
								</p>
							</div>
						)}

						{!isSuccess && (
							<Form
								className='form form-default py-4'
								onSubmit={handleSubmit(handleRegister)}
							>
								<FormInput
									basic
									label='First Name'
									placeholder='Enter first Name'
									error={!!errors.firstName}
									errorMessage={errors.firstName?.message}
									wrapperClass='mb-3'
									{...register('firstName')}
								/>
								<FormInput
									basic
									label='Last Name'
									placeholder='Enter last Name'
									error={!!errors.lastName}
									errorMessage={errors.lastName?.message}
									wrapperClass='mb-3'
									{...register('lastName')}
								/>

								<FormInput
									basic
									label='Email'
									placeholder='example@gmail.com'
									error={!!errors.email}
									errorMessage={errors.email?.message}
									wrapperClass='mb-3'
									{...register('email')}
								/>

								<FormPassword
									basic
									type='password'
									label='Password'
									placeholder='Enter password'
									error={!!errors.password}
									errorMessage={errors.password?.message}
									togglePassword
									{...register('password')}
								/>

								<Button className='w-100 mt-5 text-uppercase' type='submit'>
									Create Account
								</Button>
								<p className='mt-4'>
									Already have an account yet?
									<Link to='/login' className='ms-2 fw-bold text-uppercase fs-small'>
										Log In
									</Link>{' '}
								</p>
							</Form>
						)}
					</Col>
				</Row>
			</Container>
		</LayoutAuth>
	)
}

export default SignUpUser
