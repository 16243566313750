import { orderBy } from 'lodash'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useApi } from '../../hooks'
import { FormSelectSearch } from '../formControl'

const optionAll = { value: 'all', label: 'All'}

const FilterCompanies = ({ label = 'Vendor', isWorkflow = false, placeholder = 'Type vendor name...', ...props}) => {
  const { mainService } = useApi()
  const [companies, setUsers] = useState([])
  const [query, setQuery] = useState({
    projection: { name: 1 }
  })
  const { isLoading } = useQuery(
    ['companies', query],
    () => mainService.getCompanies(query), {
      onSuccess: data => {
        const companies = orderBy(data.companies, ['name'], 'asc');
        setUsers(companies)
      },
      refetchOnWindowFocus: false
  })

  const comOpts = companies.map(({ company, name }) => ({ value: company, label: name }))
  const handleSearch = e => {
    setQuery({ ...query, search: e.target.value })
  }

  return (
    <FormSelectSearch
      label={() => (
        <small className='text-secondary fs-sub text-uppercase fw-bold'>
          {label}
        </small>
      )}
      options={isWorkflow ? [...comOpts] : [optionAll, ...comOpts]}
      onSearch={handleSearch}
      isLoading={isLoading}
      onScroll={() => {}}
      placeholder={placeholder}
			{...props}
    />
  )
}

export default FilterCompanies
