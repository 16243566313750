import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'

import InvoiceDetail from './InvoiceDetail'
import { ContainerProvider } from '../../containerContext'
import { useApi, useBreakpoint, useLoading } from '../../../hooks'
import useAppContext from '../../app'
import { Button, Container } from 'react-bootstrap'
import Riband from '../../Riband'

const Index = () => {
	const queryClient = useQueryClient()
  const [state, setState] = useState({})
	const params = useParams()
	const { invoiceService } = useApi()
	const { store: { isLog } } = useAppContext()
	const { invoiceId = '' } = params

	const { BREAKPOINT, screenBiggerThan} = useBreakpoint()
	const isBiggerThanXL = screenBiggerThan(BREAKPOINT.XL)

	const { isLoading, data, isSuccess } = useQuery(['invoice-detail'], () => {
		return invoiceService.get(invoiceId).then(({ invoice }) => invoice)
	}, {
		refetchOnWindowFocus: false,
	})

	useEffect(() => {
		return () => {
			queryClient.removeQueries(['invoice-detail'])
		}
	}, [queryClient])

	useLoading(isLoading)

	const closeModalPhoto = () => {
		setState(prev => {
			return { ...prev, vehicle: null, showModalPhoto: false, initialSlide: 0 }
		})
	}

	const showPhotoModal = (vehicle = {}, initialSlide = 0) => {
		setState(prev => {
			return { ...prev, vehicle, showModalPhoto: true, initialSlide }
		})
	}

	const toPayment = () => {
    setState(prev => ({ ...prev, isPayment: true}))
  }
	const toReceipt = (paymentData) => {
    setState(prev => ({ ...prev, isReceipt: true, isPayment: false, paymentData }))
  }

  const hidePayment = () => {
    setState(Prev => ({ ...Prev, isPayment: false, showAmount: false}))
  }

	const hideReceipt = () => {
    setState(prev => ({ ...prev, isReceipt: false, showAmount: false, paymentData: null}))
  }

	return (
		<ContainerProvider
			state={state}
			setState={setState}
			data={data}
			toPayment={toPayment}
      hidePayment={hidePayment}
			toReceipt={toReceipt}
			hideReceipt={hideReceipt}
			closeModalPhoto={closeModalPhoto}
			showPhotoModal={showPhotoModal}
		>
			<div className='w-100 d-flex flex-column'>
				{!isLog && data?.client?._id?.canAccessPortal && (
					<Riband />
				)}
				<Container fluid={!isBiggerThanXL}>
					{isSuccess && <InvoiceDetail />}
				</Container>
			</div>


		</ContainerProvider>
	)
}

export default Index
