import { Button, FormControl } from 'react-bootstrap'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import './paging.scss'

const Paging = ({ skip, limit, onChange, total }) => {

  const next = () => {
		onChange({ skip: parseInt(skip) + parseInt(limit), limit: parseInt(limit) });
	}

  const prev = () => {
		onChange({ skip: parseInt(skip) - parseInt(limit), limit: parseInt(limit) });
	}

  const onLimit = ({ target: { value } }) => {
    onChange({ skip: 0, limit: parseInt(value) })
  }

  return (
    <div className='paging position-sticky bottom-0 d-flex flex-sm-direction-column justify-content-between'>
      <div className='d-inline-flex align-items-center'>
        <small className='me-2 fw-bold'>Show</small>
        <FormControl
          style={{ width: '100px' }}
          as='select'
          defaultValue={limit}
          onChange={onLimit}
        >
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </FormControl>
        <small className='ms-2 fw-bold'>Items</small>
      </div>

      <div className='flex-grow-1 text-end'>
        {skip !== 0 && (
          <Button variant='default' className='text-primary fw-bold' onClick={prev}>
            <BsChevronLeft className='me-3 text-dark' size={25} />
            Prev
          </Button>
        )}
        <Button variant='light' className='bg-white shadow-sm'>
          {parseInt(skip, 10) / parseInt(limit, 10) + 1}
        </Button>
        {total >= parseInt(limit, 10) && (
          <Button variant='default' className='text-primary fw-bold' onClick={next}>
            Next
            <BsChevronRight className='ms-3 text-dark' size={25} />
          </Button>
        )}
      </div>
    </div>
  )
}

export default Paging
