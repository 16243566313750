import React from 'react'
import Slider from 'react-slick'
import useContainerContext from '../../containerContext'
import Element from './Element'

const WorkflowMobile = ({ listStages }) => {
  const { handleOnScroll } = useContainerContext()

  const handleLoadmore = (target, stageId) => {
    if (target.scrollTop + target.clientHeight >= target.scrollHeight) {
        handleOnScroll(stageId)
        console.log('loadmore');
    }
  }

	return (
		<div className='workflow-mobile'>
			<Slider
				dots
				infinite={false}
				arrows={false}
				speed={500}
				slidesToShow={1}
				slidesToScroll={1}
			>
				{listStages.map(stage => (
					<div className='column' key={stage._id} onScroll={({target}) => handleLoadmore(target, stage._id)}>
						<div
							className='title d-flex justify-content-between align-items-center crop-text'
							style={{ backgroundColor: `${stage.color}` }}
						>
							<div className='crop-text'>{stage.name}</div>
						</div>
						<Element stage={stage} />
					</div>
				))}
			</Slider>
		</div>
	)
}

export default WorkflowMobile
