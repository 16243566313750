import React, { useState } from 'react'
import { Col, Container, Image, Modal, Row, Button } from 'react-bootstrap'
import car from '../../assets/images/payment/Car.svg'
import useContainerContext from '../containerContext'
import PaymentForm from './paymentForm'
import { numberFormatCurrency } from '../../utils/helpers'
import CompanyLogo from '../companyInfo/Logo'
import card from '../../assets/images/payment/Card.svg'
import payByCreditCard from '../../assets/images/payment/credit-card.png'
import payByCreditCardActive from '../../assets/images/payment/credit-card-active.png'
import payByWisetack from '../../assets/images/payment/wisetack.png'
import payByWisetackActive from '../../assets/images/payment/wisetack-active.png'
import WisetackInfo from '../WisetackInfo'

const PaymentMethod = ({ stripe, elements, data, isInvoice }) => {
	const { hidePayment } = useContainerContext()

	const {
		total = 0,
		currency = 'usd',
		number,
		client,
		payment: { amount = 0 },
		depositFlat = 0,
	} = data

	const { group } = client._id

	const company = (() => {
		if (
			group &&
			group.companyOverride &&
			group.companyOverride &&
			group.companyOverride.active
		)
			return group.companyOverride
		return data.company
	})()
	const { wisetack = {} } = data.company
	const { merchantId = '' } = wisetack
	const enabledWisetack = isInvoice && merchantId && (total - amount) >= 500

	const [isPayWithCreditCard, setPayWithCreditCard] = useState(true)

	return (
		<Modal
			show
			dialogClassName='modal-fullscreen-lg-down modal-lg modal-xl'
			onHide={() => {}}
		>
			<Modal.Header closeButton onHide={hidePayment} className='me-3 mt-3' />
			<Modal.Body className='p-4 mx-3'>
				<Container fluid>
					<Row>
						<Col md={6} lg={8} className='text-center'>
							<CompanyLogo logo={company.logo || ''} className='mx-auto' />
							<h5 className='fw-bold mt-2 mb-3'>{company.name}</h5>
							<strong>
								{isInvoice ? `INVOICE # ${number}` : `ESTIMATE # ${number}`}
							</strong>
							<p>for: {client.name || ''}</p>
							{isInvoice ? (
								<>
									<p className='record-due'>
										{numberFormatCurrency(currency, total - amount)}
									</p>
									<p>Balance Due</p>
									<div className='text-sub'>
										Job Total: {numberFormatCurrency(currency, total)}
									</div>
								</>
							) : (
								<>
									<p className='record-due'>
										{numberFormatCurrency(currency, depositFlat - amount)}
									</p>
									<p>Deposit Due</p>
								</>
							)}
							<Image src={car} width='100%' className='text-secondary mt-4' />
							<div className='text-sub mt-4'>
								Powered by{' '}
								<h6 className='text-dark fw-bold d-inline-block m-0'>stripe</h6>
							</div>
							<div className='text-sub'>
								This charge will appear as “Stripe Transfer” on your statement
							</div>
						</Col>
						<Col className='shadow-lg p-3'>
							<div className='text-center'>
								<h6 className='my-4 fw-bold d-inline-block'>Payment Info</h6>
								<Image src={card} className='ms-2' />
							</div>
							{enabledWisetack && (
								<div className='wt-tabs'>
									<button
										type='button'
										className={isPayWithCreditCard ? 'active' : ''}
										onClick={() => setPayWithCreditCard(true)}
									>
										Credit Card{' '}
										<Image src={isPayWithCreditCard ? payByCreditCardActive : payByCreditCard} alt="" />
									</button>
									<button
										type='button'
										className={!isPayWithCreditCard ? 'active' : ''}
										onClick={() => setPayWithCreditCard(false)}
									>
										Use Wisetack
										<Image src={!isPayWithCreditCard ? payByWisetackActive : payByWisetack} alt="" />
									</button>
								</div>
							)}
							{isPayWithCreditCard ? (
								<PaymentForm
									stripe={stripe}
									elements={elements}
									data={data}
									isInvoice={isInvoice}
								/>
							) : (
								<div className='tab-wisetack'>
									<WisetackInfo record={data} isModal={false} />
								</div>
							)}
						</Col>
					</Row>
				</Container>
			</Modal.Body>
		</Modal>
	)
}
export default PaymentMethod
