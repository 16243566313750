import { Col, Row } from 'react-bootstrap'
import { useBreakpoint } from '../../../hooks'

import useContainerContext from '../../containerContext'
import { RecordInfoMobile, RecordInfoDesktop } from '../../RecodeInfo'
import { VehicleMobile, VehicleDesktop } from '../../vehicle'
import CompanyInfo from '../../companyInfo'
import GroupButton from './groupButton'
import ModalPaymentInfo from '../../paymentInfo'
import ModalReceipt from '../../paymentInfo/receipt'
import ModalPhotoList from '../../ModalPhotoList'
import useAppContext from '../../app'

const InvoiceDetail = () => {
	const { store: { isLog } } = useAppContext();
	const { data, state, showPhotoModal, closeModalPhoto } = useContainerContext();
	const { showModalPhoto, vehicle, initialSlide, isPayment, isReceipt } = state;
	const { company, vehicles = [], client: { _id: client = {} } } = data;
	const { BREAKPOINT, screenBiggerThan } = useBreakpoint();
	const isBiggerThanMD = screenBiggerThan(BREAKPOINT.MD);
	let isShowPdf = true;
	let isShowLinkToPay = true;
	let showAdasChecklists = false;
	let hasDisclosure = false;
	let lang;

	if (!isLog) {
		const token = localStorage['access_token'];
		const payload = token ? JSON.parse(window.atob(token.split('.')[1])) : null
		isShowPdf = payload?.pdf;
		isShowLinkToPay = payload?.linkToPay;
		showAdasChecklists = payload?.adasChecklists;
		hasDisclosure = payload?.hasDisclosure;
		lang = payload?.language;
	}

	const companyProps = (() => {
		if (client.group && client.group.companyOverride && client.group.companyOverride && client.group.companyOverride.active) return client.group.companyOverride;
		return company;
	})();

	return (
		<div className='my-5 px-sm-3'>
			<Row>
				<Col md={8}>
					<CompanyInfo company={companyProps} isBiggerThanMD={isBiggerThanMD} />
				</Col>
				<Col sm={8} md={4} className='mx-auto'>
					<GroupButton isBiggerThanMD={isBiggerThanMD} isShowLinkToPay={isShowLinkToPay} />
				</Col>
			</Row>

			<Row>
				<Col md={12}>
					{isBiggerThanMD && (
						<div className='pt-4'>
							<div className='mt-4 py-4 bg-white shadow-lg rounded'>
								<RecordInfoDesktop
									record={data}
									isInvoice={true}
									isShowPdf={isShowPdf}
									hasDisclosure={hasDisclosure}
									lang={lang} />
								{vehicles.map((vehicle, index) => {
									return (
										<VehicleDesktop
											key={index}
											index={index.toString()}
											vehicle={vehicle}
											record={data}
											onShowPhoto={showPhotoModal}
											showInvAdasChecklists={showAdasChecklists}
										/>
									)
								})}
							</div>
						</div>
					)}
					{!isBiggerThanMD && (
						<div className='mt-4 py-4 bg-white shadow-lg'>
							<RecordInfoMobile
								record={data}
								isInvoice={true}
								isShowPdf={isShowPdf}
								hasDisclosure={hasDisclosure}
								lang={lang} />

							{vehicles.map((vehicle, index) => {
								return (
									<VehicleMobile
										key={index}
										index={index.toString()}
										vehicle={vehicle}
										record={data}
										onShowPhoto={showPhotoModal}
										showInvAdasChecklists={showAdasChecklists}
									/>
								)
							})}
						</div>
					)}
				</Col>
			</Row>

			{isPayment && <ModalPaymentInfo data={data} />}
			{isReceipt && <ModalReceipt />}
			{showModalPhoto && <ModalPhotoList vehicle={vehicle} initialSlide={initialSlide} onClose={closeModalPhoto} isInvoice={true} />}
		</div>
	)
}
export default InvoiceDetail
