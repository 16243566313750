import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import './index.scss'

const ModalReject = ({ isShow, closeModal, title, body }) => {
  return (
    <div>
        <Modal show={isShow} onHide={closeModal} dialogClassName='modal-reject' backdrop='static' centered>
            <Modal.Header closeButton className='mx-3 mt-3'>
                <h4 className='mb-0 font-weight-bold'>{title}</h4>
            </Modal.Header>
            <Modal.Body>
                <div className='px-4 text-center wrapper'>
                    <span>{body}</span>
                    <Button className='w-50 mt-4' onClick={closeModal} variant='primary'>GOT IT</Button>
                </div>
            </Modal.Body>
        </Modal>
    </div>
  )
}

export default ModalReject;