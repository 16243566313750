import React from 'react'
import { Form } from 'react-bootstrap'

const FormLabel = ({ label = '' }) => {
	const labelValue = typeof label === 'function' ? label() : label

	return (
		label && <Form.Label className='w-100 text-start'>{labelValue}</Form.Label>
	)
}
export default FormLabel
