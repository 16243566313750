import { numberFormatCurrency } from './helpers'
import { downloadImage, downloadZipFile } from './download'
import { paymentMethods, SERVER_DATA } from '../core/constants/app'

export const calcMonthlyPaymentWisetack = record => {
	const balanceDue =
		Math.round((record.total - record.payment.amount) * 100) / 100

	const a = Number(balanceDue) // amount of loan
	const r = Number(0.099 / 12) // annual rate / 12 months
	const n = Number(24) // number of months
	return Number(a / (((1 + r) ** n - 1) / (r * (1 + r) ** n)))
}

export const discountPercent = record => {
	const { discountAmount, total, taxLaborAmount } = record;
	if (discountAmount === 0) return `${discountAmount.toFixed(2)}%`;
	return `${(discountAmount / (discountAmount + total - taxLaborAmount) * 100).toFixed(2)}%`;
}

export const taxPercent = invoice => {
	const { taxLabor = false, taxParts = false, tax = 0 } = invoice
	if (!taxLabor && !taxParts) return `0.00%`
	return  `${(tax * 100).toFixed(2)}%`
}

export const taxTotal = invoice => {
	const {
		taxLabor = false,
		taxParts = false,
		taxLaborAmount = 0,
		taxPartsAmount = 0,
		currency,
	} = invoice
	const total = taxLabor ? taxLaborAmount : 0
	return numberFormatCurrency(
		currency,
		taxParts ? total + taxPartsAmount : total,
	)
}

export const isPaymentOffline = method => method.includes('offline')

export const paymentMethod = method => {
	const _method = isPaymentOffline(method) ? 'offline' : method
	return paymentMethods[_method] || 'other'
}

export const paymentType = method => {
	if (isPaymentOffline(method)) return 'Offline Payment:'
	if (method === 'creditcard') return 'Stripe Verified Payment:'
	return 'Payment'
}

export const getCarInfo = (vehicle = {}) => {
	const { make = '', model = '', color = '' } = vehicle
	return `${make.trim()} ${model.trim()}${color ? `, ${color.trim()}` : ''}`.trim()
}

export const getServiceLines = (vehicle = {}) => {
	const { serviceLines = {} } = vehicle
	const serviceLinesKeys = Object.keys(serviceLines).map(e => serviceLines[e])
	const services = serviceLinesKeys.join(' ')
	return services.trim() ? services.trim() : 'Not listed'
}

export const getService = (vehicle, key) => {
	const { serviceLines = {} } = vehicle
	return serviceLines[key] || ''
}

export const getDocuments = vehicle => {
	const { carADAS = { documents: [] } } = vehicle
	let filesDocument = []

	carADAS.documents.forEach(({ name, files }) => {
		const arr = files.map(({ fileURL }) => {
			return { fileName: name, path: `${SERVER_DATA}${fileURL}` }
		})
		filesDocument = filesDocument.concat(arr)
	})

	return filesDocument
}

export const getPhotosVehicle = vehicle => {
	const { photos = [], carADAS = { photos: [] }, videos = [] } = vehicle
	return [
		...photos,
		...carADAS.photos,
		...videos.map((video) => ({
			...video,
			getURL: `${SERVER_DATA}/${video.videoURL}`,
			url: video.thumbnailURL
		}))
	]
}

export const getScansVehicle = vehicle => {
	let { scans = [] } = vehicle
	scans = scans.map(({scanType, files}) =>
		({ scanType, path: `${SERVER_DATA}/${files.url}` })
	)
	return scans
}

export const getVideosVehicle = vehicle => {
	const { videos = [] } = vehicle
	return videos
}

export const downloadPhoto = (photoObj, callback) => {
	downloadImage(`${photoObj.getURL}`, photoObj.name, callback);
}

export const downloadZipPhotos = (vehicle, callback) => {
	const photos = getPhotosVehicle(vehicle).map((veh) => {
		return {
			fileName: veh.name, path: `${veh.getURL}`
		}
	})

	const zipName = getCarInfo(vehicle)
	downloadZipFile(zipName, photos)
		.then(() => {
			return callback(true)
		})
		.catch(() => {
			return callback(false)
		})
}

export const getPhotos = (records) => {
	const { vehicles = [] } = records
	let listPhoto = []
	vehicles.forEach(vehicle => {
		const photos = getPhotosVehicle(vehicle).map((photo) => {
			return { fileName: photo.name, path: `${SERVER_DATA}/${photo.url}` }
		})
		if (photos.length > 0) {
			listPhoto = listPhoto.concat(photos)
		}
	})
	return listPhoto
}

export const downloadAllFiles = (vehicle, callback) => {
	const photos = getPhotosVehicle(vehicle).map(({ name, getURL }) => {
		return { fileName: name, path: `${getURL}` }
	})

	const documents = getDocuments(vehicle)
	downloadZipFile(getCarInfo(vehicle), [...photos, ...documents])
		.then(() => {
			return callback(true)
		})
		.catch(() => {
			return callback(false)
		})
}

export const downloadPhotosRecord = (records, callback) => {
	const {
		vehicles = [],
		client: { name } = '',
		company: { name: companyName },
		number
	} = records
	let listPhoto = []
	vehicles.forEach(vehicle => {
		const documents = getDocuments(vehicle)
		if (documents.length > 0) {
			listPhoto = listPhoto.concat(documents)
		}
		const photos = getPhotosVehicle(vehicle).map(({ name, url }) => {
			return { fileName: name, path: `${SERVER_DATA}/${url}` }
		})
		if (photos.length > 0) {
			listPhoto = listPhoto.concat(photos)
		}
	})
	downloadZipFile(name || `${companyName}${number}`, listPhoto)
		.then(() => {
			return callback(true)
		})
		.catch(() => {
			return callback(false)
		})
}
