import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import Logout from './components/Logout'

import routes from './core/constants/routes'
import {
	LoginContainer,
	SignUpContainer,
	SignUpUserContainer,
	VerifyContainer,
	EstimatesContainer,
	EstimatesDetailContainer,
	InvoicesContainer,
	InvoiceDetailContainer,
	AccountSettingContainer,
	NotFoundPage,
	ForgotPasswordContainer,
	ResetPasswordContainer,
	ManageUsersContainer,
	WorkFlowContainer
} from './containers'
import useAppContext from './components/app'
const {
	LOGIN,
	SIGNUP,
	SIGNUPUSER,
	VERIFY,
	INVOICES,
	INVOICEDETAIL,
	ESTIMATES,
	ESTIMATESDETAIL,
	ACCOUNTSETTING,
	FORGOTPASSWORD,
	RESETPASSWORD,
	MANAGEUSERS,
	WORKFLOW
} = routes

const PrivateRoute = ({ component: Component, isShare = false, ...rest }) => {
	const { store: { clientPortalUser } } = useAppContext()
	return (
		<Route
			{...rest}
			render={props => {
				if (!localStorage.access_token) return <Logout />
				if (!clientPortalUser && !isShare)return <Logout />
				return <Component {...props} />
			}}
		/>
	)
}

const App = () => {
	const { store: { clientPortalUser } } = useAppContext()
	return (
		<Switch>
			<Redirect exact path='/' to={INVOICES} />
			<Route exact path={LOGIN} component={LoginContainer} />
			<Route exact path={SIGNUP} component={SignUpContainer} />
			<Route exact path={SIGNUPUSER} component={SignUpUserContainer} />
			<Route exact path={FORGOTPASSWORD} component={ForgotPasswordContainer} />
			<Route exact path={RESETPASSWORD} component={ResetPasswordContainer} />
	 		<Route exact path={VERIFY} component={VerifyContainer} />
			<PrivateRoute exact path={ACCOUNTSETTING} component={AccountSettingContainer} />
			<PrivateRoute exact path={ESTIMATES} component={EstimatesContainer} state={{ from: ESTIMATES }} />
			<PrivateRoute exact path={ESTIMATESDETAIL} component={EstimatesDetailContainer} isShare />
			<PrivateRoute exact path={INVOICES} component={InvoicesContainer} />
			<PrivateRoute exact path={INVOICEDETAIL} component={InvoiceDetailContainer} isShare />
			<PrivateRoute exact path={WORKFLOW} component={WorkFlowContainer} />
			{clientPortalUser && clientPortalUser.admin && <PrivateRoute exact path={MANAGEUSERS} component={ManageUsersContainer} />}
			<Route path='*'>
				<NotFoundPage />
			</Route>
		</Switch>
	)
}

export default App
