import * as Yup from 'yup'

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
export const paymentReceipt = Yup.object().shape({
	'email': Yup.string()
							.when('phone', {
								is: (phone) => !phone || phone.length === 0,
								then: Yup.string('At least one of the fields is required.')
									.required('At least one of the fields is required.')
									.email('Email address is not valid.'),
							}),
	'phone': Yup.string()
							.when('email', {
								is: (email) => !email || email.length === 0,
								then: Yup.string('At least one of the fields is required.')
									.required('At least one of the fields is required.')
									.matches(phoneRegExp, 'Phone number is not valid.'),
							}),
}, ['email', 'phone'])

export const paymentInfoDifferent = Yup.object().shape({
	amount: Yup.number().typeError('Amount must be number').required('Amount is required.'),
	zipCode: Yup.number().typeError('Zip code must be number').required('Zip code is required.'),
	name: Yup.string().required('Name is required.'),
})


export const paymentInfoFull = Yup.object().shape({
	amount: Yup.number().min(1).required('Amount is required.'),
	zipCode: Yup.number().typeError('Zip code must be number').required('Zip code is required.'),
	name: Yup.string().required('Name is required.'),
	creditCard: Yup.boolean().oneOf([true], 'Field must be completed.'),
	cardExpiry: Yup.boolean().oneOf([true], 'Field must be completed.'),
	cardCvc: Yup.boolean().oneOf([true], 'Field must be completed.'),
})
