import { useBreakpoint } from '../../../hooks'
import Filter from './Filter'
import TableDesktop from './Table.desktop'
import TableMobile from './Table.mobile'
import Paging from '../../paging'
import SortDropdown from '../../sortDropdown'
import useContainerContext from '../../containerContext'
import FilterMobile from './Filter.Mobile'

const listSort = [
	{ label: 'Date', sortKey: 'dateCreated' },
	{ label: 'Vendor', sortKey: 'company.name' },
	{ label: 'Estimate #', sortKey: 'number' },
	{ label: 'Total', sortKey: 'total' },
]

const Estimates = () => {
	const { BREAKPOINT, screenBiggerThan } = useBreakpoint()
	const isScreenBiggerThanMD = screenBiggerThan(BREAKPOINT.MD)

	const {
		setState,
		data,
		state,
		state: { sorted, query},
	} = useContainerContext()

	const onSort = sortChange => {
		setState(prev => ({ ...prev, sorted: sortChange }))
	}

	return (
		<div className='my-5'>
			<h5 className='text-center text-md-start fw-bold'>ESTIMATES SUMMARY</h5>

			<Filter isScreenBiggerThanMD={isScreenBiggerThanMD} />

			{isScreenBiggerThanMD && <TableDesktop />}
			{!isScreenBiggerThanMD && (
				<div>
					<div className='text-end'>
            <div className='d-inline-block'>
							<SortDropdown
								listSort={listSort}
								sorted={sorted}
								onSort={onSort}
							/>
						</div>
						<div className='d-inline-block'>
							<FilterMobile />
						</div>
					</div>
					<TableMobile />
				</div>
			)}
			<Paging
				total={data.length}
				limit={state.query.limit}
				skip={state.query.skip}
				onChange={({ skip, limit }) => {
					setState({
						...state,
						query: { ...query, skip, limit },
					})
				}}
			/>
		</div>
	)
}

export default Estimates
