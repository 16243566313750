import { FormComboBox } from '../formControl';

const FilterStatus = (props) => {
	return (
		<div>
			<FormComboBox
				label={() => (
					<small className='text-secondary fs-sub text-uppercase fw-bold'>
						Status
					</small>
				)}
				options={[
					{ value: 'all', label: 'All' },
					{ value: false, label: 'Approved' },
					{ value: true, label: 'Not Approved' },
				]}
				title='STATUS'
				{...props}
			/>
		</div>
	)
};

export default FilterStatus;
