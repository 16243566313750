import { Button, Form } from 'react-bootstrap'
import { useMutation, useQueryClient } from 'react-query'

import { FormInput } from '../../formControl'
import { useApi, useLoading, useYupForm } from '../../../hooks'
import { schemaUpdate } from './schema'
import toast from '../../toast'
import useContainerContext from '../../containerContext'

const UpdateUser = () => {
  const { memberSelected, updateManagerState } = useContainerContext()
  const queryClient = useQueryClient()
  const { mainService } = useApi()
  const { isLoading, mutate: edit } = useMutation(async (data) => {
    await mainService.editMember(data)
    await queryClient.invalidateQueries(['list-user'])
  }, {
    onSuccess: () => {
      toast.success({ title: 'User Updated', body: 'success' })
      updateManagerState({ memberSelected: null })
    },
    onError: () => toast.success({ title: 'User Updated', body: 'Fail' })
  })

  useLoading(isLoading)

  const defaultValue = {
		firstName: memberSelected?.firstName,
		lastName: memberSelected?.lastName,
	}

	const { handleSubmit, register, formState: { errors } } = useYupForm(schemaUpdate, defaultValue)

  const onSubmit = data => {
    edit({ ...memberSelected, ...data })
  }

	return (
		<Form onSubmit={handleSubmit(onSubmit)} className='form form-default py-4 px-3 mt-3 h-100'>
			<div className='pb-5'>
        <div className='fs-6 text-start pb-3'>
          <strong>Edit User info</strong>
        </div>
        <FormInput
          label='Email'
          disabled
          placeholder='Type email here'
          wrapperClass='mb-3'
          className='bg-body'
          value={memberSelected?.email}
        />
        <FormInput
          basic
          label='First Name'
          placeholder='Type First Name'
          error={errors?.firstName}
          errorMessage={errors?.firstName?.message}
          wrapperClass='mb-3'
          {...register('firstName')}
        />

        <FormInput
          basic
          label='Last Name'
          placeholder='Type Last Name'
          error={errors?.lastName}
          errorMessage={errors?.lastName?.message}
          {...register('lastName')}
        />
      </div>

      <Button type='submit' className='w-100 mt-4 fs-7 text-uppercase'>
        Save Changes
      </Button>
		</Form>
	)
}

export default UpdateUser
