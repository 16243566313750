import { BiPhone } from 'react-icons/bi'

const CompanyPhone = ({ phone }) => {
	return (
		<div className='mt-2'>
			<BiPhone className='text-primary me-1' />
			<span>{phone}</span>
		</div>
	)
}

export default CompanyPhone

