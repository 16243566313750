import React from 'react'
import { Modal } from 'react-bootstrap'
import './style.scss'
import WisetackInfo from '../WisetackInfo'

export const ModalWisetack = props => {
	const { show, record } = props

	const handleClose = () => {
		const { onHide } = props
		onHide()
	}

	return (
		<Modal show={show} onHide={handleClose} className='model-wisetack'>
			<Modal.Header closeButton></Modal.Header>
			<Modal.Body className='text-center'>
				<WisetackInfo record={record} isModal={true} />
			</Modal.Body>
		</Modal>
	)
}

export default ModalWisetack
