import clsx from 'clsx'
import React from 'react'
import { Form } from 'react-bootstrap'
import FormLabel from './FormLabel'
const FormInput = React.forwardRef(
	(
		{
			basic,
			error,
			errorMessage,
			label,
			controlId,
			wrapperClass = '',
			className = '',
			...propsInput
		},
		ref,
	) => {
		return (
			<Form.Group
				controlId={controlId}
				className={wrapperClass}
			>
				<FormLabel label={label} />
					<Form.Control
						className={clsx('rounded', className , {
							'bg-form': basic,
							'border-0': !error,
							'border-danger': !!error
						})}
						{...propsInput}
						ref={ref}
					/>
				<Form.Control.Feedback className='text-start d-block' type='invalid'>
					{errorMessage}
				</Form.Control.Feedback>
			</Form.Group>
		)
	},
)

export default FormInput
