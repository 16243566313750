import React from 'react'
import { Accordion, Button, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import LogoIcon from '../assets/images/logo.svg'
import useAppContext from '../components/app'

import routes from '../core/constants/routes'
import useLayoutAction from './useAction'

const { ESTIMATES, INVOICES, WORKFLOW, ACCOUNTSETTING, MANAGEUSERS } = routes

const NavigationMobile = () => {
	const actions = useLayoutAction()
	let { store: { clientPortalUser = {} } } = useAppContext()
	if (!clientPortalUser) {
		clientPortalUser = {}
	}
	const { admin = false } = clientPortalUser
	return (
		<Accordion>
			<div className='d-flex py-3 px-4 shadow'>
				<div className='flex-grow-1 d-flex align-items-center'>
					<Link to='/' className='d-inline-block'>
						<Image src={LogoIcon} />
					</Link>
				</div>
				<Accordion.Toggle
					eventKey='0'
					as='div'
					className='d-inline-flex align-items-center'
				>
					<i
						className='fas fa-bars fa-2x text-muted'
						data-toggle='collapse'
						data-target='#collapseExample'
						aria-expanded='false'
						aria-controls='collapseExample'
					/>
				</Accordion.Toggle>
			</div>
			<Accordion.Collapse eventKey='0' className='px-4'>
				<div>
					<div className='fs-small text-uppercase fw-bold pt-4' role='button'>
						<Link to={INVOICES} className='text-dark'>
							Invoices
						</Link>
					</div>
					<div className='fs-small text-uppercase fw-bold pt-4' role='button'>
						<Link to={ESTIMATES} className='text-dark'>
							Estimates
						</Link>
					</div>
					<div className='fs-small text-uppercase fw-bold pt-4' role='button'>
						<Link to={WORKFLOW} className='text-dark'>
							Vehicles
						</Link>
					</div>
					<div className='fs-small text-uppercase fw-bold pt-4' role='button'>
						<Link to={MANAGEUSERS} className='text-dark'>
							Manager users
						</Link>
					</div>
					{admin && (
						<div className='fs-small text-uppercase fw-bold pt-4' role='button'>
							<Link to={ACCOUNTSETTING} className='text-dark'>
								Account Settings
							</Link>
						</div>
					)}
					<div className='fs-small text-uppercase fw-bold pt-4' role='button'>
						<Button onClick={actions.logout} variant='default' to={ESTIMATES} className='fs-base text-dark p-0'>
							Logout
						</Button>
					</div>
					<div className='fs-small text-uppercase fw-bold py-4' role='button'>
						<a
							target='_blank'
							rel="noreferrer"
							href='https://www.mobiletechrx.com/contact-us/'
							className='text-dark'
						>
							Help
						</a>
					</div>
				</div>
			</Accordion.Collapse>
		</Accordion>
	)
}

export default NavigationMobile
