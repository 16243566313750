import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { useMutation } from 'react-query'

import { useApi, useLoading, useYupForm } from '../../../hooks'
import schema from './schema'
import { FormInput, FormPassword } from '../../formControl'
import useAppContext from '../../app'
import toast from '../../toast'

const AccountSeeting = () => {
	const {
		setStore,
		store,
		store: { clientPortalUser },
	} = useAppContext()
	const { userService } = useApi()
	
	const { firstName, lastName, email, admin } = clientPortalUser
	const defaultValue = {
		updatePassword: false,
		firstName,
		lastName,
		email,
		password: 66666666,
	}
	const useForm = useYupForm(schema, defaultValue)
	const {
		handleSubmit,
		formState: { errors },
		register,
		watch,
		setValue,
		setFocus
	} = useForm

	const { isLoading, mutate: edit } = useMutation(
		data => {
			return userService.edit(data)
		},
		{
			onSuccess: (res) => {
				const filedChanged = []
				if (res.clientPortalUser.email !== clientPortalUser.email) {
					filedChanged.push('email')
				}
				if (watch('updatePassword')) filedChanged.push('password')
				const emailMessage = filedChanged.includes('email') ? `Your email has been chaged to ${res.clientPortalUser.email}. Check your email to confirm. ` : ''
				const passwordMessage = filedChanged.includes('password') ? `Your password has successfully chaged` : ''
				toast.success({
					title: filedChanged.length > 0 ? filedChanged.join(', ') : 'Changed.',
					body: <p>
						Success. {emailMessage}{passwordMessage}
						</p>
				})
				localStorage.setItem(
					'clientPortalUser',
					JSON.stringify(res.clientPortalUser),
				)
				setStore({ ...store, clientPortalUser: res.clientPortalUser })
			},
			onError: error => {
				toast.error(error.message)
			},
		},
	)

	const onSubmit = data => {
		const { firstName, lastName, email, updatePassword, password } = data
		const dataUpdate = {
			firstName,
			lastName,
			email,
		}
		if (admin) delete dataUpdate.email
		if (updatePassword) dataUpdate.password = password
		delete dataUpdate.updatePassword
		return edit(dataUpdate)
	}
	useLoading(isLoading)

	const isUpdatePassword = watch('updatePassword')

	return (
		<Container style={{ maxWidth: '400px' }} className='mt-5'>
			<Row className='py-5 text-center max-vh-100 login'>
				<Col sm={12} className='my-0 mx-auto'>
					<div className='fs-4'>
						<strong className='d-block'>Account settings</strong>
					</div>
					<Form
						className='form form-default py-4 px-3 mt-3'
						onSubmit={handleSubmit(onSubmit)}
					>
						<FormInput
							basic
							label='First Name'
							placeholder='Enter First Name'
							error={!!errors.firstName}
							errorMessage={errors.firstName?.message}
							wrapperClass='mb-3'
							{...register('firstName')}
						/>
						<FormInput
							basic
							label='Last Name'
							placeholder='Enter Last Name'
							error={!!errors.lastName}
							errorMessage={errors.lastName?.message}
							wrapperClass='mb-3'
							{...register('lastName')}
						/>
						{!admin && (
							<FormInput
								basic
								label='Email'
								placeholder='Enter Email'
								error={!!errors.email}
								errorMessage={errors.email?.message}
								wrapperClass='mb-3'
								{...register('email')}
							/>
						)}
						<div onClick={() => {
							if (!isUpdatePassword) {
								setValue('password', '', { shouldValidate: true })
								setValue('updatePassword', true, { shouldValidate: true })
								setFocus('password')
							}
						}}>
							<FormPassword
								disabled={!isUpdatePassword}
								basic
								label='Password'
								placeholder='Enter password'
								error={!!errors.password}
								errorMessage={errors.password?.message}
								wrapperClass='mb-4 mt-3'
								togglePassword
								{...register('password')}
							/>
						</div>
						<Button className='w-100 mt-4 text-uppercase' type='submit'>
							Save
						</Button>
					</Form>
				</Col>
			</Row>
		</Container>
	)
}
export default AccountSeeting
