import * as routesUtils from '../utils/RoutesUtils'
import { API } from '../core/app.routes'
import httpClient from '../core/httpClient'

const mainService = () => {
	return {
		signup: body => {
			const path = routesUtils.getApiPath(API.SIGNUP)
			return httpClient.post(path, body)
		},
		signupUser: body => {
			const path = routesUtils.getApiPath(API.SIGNUP_USER)
			return httpClient.post(path, body)
		},
		login: body => {
			const path = routesUtils.getApiPath(API.LOGIN)
			return httpClient.post(path, body)
		},
		verify: verifyToken => {
			const path = routesUtils.getApiPath(API.VERIFY)
			return httpClient.post(path, { verifyToken })
		},
		reset: body => {
			const path = routesUtils.getApiPath(API.RESET)
			return httpClient.post(path, body)
		},
		newPassword: body => {
			const path = routesUtils.getApiPath(API.NEW_PASSWORD)
			return httpClient.post(path, body)
		},
		getCompanies: params => {
			const path = routesUtils.getApiPath(API.COMPANIES, params)
			return httpClient.get(path)
		},
		getUsers: params => {
			const path = routesUtils.getApiPath(API.LIST_USER, params)
			return httpClient.get(path)
		},
		inviteUsers: body => {
			const path = routesUtils.getApiPath(API.INVITE_USER)
			return httpClient.post(path, body)
		},
		editMember: body => {
			const path = routesUtils.getApiPath(API.USER)
			return httpClient.put(path, body)
		},
		deleteMember: (params) => {
			const path = routesUtils.getApiPath(API.USER_DETAIL, params)
			return httpClient.delete(path)
		},
		uploadFile: (companyId) => {
			const path = routesUtils.getApiPath(API.COMPANY_SIGN_S3, { id: companyId });
			return httpClient.post(path);
		}
	}
}

export default mainService
