import { PRICING_METHOD } from '../../core/constants/app';
import { FormComboBox } from '../formControl'

const options = [
  { label: 'All', value: 'all', key: 'all'},
  ...PRICING_METHOD
]

const FilterPricingMethods = (props) => {
	return (
		<div>
			<FormComboBox
				label={() => (
					<small className='text-secondary fs-sub text-uppercase fw-bold'>
						Pricing methods
					</small>
				)}
				options={options}
				title='Pricing methods'
				{...props}
			/>
		</div>
	)
};

export default FilterPricingMethods
