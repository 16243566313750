import * as routesUtils from '../utils/RoutesUtils'
import { API } from '../core/app.routes'
import httpClient from '../core/httpClient'

const workflowService = () => {
	return {
		getWorkflow: companyId => {
			const path = routesUtils.getApiPath(API.WORKFLOW)
			return httpClient.post(path, companyId)
		},
		getEstimates: params => {
			const path = routesUtils.getApiPath(API.WORKFLOW_ESTIMATES)
			return httpClient.post(path, params)
		},
		getWorkOrders: params => {
			const path = routesUtils.getApiPath(API.WORKFLOW_WORK_ORDERS)
			return httpClient.post(path, params)
		},
        getInvoices: params => {
			const path = routesUtils.getApiPath(API.WORKFLOW_INVOICES)
			return httpClient.post(path, params)
        }
	}
}

export default workflowService
