import clsx from 'clsx'
import React from 'react'

const FormCheck = React.forwardRef(
	({ disabled = false, error, errorMessage, controlId, text, label, size = 'medium', ...propsCheck }, ref) => {
		return (
			<div className={clsx({ 'disabled': disabled })}>
				<label className='form-check-label' htmlFor={controlId}>
					<input
						ref={ref}
						className='form-check-input'
						id={controlId}
						type='checkbox'
						disabled={disabled}
						{...propsCheck}
					/>
					<span
						className={clsx('form-checkbox', {
							'small': size === 'small',
							'medium': size === 'medium',
							'large': size === 'large'
						})}
					>
						{label}
					</span>
				</label>
			</div>
		)
	},
)

export default FormCheck
