export const API = {
	LOGIN: {
		path: 'login',
	},
	SIGNUP: {
		path: 'signup',
	},
	SIGNUP_USER: {
		path: 'signup/user',
	},
	RESET: {
		path: 'client-portal-users/resetPassword',
	},
	NEW_PASSWORD: {
		path: 'client-portal-users/newPassword',
	},
	LIST_USER: {
		path: 'client-portal-users/list',
	},
	INVITE_USER: {
		path: 'client-portal-users/invite-user',
	},
	USER_DETAIL: {
		path: 'client-portal-users/:userID',
	},
	USER: {
		path: 'client-portal-users',
	},
	VERIFY: {
		path: 'client-portal-users/verify',
	},
	COMPANIES: {
		path: 'companies',
	},
	COMPANY_SIGN_S3: {
		path: 'companies/:id/sign_s3',
	},
	ESTIMATES_DETAIL: {
		path: 'estimates/:estimateId',
	},
	ESTIMATES_PAYMENT: {
		path: 'estimates/payments',
	},
	VEHICLE_DETAIL: {
		path: 'estimates/:estimateId/vehicles/:vehicleId'
	},
	ESTIMATES: {
		path: 'estimates',
	},
	WORKORDERS: {
		path: 'workOrders'
	},
	INVOICES: {
		path: 'invoices',
	},
	INVOICE_DETAIL: {
		path: 'invoices/:invoiceId',
	},
	PAYMENTS: {
		path: 'payments',
	},
	PAYMENTS_SEND_RECEIPT: {
		path: '/payments/:paymentId/sendReceipt',
	},
	PAYMENTS_BY_WISETACK: {
		path: '/payments/createWisetackLink/:recordId',
	},
	PDF_INVOICE: {
		path: '/pdf/invoices/:recordId',
	},
	PDF_INVOICE_ADAS: {
		path: '/pdf/invoices/:recordId/adas',
	},
	PDF_ESTIMATE: {
		path: '/pdf/estimates/:recordId',
	},
	PDF_ESTIMATE_ADAS: {
		path: '/pdf/estimates/:recordId/adas',
	},
	PDF_ESTIMATE_SUPPLEMENT: {
		path: '/pdf/estimates/:recordId/supplement',
	},
	PDF_ESTIMATE_PARTS: {
		path: '/pdf/estimates/:recordId/parts',
	},
	GET_JOB: {
		path: 'jobs/',
	},
	WORKFLOW: {
		path: 'workflow'
	},
	WORKFLOW_ESTIMATES: {
		path: 'workflow/search/estimates'
	},
	WORKFLOW_WORK_ORDERS: {
		path: 'workflow/search/workOrders'
	},
	WORKFLOW_INVOICES: {
		path: 'workflow/search/invoices'
	}
}
