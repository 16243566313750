import { get } from 'lodash'
import { cloneElement, createElement } from 'react'
import useTable from './useTable'
import { getCellKey } from './utils'

const DefaultRow = props => <tr {...props} />

const Row = ({ rowData, index }) => {
	const {
		columns,
		options: { rowKey, onRowClick, rowComponent: CustomRow },
		state,
		setState,
	} = useTable()

	const TheRow = CustomRow || DefaultRow
	return (
		<TheRow
			role='button'
			rowdata={rowData}
			rowindex={index}
			onClick={() => {
				if (onRowClick) onRowClick(rowData)
			}}
		>
			{columns.map(column => {
				const value = get(rowData, column.dataKey)
				if (column.render) {
					return (
						<td
							key={getCellKey(rowData[rowKey], column.id)}
							className={column.className}
							style={column.style}
						>
							{column.render(value, { column, rowData, index, tableState: state, tableSetState: setState })}
						</td>
					)
				}
				return (
					<td
						key={getCellKey(rowData[rowKey], column.id)}
						className={column.className}
						style={column.style}
					>
						{column.cell
							? cloneElement(column.cell, { value, rowData, column, index, tableState: state, tableSetState: setState })
							: createElement(({ value }) => <>{value}</>, { value })}
					</td>
				)
			})}
		</TheRow>
	)
}
export default Row
