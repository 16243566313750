import React from 'react'
import { Image } from 'react-bootstrap'
import LogoFooter from '../../assets/images/logo_footer.svg'
import './copyright.scss'

export const Copyright = props => {
	return (
		<div className='copyright'>
			<p>
				<Image src={LogoFooter} alt='()' />
			</p>
			<p>
				Powered by Mobile Tech RX
				<br />
				Mobitech International Inc. | © 2020
			</p>
		</div>
	)
}

export default Copyright
