import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { Select } from '../controls'
import FormLabel from './FormLabel'

const FormComboBox = ({
	basic,
	label,
	options = [],
	value = '',
	controlId = '',
	onChange = () => {},
	error,
	errorMessage,
	wrapperClass,
	...otherProps
}) => {
	const [selected, setSelected] = useState(
		options.find(opt => opt.value === value)?.value,
	)

	useEffect(() => {
		setSelected(options.find(opt => opt.value === value)?.value,)
	}, [options, value])

	const onSelect = (value) => {
		setSelected(value)
		onChange(value)
	}

	let getSelected = options.find(opt => opt.value === selected)
	if (!getSelected) getSelected = options.length > 0 ? options[0] : null

	return (
		<Form.Group
			controlId={controlId}
			className={clsx(wrapperClass, {
				'border-danger': !!error,
				'form-group-basic': basic,
			})}
		>
			<FormLabel label={label} />
			<Select
				id={controlId}
				options={options}
				selected={getSelected.value}
				onChange={onSelect}
				variant='light'
				{...otherProps}
			/>
			<Form.Control.Feedback className='d-block' type='invalid'>
				{errorMessage}
			</Form.Control.Feedback>
		</Form.Group>
	)
}

export default FormComboBox
