import * as routesUtils from '../utils/RoutesUtils'
import { API } from '../core/app.routes'
import httpClient from '../core/httpClient'

const paymentService = () => {
	return {
    create: body => {
      const path = routesUtils.getApiPath(API.PAYMENTS)
			return httpClient.post(path, body)
    },
    sendReceipt: (params, body) => {
      const path = routesUtils.getApiPath(API.PAYMENTS_SEND_RECEIPT, params)
			return httpClient.put(path, body)
    },
    createWisetackLink: (params, body = {}) => {
      const path = routesUtils.getApiPath(API.PAYMENTS_BY_WISETACK, params)
			return httpClient.post(path, body)
    }
	}
}

export default paymentService
