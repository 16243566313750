import React from 'react'
import { Container } from 'react-bootstrap'
import LayoutHeader from './Layout.header'
import Copyright from '../components/Copyright'

const Layout = ({ children }) => {
	return (
		<div className='min-vh-100 d-flex flex-column'>
			<LayoutHeader />
			<main className='flex-grow-1 d-flex'>{children}</main>
			<footer className='text-light py-4'>
				<Container className='text-center'>
					<Copyright />
				</Container>
			</footer>
		</div>
	)
}

export default Layout
