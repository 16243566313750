export const APPROVED = {
  ALL: 'all',
	APPROVED: true,
	NOTAPPROVED: false,
}

export const FILTER_APPROVED = [
  { value: APPROVED.ALL, label: 'All' },
  { value: APPROVED.APPROVED, label: 'Approved' },
  { value: APPROVED.NOTAPPROVED, label: 'Not Approved' }
]

export const PAYMENT_STATUS = {
	ALL: 'all',
	UNPAID: 'unpaid',
	PARTIAL: 'partial',
	PAID: 'paid'
}

export const PAYMENT_TEXT = {
  ALL: 'All',
	UNPAID: 'Unpaid',
	PARTIAL: 'Partial Paid',
	PAID: 'Fully Paid'
}

export const FILTER_PAYMENT_STATUS= [
  { value: PAYMENT_STATUS.ALL, label: PAYMENT_TEXT.ALL },
  { value: PAYMENT_STATUS.UNPAID, label: PAYMENT_TEXT.UNPAID },
  { value: PAYMENT_STATUS.PARTIAL, label: PAYMENT_TEXT.PARTIAL },
	{ value: PAYMENT_STATUS.PAID, label: PAYMENT_TEXT.PAID }
]
