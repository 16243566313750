export const STAGE_GROUPING = {
    ONE: 1,
    TWO: 2,
    THREE: 3,
}

export const WORKFLOW_DOCTYPE = {
    ESTIMATE: 'estimate',
    WORK_ORDER: 'work order',
    INVOICE: 'invoice'
}

export const SKIP = { };
export const LIMIT = 8;
