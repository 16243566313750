import { Button, Col, Modal, Row } from 'react-bootstrap'
import { useBreakpoint } from '../../../hooks'

import useContainerContext from '../../containerContext'
import { RecordInfoMobile, RecordInfoDesktop } from '../../RecodeInfo'
import { VehicleMobile, VehicleDesktop } from '../../vehicle'
import CompanyInfo from '../../companyInfo'
import GroupButton from './groupButton'
import ModalPhotoList from '../../ModalPhotoList'
import ModalReceipt from '../../paymentInfo/receipt'
import ModalPaymentInfo from "../../paymentInfo"
import useAppContext from '../../app'
import ModalReject from './ModalReject'
import ModalDisclosures from './ModalDisclosures'

const EstimateDetail = () => {
	const { store: { isLog }} = useAppContext();
	const { data, state, showPhotoModal, closeModalPhoto, approveOrRejectVehicle, closeModalVerify, closeModalReject, closeModalDisclosure, closeModalDeposit, closeModalProcessingCard } = useContainerContext();
	const { showModalPhoto, vehicle, initialSlide, isPayment, isReceipt, isShowPopupVerify, isShowModalReject, isShowModalDisclosure, isShowModalDeposit, isShowModalProcessingCard } = state;
	const { company = {}, vehicles = [], hasSupplement = false, client: { _id: client = {} }, status } = data;
	const isShowButtonApproveOrReject = status;
	const { BREAKPOINT, screenBiggerThan } = useBreakpoint();
	const isBiggerThanMD = screenBiggerThan(BREAKPOINT.MD);
	const isBiggerThanLG = screenBiggerThan(BREAKPOINT.LG);

	let isShowSupplement = hasSupplement;
	let isShowPdf = true;
	let isShowParts = !!(vehicles.filter(({ parts }) => parts?.length).length);
	let isShowPayDeposit = true;
	let showAdasChecklists = false;
	let hasDisclosure = false;
	let lang;
	if (!isLog) {
		const token = localStorage['access_token'];
		const payload = token ? JSON.parse(window.atob(token.split('.')[1])) : null;
		isShowPdf = payload?.pdf;
		isShowPayDeposit = payload?.deposit;
		showAdasChecklists = payload?.adasChecklists;
		hasDisclosure = payload?.hasDisclosure;
		lang = payload?.language;
	}

	const companyProps = (() => {
		if (client.group && client.group.companyOverride && client.group.companyOverride && client.group.companyOverride.active) return client.group.companyOverride;
		return company;
	})();

	const handleApproveOrRejectVehicle = (vehicle) => {
		approveOrRejectVehicle.mutate({ 
			estimateId: data._id, 
			vehicleId: vehicle._id, 
			status: vehicle.status 
		});
	}

	return (
		<div className='my-5 px-sm-3'>
			<Row>
				<Col md={12} xl={6} lg={6}>
					<CompanyInfo company={companyProps} isBiggerThanMD={isBiggerThanMD} />
				</Col>
				<Col sm={10} md={8} xl={5} lg={6} className='mx-auto'>
					<GroupButton isBiggerThanMD={isBiggerThanMD} isBiggerThanLG={isBiggerThanLG} isShowPayDeposit={isShowPayDeposit} />
				</Col>
			</Row>

			<Row>
				<Col md={12}>
					{isBiggerThanMD && (
						<div className='pt-4'>
							<div className='mt-4 py-4 bg-white shadow-lg rounded'>
								<RecordInfoDesktop
									record={data}
									isShowPdf={isShowPdf}
									hasDisclosure={hasDisclosure}
									lang={lang}
								/>
								{vehicles.map((vehicle, index) => (
									<VehicleDesktop
										record={data}
										key={vehicle._id}
										isEstimateDetail={true}
										index={index.toString()}
										vehicle={vehicle}
										onShowPhoto={showPhotoModal}
										isShowSupplement={isShowSupplement}
										isShowParts={isShowParts}
										showEstAdasChecklists={showAdasChecklists}
										handleApproveOrRejectVehicle={handleApproveOrRejectVehicle}
										isShowButtonApproveOrReject={isShowButtonApproveOrReject}
									/>	
								))}
							</div>
						</div>
					)}
					{!isBiggerThanMD && (
						<div className='mt-4 py-4 bg-white shadow-lg'>
							<RecordInfoMobile
								record={data}
								isShowPdf={isShowPdf}
								hasDisclosure={hasDisclosure}
								lang={lang}
							/>

							{vehicles.map((vehicle, index) => {
								return (
									<VehicleMobile
										record={data}
										key={vehicle._id}
										index={index.toString()}
										vehicle={vehicle}
										onShowPhoto={showPhotoModal}
										isShowSupplement={isShowSupplement}
										isShowParts={isShowParts}
										showEstAdasChecklists={showAdasChecklists}
										isEstimateDetail={true}
										handleApproveOrRejectVehicle={handleApproveOrRejectVehicle}
										isShowButtonApproveOrReject={isShowButtonApproveOrReject}
									/>
								)
							})}
						</div>
					)}
					{showModalPhoto && (
						<ModalPhotoList vehicle={vehicle} initialSlide={initialSlide} onClose={closeModalPhoto} isInvoice={false} />
					)}
					{isReceipt && <ModalReceipt isInvoice={false} />}
					{isPayment && <ModalPaymentInfo data={data} isInvoice={false} />}
					{isShowModalDeposit && <ModalReject isShow={isShowModalDeposit} closeModal={closeModalDeposit} title="SORRY..." body="Deposit payment required before estimate can be approved." />}
					{isShowModalProcessingCard && <ModalReject isShow={isShowModalProcessingCard} closeModal={closeModalProcessingCard} title="SORRY…" body="The online payment hasn’t supported by the company yet. Please contact the company for further support!" />}

					<ModalReject
						isShow={isShowPopupVerify} 
						closeModal={closeModalVerify} 
						title="OOPS..." 
						body="There are some required fields which must be filled by Technicians. Please contact the technicians accordingly." 
					/>
					<ModalReject 
						isShow={isShowModalReject} 
						closeModal={closeModalReject} 
						title="SORRY..." 
						body={`Every vehicle in this Estimate must be set as “Approved” or “Rejected”. But don’t forget to have at least one “Approved” then.`} 
					/>
					{isShowModalDisclosure && <ModalDisclosures isShow={isShowModalDisclosure} closeModal={closeModalDisclosure} />}
				</Col>
			</Row>
		</div>
	)
}
export default EstimateDetail
