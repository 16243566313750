import { Link, useHistory, Redirect } from 'react-router-dom'
import { useMutation } from 'react-query'
import { Button, Form } from 'react-bootstrap'

import schema from './schema'
import { FormInput, FormPassword } from '../formControl'
import { useApi, useLoading, useYupForm } from '../../hooks'
import useAppContext from '../app'
import toast from '../toast'

const LoginForm = () => {
	const history = useHistory()
	const { setStore } = useAppContext()
	const { mainService } = useApi()
	const mutation = useMutation(data => mainService.login(data), {
		onSuccess: ({ access_token, clientPortalCompany, clientPortalUser }) => {
			localStorage.setItem(
				'clientPortalCompany',
				JSON.stringify(clientPortalCompany),
			)
			localStorage.setItem('clientPortalUser', JSON.stringify(clientPortalUser))
			localStorage.setItem('access_token', access_token)
			setStore({
				clientPortalCompany,
				clientPortalUser,
				isLog: !!clientPortalUser,
			})
		},
		onError: error => {
			toast.error({
				title: 'Login fail',
				body: error.message
			})
		},
	})

	useLoading(mutation.isLoading)

	const useForm = useYupForm(schema, {
		email: '',
		password: '',
	})

	const handleLogin = async ({ email, password }) => {
		mutation.mutate({ email, password })
	}

	const accessToken = localStorage.access_token
	const clientPortalCompany = localStorage.clientPortalCompany
	const clientPortalUser = localStorage.clientPortalUser

	const isLogin = accessToken && clientPortalCompany && clientPortalUser
	
	if (isLogin) {
		const { state = {} } = history.location
		const { from = '/' } = state
		return <Redirect to={from} />
	} else {
		localStorage.removeItem('access_token')
	}

	const {
		formState: { errors },
		register,
		handleSubmit,
	} = useForm

	return (
		<div>
			<Form
				className='form form-default py-4 px-3 mt-3'
				onSubmit={handleSubmit(handleLogin)}
			>
				<FormInput
					basic
					label='Email'
					placeholder='Enter email'
					error={!!errors.email}
					errorMessage={errors.email?.message}
					wrapperClass='mb-3'
					{...register('email')}
				/>

				<FormPassword
					basic
					type='password'
					label='Password'
					placeholder='Enter password'
					error={!!errors.password}
					errorMessage={errors.password?.message}
					togglePassword
					{...register('password')}
				/>

				<Button className='w-100 mt-4 text-uppercase' type='submit'>
					Login
				</Button>

				<strong className='d-block text-uppercase text-primary fs-sub mt-4 mb-2'>
					<Link to='/forgot-password'>Forgot password?</Link>
				</strong>
				<span>
					Don't have an account yet?{' '}
					<Link
						to='/signup'
						className='fs-sub text-primary text-uppercase fw-bold'
					>
						Sign up for free
					</Link>
				</span>
			</Form>
		</div>
	)
}

export default LoginForm
