import axios from 'axios'
import { handleResponseError } from '../../utils/RoutesUtils'
import { api } from '../config'

const { mtbAPI } = api

export { axios }

const client = axios.create({
  baseURL: mtbAPI,
  headers: {
    'content-type': 'application/json',
    accept: 'application/json',
    'media-type': 'application/json'
  },
})

client.interceptors.response.use(response => response.data.data, handleResponseError)
client.interceptors.request.use(config => {
  const token = localStorage.getItem('access_token')
  if (token) config.headers.common['Authorization'] = `Bearer ${token}`
  return config
})

export const amzClient = axios.create({
  headers: { 'Content-Type': 'text/csv' },
})

export const amzUploadFileEvidence = (fileEvidenceType = '') => {
  return axios.create({ headers: { 'x-amz-acl': 'private', 'Content-Type': fileEvidenceType } })
}

export const paymentEstimate = axios.create({
  baseURL: 'https://techbooksusdev.herokuapp.com/api/v6',
  headers: {
    'content-type': 'application/json',
    accept: 'application/json',
    'media-type': 'application/json'
  },
})

export default client