import clsx from 'clsx'
import { Form } from 'react-bootstrap'
import { SelectSearch } from '../controls'
import FormLabel from './FormLabel'

const FormComboBox = ({
	basic,
	label,
	controlId = '',
	onChange = () => {},
	error,
	errorMessage,
	wrapperClass,
	...otherProps
}) => {
	return (
		<Form.Group
			controlId={controlId}
			className={clsx(wrapperClass, {
				'border-danger': !!error,
				'form-group-basic': basic,
			})}
		>
			<FormLabel label={label} />
			<SelectSearch
				id={controlId}
				onChange={onChange}
				variant='light'
				{...otherProps}
			/>
			<Form.Control.Feedback className='d-block' type='invalid'>
				{errorMessage}
			</Form.Control.Feedback>
		</Form.Group>
	)
}

export default FormComboBox
