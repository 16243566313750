import React, { useState } from 'react'
import { Button, Image } from 'react-bootstrap'
import { useMutation } from 'react-query'

import { numberFormatCurrency } from '../../utils/helpers'
import { formatToLocalTime } from '../../utils/dates'
import {
	discountPercent,
	taxPercent,
	taxTotal,
	calcMonthlyPaymentWisetack,
} from '../../utils/invoice'
import { useApi, useLoading } from '../../hooks'
import toast from '../toast'
import clsx from 'clsx'
import WisetackLogo from '../../assets/images/wisetack.svg'
import ModalWisetack from '../ModelWisetack'

const ViewDesktop = ({ record = {}, isInvoice = false, isShowPdf = false, hasDisclosure = false, lang = 'us' }) => {
	const [isShowWisetack, setShowWisetack] = useState(false)
	const {
		total = 0,
		number,
		dateCreated,
		createdBy = {},
		client,
		currency = 'usd',
		discountAmount = 0,
		depositFlat = 0,
		_id,
		company,
		payment = {},
		status,
		vehicles,
	} = record

	const { prefix, wisetack = {} } = company
	const { merchantId = '' } = wisetack
	const clientID = client?._id?._id
	const detailPrefix =
		prefix && prefix.toString().replaceAll('CLIENT_ID', clientID)

	const { amount = 0 } = payment
	const isPaid = depositFlat > 0 && amount >= depositFlat

	const enabledWisetack = isInvoice && merchantId && (total - amount) >= 500
	const subtotal = vehicles.reduce((accumulator, currentValue) => (accumulator + currentValue.total), 0);
	const { pdfService } = useApi()
	const downloadPDFService = isInvoice
		? pdfService.invoicePDF
		: pdfService.estimatePDF

	const { isLoading, mutate: downloadPdf } = useMutation(
		params => {
			return downloadPDFService(params, false)
		},
		{
			onError: error =>
				toast.error({
					title: 'view PDF Fail',
					body: error.message,
				}),
		},
	)

	useLoading(isLoading)

	return (
		<div className='px-4 d-flex align-items-center'>
			<div className='d-inline-flex flex-column px-4'>
				<h4 className='fw-bold px-3'>{isInvoice ? 'Invoice' : 'Estimate'}</h4>
				{isInvoice && prefix && (
					<div>
						Invoice Prefix: {detailPrefix}
						{number}
					</div>
				)}
				{isShowPdf && (
					<Button
						variant='primary'
						className='text-center px-2 py-2 mx-2'
						style={{ letterSpacing: '1.5px' }}
						onClick={() => downloadPdf({ recordId: _id, hasDisclosure, lang })}
					>
						VIEW PDF
					</Button>
				)}
			</div>

			<div className={`d-inline-flex ps-4 border-start flex-grow-1 ${(!status && !isInvoice) && 'opacity-50'}`}>
				<div className='px-3 d-flex flex-column '>
					<strong className='fs-base'>
						{isInvoice ? 'Invoice Total' : 'Estimate Total'}
					</strong>
					<span className='fs-base mt-2'>
						{numberFormatCurrency(currency, total)}
					</span>
					<span className='fs-small'>
						Tax ({taxPercent(record)}): {taxTotal(record)}
					</span>
					<span className='fs-small'>
						Subtotal: {numberFormatCurrency(currency, subtotal)} | Discount ({discountPercent(record)}):{' '}
						{numberFormatCurrency(currency, discountAmount)}
					</span>
					{!isInvoice && depositFlat > 0 && (
						<strong
							className={clsx(
								'font-bold d-flex align-items-center py-2',
								{
									'text-deposit-paid': isPaid,
									'text-deposit-due': !isPaid,
								},
							)}
						>
							Deposit {isPaid ? 'Paid' : 'Due'}:{' '}
							{numberFormatCurrency(
								currency || 'usd',
								isPaid ? amount : depositFlat - amount || 0,
							)}
						</strong>
					)}
					{enabledWisetack && (
						<p className='wisetack-link'>
							Or, as low as{' '}
							{numberFormatCurrency(
								currency || 'usd',
								calcMonthlyPaymentWisetack(record),
							)}
							/month with <br />
							<Image src={WisetackLogo} alt='' />{' '}
							<a
								className='link'
								href='#'
								onClick={() => setShowWisetack(true)}
							>
								Learn More
							</a>
						</p>
					)}
				</div>
			</div>
			<div className={`pe-4 ${(!status && !isInvoice) && 'opacity-50'}`}>
				<div className='pe-3 d-flex flex-column'>
					<strong className='d-block text-end fs-base'>
						#{number} · {formatToLocalTime(dateCreated)}
					</strong>
					<span className='d-block text-end fs-small'>
						Created by: {createdBy.name || '[MTRX User]'}
					</span>
					<span className='d-block text-end fs-small'>
						for: {client.name || '[Client Name]'}
					</span>
				</div>
			</div>

			{enabledWisetack && (
				<ModalWisetack
					show={isShowWisetack}
					record={record}
					onHide={() => setShowWisetack(false)}
				/>
			)}
		</div>
	)
}

export default ViewDesktop
