export function resizeImage(canvas, src, dst, type, quality, cb) {
	let tmp = new Image(),
		context,
		cW,
		cH
	type = type || 'image/png'
	quality = quality || 1.0
	cW = src.naturalWidth
	cH = src.naturalHeight
	tmp.src = src.src
	tmp.onload = function () {
		cW /= 2
		cH /= 2
		canvas.width = cW
		canvas.height = cH
		context = canvas.getContext('2d')
		context.drawImage(tmp, 0, 0, cW, cH)
		dst.src = canvas.toDataURL(type, quality)
		// height or width is less than parameters (500x500)
		if (cW <= src.width && cH <= src.height) {
			// determine which is closest to 500
			if (src.width - cW < src.height - cH) {
				cH = canvas.height = (500 / cW) * cH
				cW = canvas.width = 500
				context = canvas.getContext('2d')
				context.drawImage(tmp, 0, 0, cW, cH)
				dst.src = canvas.toDataURL(type, quality)
				return cb()
			}
			cW = canvas.width = (500 / cH) * cW
			cH = canvas.height = 500
			context = canvas.getContext('2d')
			context.drawImage(tmp, 0, 0, cW, cH)
			dst.src = canvas.toDataURL(type, quality)
			return cb()
		}
		tmp.src = dst.src
	}
}

export function dataURLToBlob(dataURL) {
	const BASE64_MARKER = ';base64,'
	if (dataURL.indexOf(BASE64_MARKER) === -1) {
		const parts = dataURL.split(',')
		const contentType = parts[0].split(':')[1]
		const raw = decodeURIComponent(parts[1])
		return new Blob([raw], { type: contentType })
	}
	const parts = dataURL.split(BASE64_MARKER)
	const contentType = parts[0].split(':')[1]
	const raw = window.atob(parts[1])
	const rawLength = raw.length
	const uInt8Array = new Uint8Array(rawLength)
	for (let i = 0; i < rawLength; ++i) {
		uInt8Array[i] = raw.charCodeAt(i)
	}
	return new Blob([uInt8Array], { type: contentType })
}
