import useContainerContext from '../../containerContext'
import FilterGroup from '../../filterGroup'

import { FilterApproved } from '../../filterMobile'

const FilterMobile = () => {
	const {
		stateOrigin,
		state: {
			query: { filter },
		},
		setState,
	} = useContainerContext()

	const onFilter = filterData => {
		setState(prev => {
			return {
				...prev,
				query: {
					...prev.query,
					filter: filterData,
				},
			}
		})
	}

	return (
		<FilterGroup currentFilter={filter} originFilter={stateOrigin.query.filter} onFilter={onFilter}>
			{(filterState, setFilter, show) => {
				return (
					<>
						<FilterApproved
							isCloseMenu={!show}
							value={filterState.approved}
							onChange={approved => {
								setFilter(prev => ({ ...prev, approved }))
							}}
						/>
					</>
				)
			}}
		</FilterGroup>
	)
}

export default FilterMobile
