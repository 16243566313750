export const DATE_TIME = {
	US: 'MM/DD/YYYY',
	UK: 'DD/MM/YYYY',
}

export const SERVER_DATA = 'https://mobiletechrxus.s3.amazonaws.com'

export const CURRENCYS = {
	USD: 'usd',
	GBP: 'gbp',
	CAD: 'cad',
	AUD: 'aud',
	NZD: 'nzd',
	MXN: 'mxn',
	EUR: 'eur',
	ZAR: 'zar',
	CHF: 'chf',
	BRL: 'brl',
	TRY: 'try',
	DOP: 'dop',
	SEK: 'sek',
	SAR: 'sar',
}

export const VEHICLES_TYPES = [
	'Car',
	'Large Car',
	'Truck',
	'Large Truck',
	'SUV',
	'Large SUV',
	'Minivan',
	'Motorcycle',
	'RV',
	'Bus',
	'Watercraft',
	'Aircraft'
]

export const FILTER_STATUS = {
	ALL: 'ALL',
	OPEN: 'OPEN',
	CLOSED: 'CLOSED',
}

export const hailLabelDictionary = {
	us: 'US',
	canada: 'Canada',
	'new zealand': 'New Zealand',
	user: 'User Submitted (US)',
	australia: 'Australia',
	'united kingdom': 'United Kingdom',
}

export const CUSTOM_ALERT_DEFAULT = {
	name: '',
	method: 'text',
	description: 'Does not auto send.',
	templateBase: 'custom',
	emailSubject: '',
	value:
		'This is the default alert that asks your client to write a review after an invoice is closed. You can easily change it from the invoice view in the app.',
}

export const wheelsFields = [
	{ field: 'cosmeticRepair', text: 'Cosmetic Repair' },
	{ field: 'remanufactured', text: 'Remanufactured' },
	{ field: 'straighten', text: 'Straighten' },
	{ field: 'backsideRepair', text: 'Backside Repair' },
	{ field: 'crackRepair', text: 'Crack Repair' },
	{ field: 'powderCoat', text: 'Powder Coat' },
	{ field: 'powderCoatTmpsSleeve', text: 'Powder Coat TPMS Sleeve' },
	{ field: 'customPaint', text: 'Custom Paint' },
	{ field: 'hyperFinish', text: 'Hyper Finish' },
	{ field: 'marchineFinish', text: 'Machine Finish' },
	{ field: 'polish', text: 'Polish' },
	{ field: 'specialFinish', text: 'Special Finish' },
	{ field: 'twoPieceWheel', text: 'Two Piece Wheel' },
	{ field: 'threePieceWheel', text: 'Three Piece Wheel' },
	{ field: 'paintCenterCaps', text: 'Paint Center Caps' },
	{ field: 'paintHubcaps', text: 'Paint Hubcaps' },
	{ field: 'paintCallipers', text: 'Paint Callipers' },
	{ field: 'rechromed', text: 'Re Chromed' },
	{ field: 'hubcapRepair', text: 'Hubcap Repair' },
	{ field: 'mountAndBalance', text: 'Mount And Balance' },
	{ field: 'resetTMPS', text: 'TPMS Reset' },
	{ field: 'ceramicCoating', text: 'Ceramic Coating' },
	// { field: 'tireDisposalFee', text: 'Tire disposal fee' },
]

export const ALERTS_SEND_TIME_OPTIONS = [
	{
		value: '',
		label: 'Immediately',
		detail: 'Will send immediately when event is saved',
	},
	{ value: '1 hour before', label: '1 hour before' },
	{ value: '2 hour before', label: '2 hour before' },
	{ value: '1 day before', label: '1 day before' },
	{ value: '2 days before', label: '2 days before' },
	{ value: '1 week before', label: '1 week before' },
	{ value: '2 weeks before', label: '2 weeks before' },
]

export const REMINDER_ALERT_DEFAULT = {
	name: 'Reminder',
	method: 'text',
	description: 'Sent at a set time before an event.',
	sendBefore: '1 day before',
	autoSend: true,
	templateBase: 'reminder',
	emailSubject: '',
	value:
		'Reminder: Hi {CLIENT_NAME}! Your appointment with {COMPANY_NAME} is on {DATE_TIME}. If you have a conflict, please call {COMPANY_PHONE}. Reply STOP to unsubscribe, thank you.',
}

export const PRICING_METHOD = [
	{ label: 'Per Vehicle', value: 4, key: 'PER_VEHICLE' },
	{ label: 'Flat Fee', value: 0, key: 'FLAT_FEE' },
	{ label: 'Per Foot', value: 2, key: 'PER_FOOT' },
	{ label: 'By The Hour', value: 3, key: 'BY_THE_HOUR' },
]

export const DEFAULT_PRICING_METHOD = {
	FLAT_FEE: '0',
	PER_FOOT: '2',
	PER_HOUR: '3',
	PER_VEHICLE: '4',
}

export const BUY_CREDITS = [
	{ number: 1, price: 15 },
	{ number: 5, price: 70 },
	{ number: 10, price: 130 },
	{ number: 15, price: 180 },
	{ number: 25, price: 275 },
	{ number: 50, price: 500 },
]

export const FILTER_DATE_RANGE = {
	TODAY: 'Today',
	YESTERDAY: 'Yesterday',
	THISWEEK: 'This Week',
	LASTWEEK: 'Last Week',
	THISMONTH: 'This Month',
	LASTMONTH: 'Last Month',
	THISYEAR: 'This Year',
	LASTYEAR: 'Last Year',
	ALLTIME: 'All Time',
	CUSTOM: 'Custom',
}

export const USER_ROLES = {
	ADMIN: 'Admin',
	TECH: 'Tech',
	SUBCONTRACTOR: 'Subcontractor'
}

export const paymentMethods = {
	creditcard: 'credit card',
	cash: 'cash',
	check: 'check',
	wisetack: 'wisetack',
	offline: 'offline',
}

export const PERMISSION = {
	LIMITED_V2: 4,
	LIMITED: 3,
	BASIC: 0,
	STANDARD: 1,
	PREMIER: 2,
}

export const LANGUAGE = {
	'English (US)': 'english(us)',
	'English (UK)': 'english(uk)',
	'Spanish (es)': 'spanish(es)',
	'French Canada (fr-ca)': 'french-canada(fr-ca)',
}
