import clsx from 'clsx'
import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'

const NavDropdown = ({
	children,
	title,
	options = [],
	propsToggle = {},
	propsMenu = {},
	position = 'left',
	...otherProps
}) => {
	const [show, setShow] = useState(false)
	const toggleDropdown = () => setShow(!show)
	const hideDropdown = () => setShow(false)
	return (
		<Dropdown
			show={show}
			onMouseEnter={toggleDropdown}
			onMouseLeave={hideDropdown}
			{...otherProps}
		>
			<Dropdown.Toggle as='div' role='button'>
				{title}
			</Dropdown.Toggle>
			<Dropdown.Menu className={clsx('shadow-lg border-0 p-3', {
				'end-0': position === 'right'
			})}>
				{children}
			</Dropdown.Menu>
		</Dropdown>
	)
}

export default NavDropdown
