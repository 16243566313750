import { Col, Row } from 'react-bootstrap'
import useContainerContext from '../../containerContext'
import { FilterDateRange, FilterCompanies, FilterStatus } from '../../filter'

const Filter = ({ isScreenBiggerThanMD }) => {
	const {
		setState,
		state: {
			query,
			query: { filter },
		},
	} = useContainerContext()

	const onFilter = filterData => {
		setState(prev => {
			return {
				...prev,
				query: {
					...query,
					filter: filterData,
				},
			}
		})
	}

	return (
		<div className='pt-4'>
			<Row>
				{isScreenBiggerThanMD && (
					<>
						<Col md={3}>
							<FilterCompanies
								fluid
								className='light'
								size='medium'
								value={filter.vendor}
								onChange={vendor => {
									const filterData = { ...filter, vendor }
									onFilter(filterData)
								}}
							/>
						</Col>
						<Col md={3}>
							<FilterStatus
								fluid
								className='light'
								size='medium'
								value={filter.status}
								onChange={status => {
									const filterData = { ...filter, status }
									onFilter(filterData)
								}}
							/>
						</Col>
						<Col md={6}>
							<FilterDateRange
								fluid
								className='light'
								size='medium'
								value={filter.dateRange}
								onChange={({ startDate, endDate, value }) => {
									const filterData = {
										...filter,
										startDate,
										endDate,
										dateRange: value,
									}
									onFilter(filterData)
								}}
							/>
						</Col>
					</>
				)}
			</Row>
		</div>
	)
}
export default Filter
