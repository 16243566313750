import React from 'react'

import Item from './Item';

const Element = ({ stage }) => {
    const { vehicles = [], grouping } = stage;

    return (
        <div>
            {vehicles && vehicles.length > 0 && vehicles.map((item, index) => {
                return (
                    <Item
                        key={item._id}
                        item={item}
                        index={index}
                        grouping={grouping}
                    />
                );
            })}
        </div>
    )
}

export default Element