import React from 'react'
import { Button, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { IoIosCheckmarkCircle } from 'react-icons/io'

import { formatToLocalTime } from '../../../utils/dates'
import { numberFormatCurrency } from '../../../utils/helpers'
import useContainerContext from '../../containerContext'
import { Tooltip } from '../../controls'
import Table from '../../table'
import AvatarName from '../../avatarName'
import { SortableColumn } from '../../table-plugins/sortable'
import DownloadPhotos from '../../../assets/images/icons/photo-download.svg'
import DownloadPhotosDisabled from '../../../assets/images/icons/photo-downloaddisabled.svg'
import { PAYMENT_TEXT } from '../../../core/constants/filter'
import { downloadPhotosRecord, getPhotos } from '../../../utils/invoice'
import useAppContext from '../../app'
import { useApi, useLoading } from '../../../hooks'
import { FormInput } from '../../formControl'

const TableDesktop = () => {
	const { data = [], state, setState } = useContainerContext()
	const { setIsLoading } = useAppContext()
	const queryClient = useQueryClient()
	const { invoiceService } = useApi()
	const { isLoading, mutate: edit } = useMutation(
		params => {
			return invoiceService.edit(params)
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['invoices'])
				toast.success('Successful')
			},
			onError: error => {
				toast.error(error.message)
			},
		},
	)

	const editPO = (value, _id) => {
		const dataUpdate = {
			invoiceId: _id,
			purchaseOrder: value,
		}
		return edit(dataUpdate)
	}
	useLoading(isLoading)
	return (
		<Table
			data={data}
			rowKey='_id'
			state={state}
			setState={setState}
			className='primary'
			borderless
		>
			<SortableColumn
				dataKey='dateCreated'
				width='12%'
				headerLabel={
					<u className='d-inline-block me-1'>
						<Tooltip
							placement='bottom'
							message={
								<span>
									{`Click to sort by date
									ascending or descending`}
								</span>
							}
						>
							Date
						</Tooltip>
					</u>
				}
				className='text-start'
				render={value => formatToLocalTime(value)}
			/>

			<SortableColumn
				dataKey='company.name'
				width='25%'
				headerLabel={
					<u className='d-inline-block me-1'>
						<Tooltip
							placement='bottom'
							message={
								<span>
									{`Click to sort alphabetically
									ascending or descending`}
								</span>
							}
						>
							Vendor
						</Tooltip>
					</u>
				}
				render={(value, { rowData }) => {
					const { client = {} } = rowData
					const active = client?._id?.group?.companyOverride?.active
					const name = client?._id?.group?.companyOverride?.name
					return (
						<div className='d-flex align-items-center'>
							<AvatarName
								size='medium'
								value={active ? name : value}
								className='me-2'
							/>
							<div>{active ? name : value}</div>
						</div>
					)
				}}
			/>

			<SortableColumn
				id='purchaseOrder'
				dataKey='_id'
				width='13%'
				headerLabel={
					<u className='d-inline-block me-1'>
						<Tooltip
							placement='bottom'
							message={
								<span>
									{`Click to sort by purchase order
									ascending or descending`}
								</span>
							}
						>
							PO NUMBER
						</Tooltip>
					</u>
				}
				className='text-center'
				render={(_, { rowData }) => {
					const { purchaseOrder, _id } = rowData
					return (
						<FormInput
							basic
							defaultValue={purchaseOrder || ''}
							onBlur={e => {
								const { value } = e.target
								if (purchaseOrder !== value) {
									return editPO(value, _id)
								}
							}}
						/>
					)
				}}
			/>

			<SortableColumn
				dataKey='number'
				width='13%'
				headerLabel={
					<u className='d-inline-block me-1'>
						<Tooltip
							placement='bottom'
							message={
								<span>
									{`Click to sort by number
									ascending or descending`}
								</span>
							}
						>
							INVOICE #
						</Tooltip>
					</u>
				}
				className='text-center'
				render={(number, { rowData }) => {
					return (
						<Link to={`/invoices/${rowData._id}`} className='text-primary'>
							#{number}
						</Link>
					)
				}}
			/>
			<SortableColumn
				dataKey='total'
				width='12%'
				headerLabel={
					<u className='d-inline-block me-1'>
						<Tooltip
							placement='bottom'
							message={
								<span>{`Click to sort by dollar amount high or low`}</span>
							}
						>
							Total
						</Tooltip>
					</u>
				}
				className='text-center'
				render={(total, { rowData }) =>
					numberFormatCurrency(rowData.company?.currency || 'usd', total || 0)
				}
			/>

			<Table.Column
				id='photos'
				dataKey='_id'
				width='13%'
				header='Photos'
				className='text-center'
				render={(_id, { rowData }) => {
					const isPhotos = getPhotos(rowData)
					return (
						<Button
							variant='default'
							onClick={() => {
								if (isPhotos.length > 0) {
									setIsLoading(true)
									downloadPhotosRecord(rowData, () => {
										setIsLoading(false)
									})
								}
							}}
						>
							<Image
								className='pointer'
								src={
									isPhotos.length > 0 ? DownloadPhotos : DownloadPhotosDisabled
								}
								alt=''
							/>
						</Button>
					)
				}}
			/>

			<SortableColumn
				dataKey='payment.paid'
				width='12%'
				headerLabel={
					<u className='d-inline-block me-1'>
						<Tooltip
							placement='bottom'
							message={<span>{`Click to sort by payment status`}</span>}
						>
							payment status
						</Tooltip>
					</u>
				}
				className='text-center'
				render={value => {
					return (
						<div
							style={{ minHeight: '1.5rem' }}
							className={clsx(
								'text-white rounded-pill position-relative d-flex justify-content-center align-items-center fs-small text-capitalize',
								{
									'bg-light': value === 'unpaid',
									'bg-success': value === 'paid',
									'bg-warning': value === 'partial',
								},
							)}
						>
							{PAYMENT_TEXT[value.toUpperCase()]}
							{value !== 'partial' && (
								<IoIosCheckmarkCircle
									size={18}
									className='position-absolute top-50 translate-middle-y'
									style={{ right: '2px' }}
								/>
							)}
						</div>
					)
				}}
			/>
		</Table>
	)
}
export default TableDesktop
