import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const TooltipControl = ({ message, children, ...other }) => {
	return (
		<OverlayTrigger
			{...other}
			overlay={<Tooltip id={`tooltip-bottom`}>{message}</Tooltip>}
		>
			<div>{children}</div>
		</OverlayTrigger>
	)
}

export default TooltipControl
