import moment from 'moment'
import timeZonesJson from './fileJson/timezones.json'

const minuteToHour = (min) => {
	const hours = Math.abs(min) / 60
	const rhours = Math.floor(hours)
	const minutes = (hours - rhours) * 60
	const rminutes = Math.round(minutes)

	return `${rhours.toString().padStart(2, '0')}:${rminutes
		.toString()
		.padStart(2, '0')}`
}

const timeZoneOpts = []

timeZonesJson.forEach((item) => {
  const tz = moment.tz.zone(item.region)

  const tzResult = {
    value: item.region,
    label: `${item.display} | ${item.region} (UTC ${item.offsetServer})`,
    region: item.region,
    data: item,
  }

  if (tz) {
    const isDts = moment.tz(new Date(), item.region).isDST()

    if (isDts) {
      const offset = tz.utcOffset(new Date())
      const offsetPrefix = offset ? item.offset.substr(0, 1) : '+'
      const offsetString = minuteToHour(offset)
      tzResult.label = `${item.display.replace('Standard', 'Daylight')} | ${item.region} (DTS ${offsetPrefix}${offsetString})` 
      tzResult.data = {
        ...item,
        offset: `${offsetPrefix}${Math.abs(offset) / 60}`,
        offsetServer: `${offsetPrefix}${offsetString}`,
      }
    }

    timeZoneOpts.push({ ...tzResult })
  }
})

export const getTimeZoneWithDTS = (region) => {
  return timeZoneOpts.find((option) => { return option.region === region })
}

export const getTimeZonesWithDTS = () => timeZoneOpts

export const getCurrentTimeZone = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  } catch (error) {
    return 'America/New_York'
  }
}

export const getTimeZone = region => {
  return timeZonesJson.find((option) => { return option.region === region })
}
