import React from 'react'
import Calendar from '../../../assets/images/workflow/calendar.svg';
import IconAdd from '../../../assets/images/workflow/icon-add.svg';
import IconInProcess from '../../../assets/images/workflow/icon-inprocess.svg';
import IconComplete from '../../../assets/images/workflow/icon-complete.svg';
import CarTest from '../../../assets/images/workflow/cartest.svg';
import { numberFormatCurrency, getServiceList } from '../../../utils/helpers';
import { checkColorTimer, getColorAssign, renderTechnician } from './utils';
import useContainerContext from '../../containerContext';
import clsx from 'clsx'
const Item = ({ item = {}, index, grouping = {} }) => {
    const { companies, currentVendor } = useContainerContext();

    const showIconGroup = () => {
		if (grouping === 1) return IconAdd;
		if (grouping === 2) return IconInProcess;
		return IconComplete;
	};

    return (
        <div>
            <div className='bg-white pe-none'>
                <div
                    role='button'
                    tabIndex='0'
                    onKeyPress={() => {}}
                    className='col-12 workflow-item'
                >
                    <div className='row'>
                        <div className='col-4 workflow-image'>
                            <div className='crop'>
                                <img className={item?.image ? 'crop-image' : 'non-crop-image'} src={item?.image || CarTest} alt={item?.make} />
                            </div>
                            <span className='icon-group'>
                                <img src={showIconGroup()} alt='' />
                            </span>
                        </div>
                        <div className='col-8 vehicle-info'>
                            {item?.year}
                            <br />
                            {
                                item.make ? (
                                    <>
                                        <h5 className='font-weight-700'>{item.make}</h5>
                                        {
                                            item?.color ? <h5 className='font-weight-700'>{item?.model}, {item?.color}</h5> : <h5 className='font-weight-700'>{item?.model}</h5>
                                        }
                                    </>
                                ) : (
                                    <h5 className='font-weight-700'>NO VEHICLE SELECTED</h5>
                                )
                            }
                            <div className='fs-small'>Client: {item?.client?.name}</div>
                            <div className='fs-small'>VIN: {item?.vin ? item?.vin : (<span className='text-gray'>{` NONE ADDED`}</span>)}</div>
                            <div className='fs-small'>STOCK/RO: {item?.stock ? item?.stock : (<span className='text-gray'>{` NONE ADDED`}</span>)}</div>
                            <div className={clsx(
                                'fs-small', getColorAssign(item),
                            )}> {renderTechnician(item, companies)}</div>
                            <div className='workflow-total'>
                                {numberFormatCurrency('usd', item.total)}
                            </div>
                        </div>
                    </div>
                    <div className='workflow-services'>
                        <div className='row'>
                            <div className='col-10'>
                                <strong>{'Services: '}</strong>
                                {getServiceList(item).length > 0 ? getServiceList(item).join(', ') : (<span className='text-gray'>NONE SELECTED</span>)}
                            </div>
                            <div className='col-2'>
                                <div className='d-flex justify-content-end'>
                                    {
                                        item.hasEvent && (<img src={Calendar} alt='calendar' className='mr-1' />)
                                    }
                                    {
                                        currentVendor?.workflowManagement?.timeTrackingSC && checkColorTimer(item)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Item
