import clsx from 'clsx'
import { get } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { Dropdown, FormControl } from 'react-bootstrap'

const SelectSearch = ({
  placeholder = '',
	options = [],
	value,
	onChange,
	itemProps = {},
	toggleProps = {},
  menuProps={},
	controlId='',
	isLoading = false,
  onSearch,
	size='',
	fluid = false,
	onScroll: handleScroll,
	className = '',
	...props
}) => {
  const refSearchBox = useRef(null)
	const [selectOptions, setOptions] = useState([...options])
	const [selected, setSelected] = useState(selectOptions.find(el => el.value === value))

	useEffect(() => {
		setOptions([...options])
	}, [options])

	useEffect(() => {
		if (get(selected, 'value') !== value) {
			const newSelected = selectOptions.find(el => el.value === value)
			setSelected(newSelected)
			refSearchBox.current.value = newSelected?.label
		}
	}, [value, options, selected, selectOptions])

  let timer = null
  const handleSearch = (e) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      if (typeof onChange !== 'function') return
      onSearch(e)
    }, 500);
  }

	const onScroll = (event) => {
		var element = event.target;
		if (element.scrollHeight - element.scrollTop === element.clientHeight)
		{
			handleScroll()
		}
	}

	const { className: toggleClassName, ...otherToggleProps } = toggleProps
	const { className: menuClassName, ...otherMenuProps} = menuProps
	const { className: itemClassName, ...otherItemProps} = itemProps
	return (
		<Dropdown
			className={clsx('select-search position-relative', className, {
				'medium': size === 'medium',
				'fluid': fluid === true
			})}
			{...props}
		>
			<Dropdown.Toggle
				as='div'
				className={clsx('form-control', toggleClassName)}
				{...otherToggleProps}
				role='button'
				
			>
        <FormControl
					id={controlId}
          placeholder={placeholder}
          ref={refSearchBox}
          onChange={handleSearch}
					defaultValue={selected?.label || ''}
					style={{ height: '100%', boxShadow: 'unset', maxHeight: '45px' }}
					className='border-0 px-0 fs-sub fw-600'
        />
			</Dropdown.Toggle>

			<Dropdown.Menu
				className={clsx('', menuClassName, {
					'loader': isLoading,
				})}
				{...otherMenuProps}
			>
				<div className='group-items position-relative overflow-auto' onScroll={onScroll}>
					{selectOptions.map(({ value, label, renderLabel }, index) => {
						const selectedValue = get(selected, 'value', '')
						const active = value === selectedValue
						return (
							<Dropdown.Item
								className={clsx('text-wrap text-break', itemClassName)}
								active={active}
								key={`key-option-${value}-${index}`}
								onClick={() => {
									refSearchBox.current.value = label
									onChange(value)
								}}
								{...otherItemProps}
							>
								{renderLabel ? renderLabel(active) : label}
							</Dropdown.Item>
						)
					})}
				</div>
			</Dropdown.Menu>
			
		</Dropdown>
	)
}

export default SelectSearch
