import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { clearToken } from '../hooks/useStorageApp'

const useLayoutAction = () => {
  const queryClient = useQueryClient()
  const history = useHistory()

  const logout = () => {
    queryClient.clear()
    clearToken()
    history.push('/login')
  }
  return {
    logout,
  }
}

export default useLayoutAction
