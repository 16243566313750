import React, { useState } from 'react'
import { useQuery } from 'react-query'

import Estimates from './Estimates'
import { sortToObject } from '../../../utils/sort'
import { ContainerProvider } from '../../containerContext'
import { FILTER_DATE_RANGE } from '../../../core/constants/app'
import { useApi } from '../../../hooks'

const queryOrigin = {
	query: {
		skip: 0,
		limit: 10,
		filter: {
			dateRange: FILTER_DATE_RANGE.ALLTIME,
			vendor: 'all',
			approved: 'all',
			status: 'all',
		},
	},
	sorted: {
		sortBy: 'dateCreated',
		sortDirection: 'desc',
	},
}

const Index = () => {
	const [state, setState] = useState({ ...queryOrigin })
	const { estimateService } = useApi()
	const {
		isLoading,
		data = [],
	} = useQuery(
		['estimates', state.query, state.sorted],
		() => {
			const { filter, ...otherQuery } = state.query
			const params = {
				...otherQuery,
				sort: { ...sortToObject(state.sorted) },
				filter: {},
			}

			const { startDate, endDate, vendor, approved, status } = filter
			if (startDate && endDate) params.filter = { ...params.filter, startDate, endDate}
			if (vendor !== 'all') params.filter.company = vendor
			if (approved !== 'all') params.filter.approved = approved
			if (status !== 'all') params.filter.status = status

			return estimateService.many(params)
		},
		{
			refetchOnWindowFocus: false,
		},
	)

	return (
		<ContainerProvider
			state={state}
			setState={setState}
			data={data.estimates || []}
			stateOrigin={queryOrigin}
			isLoading={isLoading}
		>
			<Estimates />
		</ContainerProvider>
	)
}

export default Index
