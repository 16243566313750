import { useEffect, useRef } from 'react'
import flatpickr from 'flatpickr'
import { FormInput } from '../formControl'

const DatePicker = ({ options, label, ...props }) => {
	const flatpickrRef = useRef(null)

	useEffect(() => {
		if (flatpickrRef.current) flatpickr(flatpickrRef.current, options)
	}, [options])

	return (
		<FormInput
			label={
				<div className='h8 text-uppercase fw-bold'>
          {label || ' '}
        </div>
			}
			ref={flatpickrRef}
			className='flatpickr border-0 shadow-sm'
			placeholder='Select Date..'
			{...props}
		/>
	)
}

export default DatePicker
