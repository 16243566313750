import clsx from 'clsx'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { Row, Button } from 'react-bootstrap'
import { FaCheckCircle, FaRegCircle } from 'react-icons/fa'
import { numberFormatCurrency } from '../../utils/helpers'
import { FormInput } from '../formControl'

export const TIP_OPTIONS = {
  TIP_25: 25,
  TIP_20: 20,
  TIP_15: 15,
  CUSTOM: 'custom',
}

const Tips = ({ amount = 0, onChange, currency = 'usd' }) => {
  const [currentTip, setCurrentTip] = useState(TIP_OPTIONS.CUSTOM)
  const [amountInput, setAmountInput] = useState(0)
  const [tipAmount, setTipAmount] = useState(0)

  const tipAmountNumber = useCallback((tip) => {
    return amount * tip / 100
  }, [amount])

  const getAmountTip = (tip) => {
    return numberFormatCurrency(currency, tipAmountNumber(tip))
  }

  useEffect(() => {
    if (currentTip !== TIP_OPTIONS.CUSTOM)
      setTipAmount(tipAmountNumber(currentTip))
    else setTipAmount(amountInput)
  }, [amount, amountInput, currentTip, tipAmountNumber])

  useEffect(() => {
    onChange(tipAmount)
  }, [onChange, tipAmount])


  const renderTip = (tip) => {
    const isActive = currentTip === tip
    return (
      <div
        className={
          clsx('d-flex border py-1 px-3 rounded justify-content-between align-items-center', {
            'bg-primary text-white': isActive
          })
        }
        role='button'
        style={{ lineHeight: '1rem' }}
        onClick={() => {
          setCurrentTip(tip)
          setAmountInput('')
        }}
      >
        <div>
          <strong className='d-block'>{`${tip}%`}</strong>
          <span
            className={clsx('fs-min', {
              'text-light': !isActive
            })}
          >
            {getAmountTip(tip)}
          </span>
        </div>
        {currentTip === tip && <FaCheckCircle  size={16} />}
        {currentTip !== tip && <FaRegCircle />}
      </div>
    )
  }

  return (
    <>
      <Row className='my-4'>
        <strong className='d-block fs-small mb-2'>Tip</strong>
        <div className='d-grid grid-auto-columns grid-gap-2 grid-template-areas-2'>
          {[TIP_OPTIONS.TIP_25, TIP_OPTIONS.TIP_20, TIP_OPTIONS.TIP_15].map((tip, index) => {
            return <Fragment key={index}>{renderTip(tip)}</Fragment>
          })}
          <Button
            variant={currentTip === TIP_OPTIONS.CUSTOM ? 'primary' : 'default text-dark'}
            className='border rounded w-100 p-2'
            onClick={() => { setCurrentTip(TIP_OPTIONS.CUSTOM)}}
          >
            <div>None</div>
          </Button>
        </div>
      </Row>
      <div className='mt-2'>
        <strong className='d-block fs-small mb-2'>Custom tip</strong>
        <FormInput basic placeholder='Enter $ tip' value={amountInput} onChange={(e) => {
          setCurrentTip(TIP_OPTIONS.CUSTOM)
          setAmountInput(e.target.value)
        }} />
      </div>
    </>
  )
}

export default Tips
