import * as routesUtils from '../utils/RoutesUtils'
import { API } from '../core/app.routes'
import httpClient from '../core/httpClient'
import commonService from './common.service'

const pdfService = () => {
  const common = commonService()
	return {
		invoicePDF: async (params = {}, targetBlank = true) => {
			const path = routesUtils.getApiPath(API.PDF_INVOICE, params)
			return httpClient.get(path)
				.then((res) => {
          common.downloadLink(`invoice_${params.recordId}.pdf`, res.url, targetBlank)
          return res
				})
		},
		invoiceADASPDF: async (params = {}, targetBlank = true) => {
			const path = routesUtils.getApiPath(API.PDF_INVOICE_ADAS, params)
			return httpClient.get(path)
				.then((res) => {
          common.downloadLink(`invoice_${params.recordId}.pdf`, res.url, targetBlank)
          return res
				})
		},
		estimatePDF: async (params = {}, targetBlank = true) => {
			const path = routesUtils.getApiPath(API.PDF_ESTIMATE, params)
			return httpClient.get(path)
				.then((res) => {
          common.downloadLink(`estimate_${params.recordId}.pdf`, res.url, targetBlank)
          return res
				})
		},
		estimateSupplementPDF: async (params = {}, targetBlank = true) => {
			const path = routesUtils.getApiPath(API.PDF_ESTIMATE_SUPPLEMENT, params)

			return httpClient.get(path)
				.then((res) => {
          common.downloadLink(`estimate_${params.recordId}.pdf`, res.url, targetBlank)
          return res
				})
		},
		estimatePartsPDF: async (params = {}, targetBlank = true) => {
			const path = routesUtils.getApiPath(API.PDF_ESTIMATE_PARTS, params)
			return httpClient.get(path)
				.then((res) => {
          common.downloadLink(`estimate_${params.recordId}.pdf`, res.url, targetBlank)
          return res
				})
		},
		estimateADASPDF: async (params = {}, targetBlank = true) => {
			const path = routesUtils.getApiPath(API.PDF_ESTIMATE_ADAS, params)
			return httpClient.get(path)
				.then((res) => {
          common.downloadLink(`estimate_${params.recordId}.pdf`, res.url, targetBlank)
          return res
				})
		},
	}
}

export default pdfService
