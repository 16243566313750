// -----------------------
export const DEFAULT_USER = {
	LABOR_RATE: '50',
	LABOR_RATE_RI: '50',
}

// -------- server read file pdf-------------------//
export const PAGE_SIZE = [10, 20, 50, 100]
export const PAGE_SIZE_DEFAULT = 100

export const defaults = {
	DEFAULT_USER: {
		LABOR_RATE: '50',
		LABOR_RATE_RI: '50',
	},
	PAGE_SIZE: [10, 20, 50, 100],
	PAGE_SIZE_DEFAULT: 100,
}

export const api = {
	// mtbAPI: `${process.env.REACT_APP_API_PREFIX}/`,
	mtbAPI: `${process.env.REACT_APP_ENV_CLIENT_PORTAL_API}/`,
}

export const stripe = {
	stripeKey: process.env.REACT_APP_STRIPE_KEY,
	stripeClientID: process.env.REACT_APP_STRIPE_CLIENT_ID,
}

const config = { api, defaults, stripe }

/*

// main.yml, main-dev.yml
env:
	AWS_ACCESS_KEY_ID: ${{ secrets.AWS_ACCESS_KEY_ID }}
	AWS_SECRET_ACCESS_KEY: ${{ secrets.AWS_SECRET_ACCESS_KEY }}

// main.yml
env:
	AWS_ACCESS_KEY_ID: ${{ secrets.AWS_ACCESS_KEY_ID }}
	AWS_SECRET_ACCESS_KEY: ${{ secrets.AWS_SECRET_ACCESS_KEY }}
	REACT_APP_STRIPE_KEY: ${{ secrets.STRIPE_KEY_PROD }}
	REACT_APP_STRIPE_CLIENT_ID: ${{ secrets.STRIPE_CLIENT_ID_PROD }}

// main-dev.yml
env:
	AWS_ACCESS_KEY_ID: ${{ secrets.AWS_ACCESS_KEY_ID }}
	AWS_SECRET_ACCESS_KEY: ${{ secrets.AWS_SECRET_ACCESS_KEY }}
	REACT_APP_STRIPE_KEY: ${{ secrets.STRIPE_KEY_DEV }}
	REACT_APP_STRIPE_CLIENT_ID: ${{ secrets.STRIPE_CLIENT_ID_DEV }}

*/

export default config
