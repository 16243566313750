import { Button, Image } from 'react-bootstrap'
import { useMutation } from 'react-query'
import { AiFillDollarCircle } from 'react-icons/ai'
import { numberFormatCurrency } from '../../utils/helpers'
import { formatToLocalTime } from '../../utils/dates'
import { useApi, useLoading } from '../../hooks'
import toast from '../toast'
import DocumentTop from '../../assets/images/icons/document-top.svg'
import clsx from 'clsx'

const ViewMobile = ({ record = {}, isInvoice = false, isShowPdf = false, hasDisclosure = false, lang = 'us'}) => {
	const {
		total = 0,
		number,
		dateCreated,
		currency = 'usd',
		createdBy = {},
		client,
		company,
		depositFlat = 0,
		_id,
		payment = {},
		status,
	} = record

	const { prefix } = company
	const clientID = client?._id?._id
	const detailPrefix = prefix && prefix.toString().replaceAll('CLIENT_ID', clientID)

	const { amount = 0 } = payment

	const isPaid = depositFlat > 0 && amount >= depositFlat

	const { pdfService } = useApi()
	const downloadPDFService = isInvoice
		? pdfService.invoicePDF
		: pdfService.estimatePDF

	const { isLoading, mutate: downloadPdf } = useMutation(
		params => {
			return downloadPDFService(params, false)
		},
		{
			onError: error =>
				toast.error({
					title: 'view PDF Fail',
					body: error.message,
				}),
		},
	)

	useLoading(isLoading)

	return (
		<div className='px-4 text-center'>
			<h4 className='fw-bold '>{isInvoice ? 'Invoice' : 'Estimate'}</h4>
			{isInvoice && prefix && (
					<div>Invoice Prefix: {detailPrefix}{number}</div>
				)}
			{isShowPdf && (
				<Button
					variant='primary'
					className='text-center px-3 py-2 mx-2'
					style={{letterSpacing: '1.5px'}}
					onClick={() => {
						downloadPdf({ recordId: _id, hasDisclosure, lang})
					}}
				>
					VIEW PDF
				</Button>
			)}

			<div className={`my-3 fs-small ${(!status && !isInvoice) && 'opacity-50'}`}>
				<strong className='d-block'>
					#{number} · {formatToLocalTime(dateCreated)}
				</strong>
				<span className='d-block'>
					Created by: {createdBy.name || '[MTRX User]'}
				</span>
				<span className='d-block'>for: {client.name || '[Client Name]'}</span>
			</div>

			<div>
				<h1 className={`mb-0 ${(!status && !isInvoice) && 'opacity-50'}`}>{numberFormatCurrency(currency, total)}</h1>
				<span className={`${(!status && !isInvoice) && 'opacity-50'}`}>{isInvoice ? 'Invoice Total' : 'Estimate Total'}</span>
				<br />

				{!isInvoice && depositFlat > 0 && (
					<strong
						className={clsx(
							'font-bold d-flex justify-content-center align-items-center py-2',
							{
								'text-deposit-paid': isPaid,
								'text-deposit-due': !isPaid,
							},
						)}
					>
						<AiFillDollarCircle size={20} className='me-1' />
						Deposit {isPaid ? 'Paid' : 'Due'}:{' '}
						{numberFormatCurrency(
							company?.currency || 'usd',
							isPaid ? amount : depositFlat - amount || 0,
						)}
					</strong>
				)}
			</div>
		</div>
	)
}

export default ViewMobile
