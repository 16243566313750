import { useEffect, useState } from 'react'

const BREAKPOINT = {
	SM: 'SM',
	MD: 'MD',
	LG: 'LG',
	XL: 'XL',
	XXL: 'XXL',
}

const BREAKPOINT_UNIT = {
	SM: 576,
	MD: 768,
	LG: 992,
	XL: 1200,
}

const checkSize = width => {
	if (width <= BREAKPOINT_UNIT.SM) return BREAKPOINT.SM
	if (width <= BREAKPOINT_UNIT.MD) return BREAKPOINT.MD
	if (width <= BREAKPOINT_UNIT.LG) return BREAKPOINT.LG
	if (width <= BREAKPOINT_UNIT.XL) return BREAKPOINT.XL
	return BREAKPOINT.XXL
}

function debounce(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}

const useBreakpoint = () => {
	const [breakpoint, setSize] = useState(checkSize(window.innerWidth))

	// const breakpoint = useRef(checkSize(window.innerWidth))

	const screenBiggerThan = screen => {
		if (screen === 'SM') return ['MD', 'LG', 'XL', 'XXL'].includes(breakpoint)
		if (screen === 'MD') return ['LG', 'XL', 'XXL'].includes(breakpoint)
		if (screen === 'LG') return ['XL', 'XXL'].includes(breakpoint)
		if (screen === 'XL') return ['XXL'].includes(breakpoint)
		return
	}

	useEffect(() => {
		const debouncedHandleResize = debounce(function windowResize() {
      setSize(checkSize(window.innerWidth))
    }, 100)

		window.addEventListener('resize',debouncedHandleResize)
		return () => {
			window.removeEventListener('resize', debouncedHandleResize)
		}
	}, [])

	return {
		breakpoint,
		BREAKPOINT,
		screen: {
			isMobile: breakpoint === BREAKPOINT.SM,
			isMobileAfter: ['MD', 'LG', 'XL'].includes(breakpoint),
			isTablet: breakpoint === BREAKPOINT.MD,
			isTabletAfter: ['LG', 'XL'].includes(breakpoint),
			isPC: breakpoint === BREAKPOINT.LG,
			isPCAfter: ['XL'].includes(breakpoint),
			isPCL: breakpoint === BREAKPOINT.XL,
		},
		screenBiggerThan,
	}
}

export default useBreakpoint
