import React from 'react'
import { Container } from 'react-bootstrap'
import ManageUsers from '../components/presentations/manageUsers/ManageUsers'
import Layout from '../layout'

const ManageUsersContainer = () => {
	return (
		<Layout>
			<Container>
				<ManageUsers />
			</Container>
		</Layout>
	)
}

export default ManageUsersContainer
