import React from 'react'
import clsx from 'clsx'
import { Navbar, Dropdown, Nav, Image, Container } from 'react-bootstrap'
import { Link, useLocation, NavLink } from 'react-router-dom'

import { NavDropdown } from '../components/controls'
import routes from '../core/constants/routes'
import useAction from './useAction'
import LogoIcon from '../assets/images/logo.svg'
import useAppContext from '../components/app'

const { INVOICES, ESTIMATES, WORKFLOW, ACCOUNTSETTING, MANAGEUSERS } = routes

const NavigationDesktop = () => {
	const { store: { clientPortalUser } } = useAppContext()
	const actions = useAction()
	const location = useLocation()
	const { pathname = '' } = location

	const { admin = false } = clientPortalUser

	return (
		<Container>
			<Navbar className='fw-bold text-uppercase p-0 navigate-desktop'>
				<Navbar.Brand>
					<Link to='/'>
						<Image src={LogoIcon} />
					</Link>
				</Navbar.Brand>

				<Nav className='ms-auto nav-drop-down'>
					<NavLink
						to={ESTIMATES}
						className={clsx('fs-small text-dark mx-4 py-4', {
							'border-bottom text-primary border-primary border-3': pathname === ESTIMATES,
						})}
					>
						Estimates
					</NavLink>
					<NavLink
						to={INVOICES}
						className={clsx('fs-small text-dark mx-4 py-4', {
							'border-bottom text-primary border-primary border-3': pathname === INVOICES,
						})}
					>
						Invoices
					</NavLink>
					<NavLink
						to={WORKFLOW}
						className={clsx('fs-small text-dark mx-4 py-4', {
							'border-bottom text-primary border-primary border-3': pathname === WORKFLOW,
						})}
					>
						VEHICLES
					</NavLink>
				</Nav>
				<Nav>
					<NavDropdown
						className='mx-4 py-3 fs-small border-bottom border-white'
						title={`${clientPortalUser?.firstName} ${clientPortalUser?.lastName}`}
						position='right'
					>
						<Dropdown.Item className='text-capitalize'>
							<NavLink
								to={ACCOUNTSETTING}
								className={clsx('text-dark', { 'bg-none' : pathname === ACCOUNTSETTING})}
							>
								Account Settings
							</NavLink>
						</Dropdown.Item>
						{admin && (
							<Dropdown.Item className='text-capitalize'>
								<NavLink
									to={MANAGEUSERS}
									className={clsx('text-dark', { 'bg-none' : pathname === MANAGEUSERS})}
								>
									Manage Users
								</NavLink>
							</Dropdown.Item>
						)}
						<Dropdown.Item className='text-capitalize' onClick={actions.logout}>
							Log out
						</Dropdown.Item>
						<a
							target='_blank'
							rel="noreferrer"
							href='https://www.mobiletechrx.com/contact-us/'
							className='text-dark dropdown-item'
						>
							Help
						</a>
					</NavDropdown>
				</Nav>
			</Navbar>
		</Container>
	)
}

export default NavigationDesktop
