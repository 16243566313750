import moment from 'moment'
import { isEmpty } from 'lodash'

export function formatAmount(amount = 0) {
  return amount.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

export const formatDate = dateString => {
  return moment(dateString).format('MM/DD/YYYY')
}

export const DOCUMENT_TYPE = {
  ESTIMATE: 'estimate',
  INVOICE: 'invoice',
}
export const AMOUNT_TYPE = {
  FLAT: 'flat',
  PERCENT: 'percent',
}

export const downloadImage = path => {
  // fetch(path, {
  //   method: 'GET',
  //   headers: {},
  // })
  //   .then(response => {
  //     response.arrayBuffer().then(async buffer => {
  //       const blob = new Blob([buffer])
  //       const fileType = await FileType.fromBlob(blob)
  //       const url = window.URL.createObjectURL(blob)
  //       const link = document.createElement('a')
  //       link.href = url
  //       link.setAttribute('download', `photo.${fileType.ext}`) // or any other extension
  //       document.body.appendChild(link)
  //       link.click()
  //     })
  //   })
  //   .catch(err => {
  //     console.log(err)
  //   })
  return alert('waiting')
}

export const DETAIL_PORTAL = 'Detail Portal - Mobile Tech RX'

export const getVehicleInfo = (vehicle) => {
  const { year, make, model, color } = vehicle;

  if (isEmpty(year) && isEmpty(make) && isEmpty(model)) {
    return 'vehicle';
  }
  if (isEmpty(year) || isEmpty(make) || isEmpty(model)) {
    const info = `${year} ${make} ${model}${color ? `, ${color}` : ''}`.trim()
    return `vehicle (${ info })`;
  }
  return `${year} ${make} ${model}${color ? `, ${color}` : ''}`;
}