import clsx from 'clsx'
import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { FaEye, FaRegEyeSlash } from 'react-icons/fa'
import FormLabel from './FormLabel'

const FormPassword = React.forwardRef(
	(
		{
			basic,
			error,
			errorMessage,
			label,
			controlId,
			wrapperClass = '',
			className = '',
      togglePassword = false,
			...propsInput
		},
		ref,
	) => {
    const [isPassword, setType] = useState(true)

    const type = isPassword ? 'password' : 'text'
    propsInput.type = type

		return (
			<Form.Group
				controlId={controlId}
				className={wrapperClass}
			>
				<FormLabel label={label} />
        <div className='position-relative'>
          <input
            className={clsx('form-control rounded', className , {
              'pe-4': togglePassword,
              'bg-form': basic,
              'border-0': !error,
              'border-danger': !!error
            })}
            ref={ref}
            {...propsInput}
          />
          {togglePassword && (
            <div className='position-absolute top-50 translate-middle-y d-inline-block'
              onClick={(event) => {
                event.preventDefault();
                setType(!isPassword)
              }}
              style={{ right: '.4rem' }}
            >
              {isPassword && <FaEye className='text-primary' />}
              {!isPassword && <FaRegEyeSlash className='text-dark' />}
            </div>
          )}
        </div>
				
				<Form.Control.Feedback className='text-start d-block' type='invalid'>
					{errorMessage}
				</Form.Control.Feedback>
			</Form.Group>
		)
	},
)

export default FormPassword
