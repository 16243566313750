import * as routesUtils from '../utils/RoutesUtils'
import { API } from '../core/app.routes'
import httpClient from '../core/httpClient'

const userService = () => {
	return {
		edit: body => {
			const path = routesUtils.getApiPath(API.USER)
			return httpClient.put(path, body)
		},
	}
}

export default userService
