import { useMemo, useState } from 'react'
import { Accordion, Button, Image } from 'react-bootstrap'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'

import IconDownload from '../../assets/images/icons/download.svg'
import IconPlayMedia from '../../assets/images/icons/play_media.svg'
import { SERVER_DATA } from '../../core/constants/app'
import { downloadAllFiles, getDocuments, getPhotosVehicle, getScansVehicle } from '../../utils/invoice'
import useAppContext from '../app'

const PhotosDesktop = ({ onShowPhoto, vehicle }) => {
	const { setIsLoading } = useAppContext()
	const showPhotos = (index) => {
		onShowPhoto(vehicle, index)
	}

	const [activeKey, setActiveKey] = useState('0')

	const fileDocuments = useMemo(() => {
		return getDocuments(vehicle)
	}, [vehicle])

	const photos = useMemo(() => {
		return getPhotosVehicle(vehicle)
	}, [vehicle])

	const scans = useMemo(() => {
		return getScansVehicle(vehicle)
	}, [vehicle])

	if (photos.length <= 0 && fileDocuments.length <= 0 && scans.length <= 0) return null

	return (
		<div className='py-3 position-relative' style={{ zIndex: 2 }}>
			<Accordion
				defaultActiveKey="0"
				onSelect={setActiveKey}
				className='text-end'
			>
				<Accordion.Toggle
					as={Button}
					variant='default'
					eventKey='0'
					className='ms-auto fs-sub text-primary text-uppercase fw-bold'
				>
					{activeKey === '0' ? (
						<>
							Hide Media ({photos.length}) and Documents ({fileDocuments.length + scans.length})
							<FaChevronUp size={14} className='ms-2' />
						</>
					) : (
						<>
							See Media ({photos.length}) and Documents ({fileDocuments.length + scans.length})
							<FaChevronDown size={14} className='ms-2' />
						</>
					)}
				</Accordion.Toggle>
				<Accordion.Collapse eventKey='0'>
					<div className='text-start'>
					{(fileDocuments.length > 0 || scans.length > 0) && (
						<>
							<strong className='fs-small'>Documents</strong>
							<div className='p-4'>
								{fileDocuments.map(({ fileName, path }, index) => {
									 return (
										<div key={index.toString()}>
											<a href={path} target='_blank' rel='noreferrer'>{fileName}</a>
										</div>
									 )
								})}
								{scans.map(({ scanType, path }, index) => {
									 return (
										<div key={index.toString()}>
											<a href={path} target='_blank' rel='noreferrer'>{scanType}</a>
										</div>
									 )
								})}
							</div>
						</>
					)}


						<strong className='fs-small'>Media</strong>
						<div className='p-4 text-start d-grid grid-auto-columns grid-template-areas-4 grid-gap-4'>
							{photos.map((photo, index) => {
								return (
									<div key={photo.date}>
										<div className='media-container'>
											{photo.videoURL && (
												<div className='play-media-overlay'>
													<Image
														src={IconPlayMedia}
														className='w-100' alt={photo.name}
														onClick={() => showPhotos(index)}
													/>
												</div>
											)}
											<Image
												src={`${SERVER_DATA}/${photo.url}`}
												className='w-100' alt={photo.name}
												onClick={() => showPhotos(index)}
											/>
										</div>
										<div className='py-3 text-center'>{photo.name}</div>
									</div>
									)
								})}
							</div>

							<div className='text-center'>
								<Button
									variant='default'
									className='ms-auto text-primary text-uppercase fs-small fw-bold'
									onClick={() => {
										setIsLoading(true)
										downloadAllFiles(vehicle, () => {
											setIsLoading(false)
										})
									}}
								>
									Download all Media
                	<Image src={IconDownload} className='ms-2' />
								</Button>
							</div>
						</div>
					</Accordion.Collapse>
				</Accordion>
			</div>
	)
}

export default PhotosDesktop
