import CompanyLogo from './Logo'
import Info from './Info'

const CompanyInfo = ({ company, isBiggerThanMD }) => {

  return (
    <div className='d-flex flex-column flex-md-row align-items-center'>
      <CompanyLogo logo={company.logo || ''} isBiggerThanMD={isBiggerThanMD} />
      <Info company={company || ''} isBiggerThanMD={isBiggerThanMD} />
    </div>
  )
}

export default CompanyInfo
