import React from 'react'
import { Image } from 'react-bootstrap'

import NavigationMobile from './Navigation.mobile'
import NavigationDesktop from './Navigation.desktop'
import { useBreakpoint } from '../hooks'
import logo from '../assets/images/logo.svg'
import './layout.scss'
import useAppContext from '../components/app'


const LayoutHeader = () => {

	const { store: { isLog } } = useAppContext()
	const {
		BREAKPOINT,
		screenBiggerThan,
	} = useBreakpoint()

	const isBiggerThanMD = screenBiggerThan(BREAKPOINT.MD)

	if (!isLog) {
		return (
			<header className='text-center'>
				<div className='bg-white py-4 shadow-sm '>
					<Image src={logo} />
				</div>
			</header>
		)
	}

	return (
		<header
			id='header-app'
			className='bg-white shadow-sm position-sticky'
			style={{ top: 0, zIndex: 1020 }}
		>
			{isBiggerThanMD && <NavigationDesktop />}
			{!isBiggerThanMD && <NavigationMobile />}
		</header>
	)
}

export default LayoutHeader
