import React from 'react'
import { useBreakpoint } from '../../../hooks'
import useContainerContext from '../../containerContext'
import Paging from '../../paging'
import SortDropdown from '../../sortDropdown'
import Filter from './Filter'
import FilterMobile from './Filter.mobile'
import TableDesktop from './Table.desktop'
import TableMobile from './Table.mobile'

const listSort = [
	{ label: 'Date', sortKey: 'dateCreated' },
	{ label: 'Vendor', sortKey: 'client.name' },
	{ label: 'PO Number', sortKey: 'purchaseOrder' },
	{ label: 'Invoice #', sortKey: 'number' },
	{ label: 'Total', sortKey: 'total' },
]

const Invoices = () => {
	const {
		setState,
		state: { sorted, query },
		data,
	} = useContainerContext()
	const { BREAKPOINT, screenBiggerThan } = useBreakpoint()
	const isScreenBiggerThanMD = screenBiggerThan(BREAKPOINT.MD)

	const onSort = sortChange => {
		setState(prev => ({ ...prev, sorted: sortChange }))
	}

	return (
		<div className='my-5'>
			<h5 className='text-center text-md-start fw-bold'>INVOICES SUMMARY</h5>

			<Filter isScreenBiggerThanMD={isScreenBiggerThanMD} />

			{isScreenBiggerThanMD && <TableDesktop />}
			{!isScreenBiggerThanMD && (
				<>
					<div className='text-end'>
						<div className='d-inline-block'>
							<SortDropdown
								listSort={listSort}
								sorted={sorted}
								onSort={onSort}
							/>
						</div>
						<div className='d-inline-block'>
							<FilterMobile />
						</div>
					</div>
					<TableMobile />
				</>
			)}
			<Paging
				total={data.length}
				limit={query.limit}
				skip={query.skip}
				onChange={({ skip, limit }) => {
					setState(prev => ({ ...prev, query: { ...query, skip, limit } }))
				}}
			/>
		</div>
	)
}
export default Invoices
