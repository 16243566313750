import { Button, Image } from 'react-bootstrap'
import { FaCheckCircle } from 'react-icons/fa'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

import useContainerContext from '../../containerContext'
import { Tooltip } from '../../controls'
import { SortableColumn } from '../../table-plugins/sortable'
import Table from '../../table'
import { formatToLocalTime } from '../../../utils/dates'
import { numberFormatCurrency } from '../../../utils/helpers'
import DownloadPhotos from '../../../assets/images/icons/photo-download.svg'
import DownloadPhotosDisabled from '../../../assets/images/icons/photo-downloaddisabled.svg'
import { downloadPhotosRecord, getPhotos } from '../../../utils/invoice'
import AvatarName from '../../avatarName'
import useAppContext from '../../app'

const TableDesktop = () => {
	const { data = [], state, setState } = useContainerContext()
	const { setIsLoading } = useAppContext()
	return (
		<Table
			data={data}
			rowKey='_id'
			state={state}
			setState={setState}
			className='primary'
			borderless
		>
			<SortableColumn
				dataKey='dateCreated'
				width='15%'
				headerLabel={
					<u className='d-inline-block me-1'>
						<Tooltip
							placement='bottom'
							message={
								<span>
									{`Click to sort by date
									ascending or descending`}
								</span>
							}
						>
							Date
						</Tooltip>
					</u>
				}
				className='text-start'
				render={value => formatToLocalTime(value)}
			/>
			<SortableColumn
				dataKey='company.name'
				headerLabel={
					<u className='d-inline-block me-1'>
						<Tooltip
							placement='bottom'
							message={
								<span>
									{`Click to sort alphabetically
									ascending or descending`}
								</span>
							}
						>
							Vendor
						</Tooltip>
					</u>
				}
				render={(value, { rowData }) => {
					const { client = {} } = rowData
					const active = client?._id?.group?.companyOverride?.active
					const name = client?._id?.group?.companyOverride?.name
					return (
						<div className='d-flex align-items-center'>
							<AvatarName
								size='medium'
								value={active ? name : value}
								className='me-2'
							/>
							<div>{active ? name : value}</div>
						</div>
					)
				}}
			/>

			<SortableColumn
				dataKey='number'
				width='15%'
				headerLabel={
					<u className='d-inline-block me-1'>
						<Tooltip
							placement='bottom'
							message={
								<span>Click to sort by number ascending or descending</span>
							}
						>
							Estimate #
						</Tooltip>
					</u>
				}
				className='text-start'
				render={(number, { rowData: { _id } }) => {
					return (
						<Link to={`/estimates/${_id}`} className='text-primary'>
							#{number}
						</Link>
					)
				}}
			/>
			<SortableColumn
				dataKey='total'
				width='15%'
				headerLabel={
					<u className='d-inline-block me-1'>
						<Tooltip
							placement='bottom'
							message={
								<span>{`Click to sort by dollar amount high or low`}</span>
							}
						>
							Total
						</Tooltip>
					</u>
				}
				className='text-start'
				render={(value, { rowData }) =>
					numberFormatCurrency(rowData.company?.currency || 'usd', value || 0)
				}
			/>
			<Table.Column
				id='photos'
				dataKey='_id'
				width='15%'
				header='Photos'
				className='text-center'
				render={(_id, { rowData }) => {
					const isPhotos = getPhotos(rowData)
					return (
						<Button
							variant='default'
							onClick={() => {
								if (isPhotos.length > 0) {
									setIsLoading(true)
									downloadPhotosRecord(rowData, () => {
										setIsLoading(false)
									})
								}
							}}
						>
							<Image
								className='pointer'
								src={
									isPhotos.length > 0 ? DownloadPhotos : DownloadPhotosDisabled
								}
								alt=''
							/>
						</Button>
					)
				}}
			/>
			<SortableColumn
				dataKey='status'
				width='10%'
				headerLabel={<u className='d-inline-block me-1'>Approved?</u>}
				className='text-center'
				render={(totalApproved = 0, { rowData }) => {
					const { status } = rowData

					return (
						<FaCheckCircle
							className={clsx({
								'text-success': !status,
								'text-gray': status,
							})}
						/>
					)
				}}
			/>
		</Table>
	)
}

export default TableDesktop
