import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { AppProvider } from './components/app'
import App from './App'

const queryClient = new QueryClient()

const Root = () => {
	return (
		<QueryClientProvider client={queryClient}>
			<BrowserRouter>
				<AppProvider>
					<App />
				</AppProvider>
			</BrowserRouter>
		</QueryClientProvider>
	)
}

export default Root
