import clsx from 'clsx'
import { Button } from 'react-bootstrap'
import { FaCheckCircle } from 'react-icons/fa'
import { IoWarningOutline } from 'react-icons/io5'
import moment from 'moment'

import useContainerContext from '../../containerContext'
import { numberFormatCurrency } from '../../../utils/helpers'

const GroupButton = ({ isBiggerThanMD, isShowPayDeposit, isBiggerThanLG }) => {
	const {
		data: { depositFlat = 0, payment = {}, status },
		toPayment, toApproveEst, payments
	} = useContainerContext();
	const paymentHistory = payments[0];
	const { amount = 0 } = payment;
	const isPaid = depositFlat > 0 && amount >= depositFlat;
	const isShowPaymentHistory = amount > 0 && status;
	const isShowPaymentStatus = depositFlat > 0 || isShowPayDeposit;

	return (
		<div
			className={clsx({
				'mt-5': !isBiggerThanMD,
				'mt-3': !isBiggerThanLG,
				'd-flex': true,
				'justify-content-around': isShowPaymentStatus,
				'justify-content-end': depositFlat <= 0,
				'justify-content-center': depositFlat <= 0 && !isBiggerThanLG,
			})}
		>
			<div className={`${!status && 'd-flex align-items-center'}`}>
				{
					status ? (
						<>
							<Button
								variant='warning'
								className='fs-small mx-auto text-white fw-bold py-2 px-4 rounded-pill d-flex align-items-center'
							>
								<IoWarningOutline size={20} className='me-2' />
								Needs Approval
							</Button>
							<Button
								className='fs-sub fw-bold text-uppercase w-100 py-3 mt-3'
								onClick={toApproveEst}
							>
								Approve Estimate
							</Button>
						</>
					) : (
						<Button
							variant='success'
							className='fs-small mx-auto text-white fw-bold py-2 px-4 rounded-pill d-flex align-items-center justify-content-center'
							style={{ minWidth: '100px' }}
						>
							<FaCheckCircle size={20} className='me-2' />
							Approved for Work
						</Button>
					)
				}

			</div>
			<div>
				{!isPaid ? (
					<div className='text-center'>
						{
							isShowPaymentStatus && (
								<Button
									variant='warning'
									className='fs-small mx-auto text-white fw-bold py-2 px-4 rounded-pill d-flex align-items-center'
								>
									<IoWarningOutline size={20} className='me-2' />
									Needs Payment
								</Button>
							)
						}
						{
							isShowPaymentHistory ? 
								<div>
									<span className='text-gray'>
										{
											paymentHistory ? <>
												{`${moment(paymentHistory.dateCreated).format('MM/DD/YYYY')} ${numberFormatCurrency(paymentHistory.currency, paymentHistory.amount)}`} Latest <br/> Payment Received
											</> : null
										}
									</span>
								</div> : null
						}
						{isShowPayDeposit && (
							<>
								{
									status && 
										<Button className='fs-sub fw-bold text-uppercase w-100 py-3 mt-3' onClick={() => toPayment()}>
											Pay Deposit
										</Button>
								}
							</>
						)}
					</div>
				) : (
					<div className='text-center'>
						<Button
							variant='success'
							className='fs-small mx-auto text-white fw-bold py-2 px-4 rounded-pill d-flex align-items-center justify-content-center'
							style={{ minWidth: '100px' }}
						>
							<FaCheckCircle size={20} className='me-2' />
							Deposit Paid
						</Button>
						{
							isShowPaymentHistory ? 
								<span className='text-gray mt-2'>
									{
										paymentHistory ? <>
											{`${moment(paymentHistory.dateCreated).format('MM/DD/YYYY')} ${numberFormatCurrency(paymentHistory.currency, paymentHistory.amount)}`} Latest <br/> Payment Received
										</> : null
									}
								</span> : null
						}
					</div>
				)}
			</div>
		</div>
	)
}

export default GroupButton
