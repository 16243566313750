import { useMemo } from 'react'
import { Button, Image } from 'react-bootstrap'
import { SERVER_DATA } from '../../core/constants/app'
import {
	downloadAllFiles,
	getDocuments,
	getPhotosVehicle,
} from '../../utils/invoice'
import useAppContext from '../app'
import Download from '../../assets/images/icons/download-all.svg'
import IconPlayMedia from '../../assets/images/icons/play_media.svg'

const PhotosMobile = ({ onShowPhoto, vehicle = {} }) => {
	const { setIsLoading } = useAppContext()
	const photos = useMemo(() => {
		return getPhotosVehicle(vehicle)
	}, [vehicle])

	const fileDocuments = useMemo(() => {
		return getDocuments(vehicle)
	}, [vehicle])

	if (photos.length <= 0) return null

	return (
		<>
			<div className='mt-3'>
				{fileDocuments.length > 0 && (
					<>
						<span className='d-block fs-small fw-600'>Documents</span>
						<div className='p-2'>
							{fileDocuments.map(({ fileName, path }, index) => {
								return (
									<div key={index.toString()}>
										<a href={path} target='_blank' rel='noreferrer'>
											{fileName}
										</a>
									</div>
								)
							})}
						</div>
					</>
				)}

				{photos.length > 0 && (
					<>
						<span className='d-block fs-small fw-600 mb-2'>Media</span>
						<div className='d-grid grid-auto-columns grid-template-areas-5 grid-gap-2'>
							{photos.map((photo, index) => {
								return (
									<div className='media-container-mobile'>
										{photo.videoURL && (
											<div className='play-media-overlay'>
												<Image
													src={IconPlayMedia}
													className='w-100' alt={photo.name}
													onClick={() => onShowPhoto(vehicle, index)}
												/>
											</div>
										)}
										<Image
											key={photo._id}
											src={`${SERVER_DATA}/${photo.url}`}
											className='w-100'
											onClick={() => onShowPhoto(vehicle, index)}
										/>
									</div>
								)
							})}
						</div>
					</>
				)}

				<div className='text-center pt-3'>
					<Button
						variant='default'
						className='fs-sub text-primary fw-bold text-uppercase p-0'
						onClick={() => {
							setIsLoading(true)
							downloadAllFiles(vehicle, () => {
								setIsLoading(false)
							})
						}}
					>
						Download All Media
						<Image src={Download} className='ms-2'/>
					</Button>
				</div>
			</div>
		</>
	)
}

export default PhotosMobile
