import React, { createContext, useState, useContext } from 'react'
import { ToastContainer } from 'react-toastify'
import { useStorageApp } from '../../hooks'
const AppContext = createContext()

export const AppProvider = ({ children }) => {
	const storage = useStorageApp()
	const { clientPortalUser = null, clientPortalCompany = null } = storage
	const [isLoading, setIsLoading] = useState(false)
	const [isBackdrop, setIsBackdrop] = useState(false)

	const [store, setStore] = useState({
		company: null,
		subscription: null,
		clientPortalUser,
		clientPortalCompany,
		isLog: !!clientPortalUser,
	})

	return (
		<AppContext.Provider
			value={{
				isLoading,
				setIsLoading: setIsLoading,
				setIsBackdrop,
				store,
				setStore,
			}}
		>
			{children}
			{isLoading && (
				<div className='loading'>
					<div className='loader' />
				</div>
			)}
			{isBackdrop && <div className='backdrop' />}
			<ToastContainer
				toastClassName={'rounded p-3'}
				position='top-right'
				autoClose={4000}
				hideProgressBar={false}
				newestOnTop
				closeOnClick
				pauseOnVisibilityChange
				draggable
				pauseOnHover
			/>
		</AppContext.Provider>
	)
}

const useAppContext = () => {
	return useContext(AppContext)
}

export default useAppContext
