import { HiOutlineLocationMarker } from 'react-icons/hi'

const CompanyAddress = ({ address }) => {
	return (
		<div className='mt-2'>
			<HiOutlineLocationMarker className='text-primary me-1' />
			{address}
		</div>
	)
}

export default CompanyAddress
