import clsx from 'clsx'
import React from 'react'
import { Button, Col, Container, Form, Image } from 'react-bootstrap'
import { useMutation } from 'react-query'
import { Link } from 'react-router-dom'
import { useApi, useLoading, useYupForm } from '../../../hooks'
import { FormInput } from '../../formControl'
import LayoutAuth from '../../LayoutAuth'
import { Email } from './schema'

const Forgot = () => {
	const { mainService } = useApi()

	const {
		isLoading,
		isSuccess,
		isError,
		error,
		mutate: reset,
	} = useMutation(data => mainService.reset(data))
	const useForm = useYupForm(Email, { email: '' })
	const {
		register,
		handleSubmit,
		formState: { isDirty, isValid, errors },
	} = useForm

	const onSubmit = data => {
		return reset({ email: data.email })
	}

	useLoading(isLoading)

	return (
		<LayoutAuth>
			<Container
				style={{ maxWidth: '350px' }}
				className='flex-grow-1 d-flex flex-column justify-content-center align-items-center mb-5'
			>
				<Col sm={12} className='text-center w-100'>
					<div className='fs-small text-center'>
						<strong className='d-block'>Reset password</strong>
						<span>Don't worry, happens to the best of us</span>
					</div>
					{isSuccess && (
						<>
							<div className='fs-small text-center mb-3'>
								<div className='mx-auto mt-4'>
									If an account exists for the email address you entered, an
									email will be sent with your reset link.
								</div>
							</div>

							<Link to='/login' className='mt-3'>
								<small className='text-uppercase fw-bold'>Back to Login</small>
							</Link>
						</>
					)}
					{isError && (
						<div className='fs-small text-center mb-3'>
							<span className='text-capitalize'>{error.message}</span>
						</div>
					)}

					<Form
						className={clsx('form form-default py-4 px-3', {
							'd-none': isSuccess,
						})}
						onSubmit={handleSubmit(onSubmit)}
					>
						<FormInput
							basic
							label='Type your email address'
							placeholder='Type email address...'
							error={!!errors.email}
							errorMessage={errors.email?.message}
							wrapperClass='mb-4 mt-3'
							{...register('email')}
						/>
						<Button
							className='w-100'
							type='submit'
							disabled={!isDirty || !isValid}
						>
							<small className='fw-bold text-uppercase'>
								Email me a reset link
							</small>
						</Button>
					</Form>
				</Col>
			</Container>
		</LayoutAuth>
	)
}
export default Forgot
