import React from 'react'
import './riband.scss'
import { Link } from 'react-router-dom'
import { Button, Image } from 'react-bootstrap'
import iconClose from '../../assets/images/icons/ic16-close.svg'

export const Riband = () => {
	const [show, setShow] = React.useState(true);
	if (!show) {
		return '';
	}
	return (
		<div className='riband'>
			<Button className='button-close' onClick={() => { setShow(false); }}>
				<Image src={iconClose} alt='' />
			</Button>
			<div className='bg-primary text-white py-2 text-center'>
				<span className='question my-1 mx-4 d-inline-block'>
					Want to see all your vendor’s invoices?
				</span>
				<Link to='/signup'>
					<Button
						variant='default'
						className='ms-2 bg-white text-base py-2 px-4 my-1'
					>
						CREATE AN ACCOUNT
					</Button>
				</Link>
			</div>
		</div>
	)
}

export default Riband
