import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import FileType from 'file-type/browser'
import toast from '../components/toast'

export const downloadTemplateFile = url => {
	const link = document.createElement('a')
	link.href = url
	link.click()
	toast.success({
		title: 'Successful.',
		body: 'Template file downloaded'
	})
}

// arrayImages = [{ fileName, path }]

export const downloadZipFile = (zipName, arrayImages = []) => {
	if (arrayImages <= 0) return
	const zip = new JSZip()
	const tasks = []

	for (let i = 0; i < arrayImages.length; i++) {
		const { fileName, path } = arrayImages[i];
		tasks.push(
			new Promise((resolve, reject) => {
				return fetch(path)
					.then(response => {
						return response.blob()
					})
					.then(async blob => {
						const fileType = await FileType.fromBlob(blob)
						if (fileType) {
							zip.file(`${i + 1}.${fileName}.${fileType.ext}`, blob)
							resolve()
						} else {
							reject('File not found.')
						}
					})
					.catch(reject)
			}),
		)
	}

	return Promise.all(tasks).then(() => {
		return zip.generateAsync({ type: 'blob' }).then(content => {
			return saveAs(content, `${zipName}.zip`)
		})
	})
}

export const downloadImage = (path, fileName = '', callback) => {
	fetch(path, {
		method: 'GET',
	})
		.then(response => {
			response.arrayBuffer().then(async buffer => {
				const blob = new Blob([buffer])
				const fileType = await FileType.fromBlob(blob)
				const url = window.URL.createObjectURL(blob)
				const link = document.createElement('a')
				link.href = url
				link.target = '_blank'
				link.setAttribute('download', `${fileName}.${fileType.ext}`) // or any other extension
				document.body.appendChild(link)
				link.click()
			})
		})
		.catch(err => {
			console.log(err)
		})
		.finally(() => {
			callback()
		})
}
