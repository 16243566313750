import { Image, Button } from 'react-bootstrap'
import ImgVehicleMobile from '../../assets/images/car/vehicle-desktop.svg'
import { useMutation } from 'react-query'

import ServicesMobile from './Services.mobile'
import PhotosMobile from './Photo.mobile'
import { numberFormatCurrency } from '../../utils/helpers'
import { getCarInfo, getServiceLines } from '../../utils/invoice'
import { useApi, useLoading } from '../../hooks'
import toast from '../toast'
import DocumentTop from '../../assets/images/icons/document-top.svg'
import DocumentBot from '../../assets/images/icons/document-bot.svg'
import { VEHICLE_STATUS } from '../../core/constants1'
import DontWorkVehicleIcon from '../../assets/images/icons/dont-work-vehicle.svg'
import ApproveForWorkIcon from '../../assets/images/icons/approve-for-work.svg'

const ViewMobile = ({
  vehicle,
  index,
  onShowPhoto,
  record = {},
  isShowSupplement = false,
  isShowParts = false,
  showEstAdasChecklists,
	showInvAdasChecklists,
  handleApproveOrRejectVehicle,
  isEstimateDetail = false,
	isShowButtonApproveOrReject = false,
}) => {
  const { currency = 'usd' } = record;
  const { image, year = '', vin = '', stock = '', total = 0, serviceLines = {}, status } = vehicle;
  const isRejectVehicle = status === VEHICLE_STATUS.DECLINED && isEstimateDetail;
	const isApprovedVehicle = status === VEHICLE_STATUS.APPROVED && isEstimateDetail;
  const { pdfService } = useApi();
	const { isLoading, mutate: downloadPdf } = useMutation(({ recordId, type }) => {
    const services = (type === 'parts') ? pdfService.estimatePartsPDF : (type === 'supplement') ? pdfService.estimateSupplementPDF : ((type === 'adas_estimate')) ? pdfService.estimateADASPDF : pdfService.invoiceADASPDF
    return services({ recordId }, false)
  }, {
		onError: error => toast.error({ title: 'View PDF errors', body: error.message })
	})

  function pad(d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
  }

  useLoading(isLoading)

  return (
    <div className='mx-4 shadow mt-3'>
      { isRejectVehicle && <div className='reject-vehicle rounded-top' /> }
			{ isApprovedVehicle && <div className='approved-vehicle rounded-top' /> }
      <div className={`py-4 px-3 ${isRejectVehicle && 'position-relative'}`}>
      { isRejectVehicle && <div className='blur' /> }
        <div className='d-flex'>
					<span className='d-inlone-block fw-bold'>{pad(Number(index) + 1)}</span>

          <Image style={{ width: '20%', objectFit: 'contain' }} src={image ? image.replace('color', 'colorCropped') : ImgVehicleMobile} />
          <div className='flex-grow-1 ms-2'>
            <div className='d-flex justify-content-between fs-base'>
              <span>{year}</span>
              <span className='fw-600'>{numberFormatCurrency(currency, total)}</span>
            </div>
            <strong>{getCarInfo(vehicle)}</strong>
            <span className='d-block fs-sub'>
              <span className='fw-600'>VIN: </span>
              <span>{vin || 'None Added'}</span>
            </span>
            <span className='d-block fs-sub'>
              <span className='fw-600'>STOCK/RO: </span>
              <span>{stock || 'None Added'}</span>
            </span>
          </div>
        </div>
        {isShowSupplement && (
          <div className='text-center mt-3'>
            <Button
              variant='default'
              onClick={() => downloadPdf({ recordId: record._id, type: 'supplement' })}
              className='text-primary text-uppercase fw-bold mx-auto py-1'>
                View insurance supplement
								<Image src={DocumentTop} className='ms-2'/>
            </Button>
          </div>
          )}

        {isShowParts && (
          <div className='text-center'>
            <Button
              variant='default'
              onClick={() => downloadPdf({ recordId: record._id, type: 'parts' })}
              className='text-primary text-uppercase fw-bold mx-auto py-1'>
                Parts List
								<Image src={DocumentBot} className='ms-2'/>
            </Button>
          </div>
        )}

        {showEstAdasChecklists && (
          <div className='text-center'>
            <Button
              variant='default'
              onClick={() => downloadPdf({ recordId: record._id, type: 'adas_estimate' })}
              className='text-primary text-uppercase fw-bold mx-auto py-1'>
                ADAS Checklists
								<Image src={DocumentBot} className='ms-2'/>
            </Button>
          </div>
        )}

        {showInvAdasChecklists && (
          <div className='text-center'>
            <Button
              variant='default'
              onClick={() => downloadPdf({ recordId: record._id, type: 'adas_invoice' })}
              className='text-primary text-uppercase fw-bold mx-auto py-1'>
                ADAS Checklists
								<Image src={DocumentBot} className='ms-2'/>
            </Button>
          </div>
        )}

        <div className='d-flex justify-content-end'>
          { isRejectVehicle && (
            <div className='btn-reject'>Rejected</div>
          )}
          { isApprovedVehicle && (
            <div className='btn-approved'>Approved</div>
          )}
        </div>

      
        {/* services */}
        <ServicesMobile serviceLines={serviceLines} />

        {
          isShowButtonApproveOrReject ? (
            <div>
              {
                status === VEHICLE_STATUS.UNDECIDED ? (
                  <div className='d-flex flex-column align-items-center pt-2'>
                    <Button 
                      variant='white' 
                      className='d-flex align-items-center pb-0' 
                      onClick={() => handleApproveOrRejectVehicle({ ...vehicle, status: VEHICLE_STATUS.APPROVED })}
                    >
                      <span className='mr-1 text-primary'>APPROVE FOR WORK</span>
                      <Image src={ApproveForWorkIcon} />
                    </Button>
                    <Button 
                      className='d-flex align-items-center pb-0' 
                      onClick={() => handleApproveOrRejectVehicle({ ...vehicle, status: VEHICLE_STATUS.DECLINED })}
                      variant='white'
                    >
                      <span className='mr-1 text-reject-vehicle'>DON'T WORK ON THIS VEHICLE</span>
                      <Image src={DontWorkVehicleIcon}/>
                    </Button>
                  </div>
                ) : null
              }
              {
                status === VEHICLE_STATUS.APPROVED ? (
                  <div className='d-flex justify-content-end'>
                    <Button 
                      className='d-flex align-items-center' 
                      onClick={() => handleApproveOrRejectVehicle({ ...vehicle, status: VEHICLE_STATUS.DECLINED })}
                      variant='white'
                    >
                      <span className='mr-1 text-reject-vehicle'>DON'T WORK ON THIS VEHICLE</span>
                      <Image src={DontWorkVehicleIcon}/>
                    </Button>
                  </div>
                ) : null
              }
            </div>
          ) : null
        }

        {/* photos */}
        <PhotosMobile onShowPhoto={onShowPhoto} vehicle={vehicle} />
      </div>

      {
        isShowButtonApproveOrReject ? (
          <div className='d-flex justify-content-center pb-2'>
						{
							status === VEHICLE_STATUS.DECLINED ? (
								<Button 
									variant='white' 
									className='d-flex align-items-center' 
									onClick={() => handleApproveOrRejectVehicle({ ...vehicle, status: VEHICLE_STATUS.APPROVED })}
								>
									<span className='mr-1 text-primary'>APPROVE FOR WORK</span>
									<Image src={ApproveForWorkIcon} />
								</Button>
							) : null
						}
					</div>
        ) : null
      }
    </div>
  )
}

export default ViewMobile
