import { WORKFLOW_DOCTYPE } from "../../../core/constants/workflow.constant";
import StopWatch from '../../../assets/images/workflow/stopwatch.svg';
import StopWatchRed from '../../../assets/images/workflow/stopwatch-red.svg';
import StopWatchGreen from '../../../assets/images/workflow/stopwatch-green.svg';
import { TIER } from "../../../core/constants1";

export const isEmpty = (value) => {
    return value === undefined ||
        value === null ||
        (typeof value === 'object' && Object.keys(value).length === 0) || 
        (typeof value === 'string' && value.trim().length === 0);
}

export const checkColorTimer = (item) => {
	let icon;
	const { tracking: { services } } = item;
	const isServiceRunning = services.some((service) => {
		return service.timeKeeping.status === true;
	});
	if (services.length === 0) {
		icon = (<img src={StopWatch} alt='stopwatch' hidden />);
	} else if (isServiceRunning) {
		icon = (<img src={StopWatchGreen} alt='stopwatch' />);
	} else {
		const isRed = services.some((service) => {
			return service?.assignedTo && service.timeKeeping.totalMS !== 0
		})
		if (isRed) {
			icon = (<img src={StopWatchRed} alt='stopwatch' />);
		}
	}
	return icon;
};

export const renderTechnician = (item, companies) => {
	let text = '';
	const { assignedTo } = item;
	switch (item.docType) {
	case WORKFLOW_DOCTYPE.ESTIMATE:
		text = `Estimate #${item.number.toString().padStart(4, '0')} by ${item.createdBy?.name || item.createdBy}`;
		break;
	case WORKFLOW_DOCTYPE.WORK_ORDER:
		if (assignedTo.length === 0) {
			text = `Work order #${item.number.toString().padStart(4, '0')} to Unassigned`;
		} else if (assignedTo.length === 1) {
			text = `Work order #${item.number.toString().padStart(4, '0')} to ${assignedTo[0].user.name}`;
		} else {
			text = `Work order #${item.number.toString().padStart(4, '0')} to Multiple`;
		}
		break;
	case WORKFLOW_DOCTYPE.INVOICE:
		let invoicePrefix = '';
		companies.forEach(element =>{
			if(element.company == item.company && element.prefix){
				invoicePrefix = element?.prefix || '';
				invoicePrefix = invoicePrefix.replaceAll('{CLIENT_ID}', (item.client?.clientID || ''));
			}
		})
		if (assignedTo.length === 0) {
			text = `Invoice	${invoicePrefix} #${item.number.toString().padStart(4, '0')} by Unassigned`;
		} else if (assignedTo.length === 1) {
			text = `Invoice	${invoicePrefix} #${item.number.toString().padStart(4, '0')} by ${assignedTo[0].user.name}`;
		} else {
			text = `Invoice ${invoicePrefix} #${item.number.toString().padStart(4, '0')} by Multiple`;
		}
		break;
	default:
		break;
	}

	return text;
};

export const getColorAssign = (item) =>{
	const { assignedTo } = item;
	switch (item.docType) {
		case WORKFLOW_DOCTYPE.ESTIMATE:
		case WORKFLOW_DOCTYPE.INVOICE:
			return ''
		case WORKFLOW_DOCTYPE.WORK_ORDER:
			if (assignedTo.length === 0){
				return 'text-assign-to-red'
			}
			const total = item.total;
			let totalAssign = 0;
			assignedTo.forEach(element => {
				totalAssign += element.amount
			});
			if(totalAssign == total){
				return 'text-assign-to-grey'
			}else{
				return 'text-assign-to-red'
			}
		default:
			return ''
	}
}

export const accessWorkflow = (vendor) => {
    return vendor?.workflowManagement?.stages && (vendor?.stripe?.tier === TIER.PRO || vendor?.stripe?.tier === TIER.ENTERPRISE)
}