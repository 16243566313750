import CompanyPhone from './Phone'
import CompanyEmail from './Email'
import CompanyAddress from './Address'
import { getAddressCompany } from '../../utils/company'
import clsx from 'clsx'

const Info = ({ company, isBiggerThanMD }) => {
	const { phone, name, email, shopAddress = {} } = company
	const address = getAddressCompany(shopAddress)

	return (
		<div
			className={clsx('text-center text-md-start', {
				'mt-2': !isBiggerThanMD,
			})}
		>
			<h4
        className={clsx('fw-bold', {
          'mb-3': !isBiggerThanMD
        })}
      >
        {name}
      </h4>
      {phone && <CompanyPhone phone={phone} />}
			{email && <CompanyEmail email={email} />}
			{address && <CompanyAddress address={address} />}
		</div>
	)
}

export default Info
