import {
  Children,
  createContext,
  useMemo,
  useReducer,
} from 'react'
import { columnsReducer } from './reducer'
import TableConsumer from './TableConsumer'

export const TableContext = createContext()

const Table = ({
  data = [],
  children,
  initialState,
  onStateChange,
  state,
  setState,
  ...options
}) => {
  const [columns, dispatchColumns] = useReducer(columnsReducer, [])

  const childCount = useMemo(() => Children.count(children), [children])
  const mountedFinish = useMemo(() => childCount && childCount === columns.length, [
    childCount,
    columns.length,
  ])

  return (
    <TableContext.Provider
      value={{ data, columns, options, state, setState, dispatchColumns }}
    >
      {mountedFinish && <TableConsumer />}
      {children}
    </TableContext.Provider>
  )
}

export default Table
