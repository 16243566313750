import React from 'react'
import { useQueryClient } from 'react-query'
import { Button, Col, Form, Image, Modal, Row } from 'react-bootstrap'
import clsx from 'clsx'
import { IoIosCheckmarkCircle } from 'react-icons/io'
import { FaChevronRight } from 'react-icons/fa'
import useContainerContext from '../containerContext'
import card from '../../assets/images/payment/Card.svg'
import { FormInput } from '../formControl'
import { useApi, useBreakpoint, useYupForm } from '../../hooks'
import { paymentReceipt } from './schema'
import Logo from '../../assets/images/logo.svg'
import { numberFormatCurrency } from '../../utils/helpers'
import { formatToLocalTime } from '../../utils/dates'
import useAppContext from '../app'
import toast from '../toast'

const Receipt = ({ isInvoice = true }) => {
	const queryClient = useQueryClient()
	const {
		hideReceipt,
		state: { paymentData },
		data,
	} = useContainerContext()
	const { setIsLoading } = useAppContext()
	const { BREAKPOINT, screenBiggerThan } = useBreakpoint()
	const isBiggerThanMD = screenBiggerThan(BREAKPOINT.MD)
	const isBiggerThanLG = screenBiggerThan(BREAKPOINT.LG)
	const isBiggerThanXL = screenBiggerThan(BREAKPOINT.XL)
	const isBiggerThanSM = screenBiggerThan(BREAKPOINT.SM)

	const { paymentService } = useApi()
	const useForm = useYupForm(paymentReceipt, {
		email: '',
		phone: '',
	})

	const { total = 0, currency = 'usd', number, company, dateCreated } = data
	const { amount = 0 } = paymentData
	const {
		handleSubmit,
		register,
		formState: { errors, isDirty },
	} = useForm

	const onClose = async () => {
		if (isInvoice) {
			await queryClient.invalidateQueries(['invoice-detail'])
		} else {
			await queryClient.invalidateQueries(['estimatesDetail'])
		}
		setIsLoading(false)
		hideReceipt()
	}

	const onSubmit = async (dataForm) => {
		setIsLoading(true)
		const { paymentId } = paymentData
		const body = {
			payment: paymentId,
			invoice: data._id,
			estimate: '',
			emailAddress: dataForm.email,
			phoneNumber: dataForm.phone.toString()
		}
		if (!isInvoice) {
			body.invoice = '';
			body.estimate = data._id;
		}

		await paymentService.sendReceipt({ paymentId }, body)
			.then(() => {
				toast.success({
					title: 'Payment Successful',
					body: `Your  payment has been successful. A confirmation ${body.emailAddress} has been sent to ${body.emailAddress}`
				})
				onClose()
			})
			.catch(error => {
				toast.error({
					title: 'Send receipt fail',
					body: error.message
				})
				setIsLoading(false)
			})
	}

	return (
		<Modal show dialogClassName='modal-fullscreen-lg-down modal-lg modal-xl' onHide={onClose}>
			<Modal.Header closeButton onHide={hideReceipt} className='me-3 mt-3' />
			<Modal.Body>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Row>
						<Col xl={8} md={7} className='text-center'>
							<div className='company-logo border-secondary rounded-circle shadow'>
								<Image src={company.logo} alt="" />
							</div>
							<h5 className='fw-bold mt-2 mb-4'>{company.name}</h5>
							{isBiggerThanMD && (
								<>
									<strong>RECEIPT</strong>
									<small className='mt-5 fw-bold d-block'>
										{isInvoice ? `Invoice Total ${numberFormatCurrency(currency, total)}` : `Estimate Total ${numberFormatCurrency(currency, total)}`}
									</small>
									<div className='text-sub mb-3'>
										{isInvoice ? `Invoice #${number} · ${formatToLocalTime(dateCreated)}` : `Estimate #${number} · ${formatToLocalTime(dateCreated)}`}
									</div>
									<div className='mt-5'>
										<div
											className={clsx(
												'd-flex justify-content-between mx-auto',
												{
													'w-75': !isBiggerThanXL,
													'w-50': isBiggerThanXL,
												},
											)}
										>
											<strong>Stripe Verified Payment:</strong>
											<span className='d-flex align-items-center'>
												<Image src={card} className='me-2' />
												Credit Card
											</span>
										</div>
										<div
											className={clsx(
												'd-flex justify-content-between mx-auto',
												{
													'w-75': !isBiggerThanXL,
													'w-50': isBiggerThanXL,
												},
											)}
										>
											<strong>Total Paid:</strong>
											<span>{numberFormatCurrency(currency, amount)}</span>
										</div>
										<div
											className={clsx(
												'd-flex justify-content-between mx-auto',
												{
													'w-75': !isBiggerThanXL,
													'w-50': isBiggerThanXL,
												},
											)}
										>
											<strong>Date & Time:</strong>
											<span>10/29/20 - 11:32 AM</span>
										</div>
									</div>
									<div className='mt-5'>
										<div className='text-sub m-0'>
											Powered by{' '}
											<h6 className='text-dark fw-bold d-inline-block m-0'>
												stripe
											</h6>
										</div>
										<div className='text-sub mt-1'>
											This charge will appear as “Stripe Transfer” on your
											statement
										</div>
									</div>
								</>
							)}
						</Col>
						<Col>
							{isBiggerThanMD && (
								<div className='text-white bg-success w-75 rounded-pill mx-auto d-flex justify-content-center align-items-center py-2'>
									<IoIosCheckmarkCircle size={20} />
									<small className='fw-bold ms-2'>Payment Successful</small>
								</div>
							)}
							<div
								className={clsx('shadow px-4 pt-3 my-3', {
									'pt-5 pb-3': !isBiggerThanSM,
								})}
							>
								<div className='text-center mt-2 mb-4'>
									<strong>How would you like to </strong>
									<strong className='d-block'>
										receive your digital receipt?
									</strong>
								</div>
								<div>
									<fieldset>
										<legend className='text-legend mt-2'>Email me</legend>
									</fieldset>
									<FormInput
										basic
										placeholder='Enter email address'
										{...register('email')}
										error={!!errors.email}
										errorMessage={errors.email?.message}
									/>
								</div>
								<div>
									<fieldset>
										<legend className='text-legend mt-3'>Text me</legend>
									</fieldset>
									<FormInput
										basic
										placeholder='Enter phone number'
										{...register('phone')}
										error={!!errors.phone}
										errorMessage={errors.phone?.message}
									/>
								</div>
								<Button
									type='submit'
									className='w-100 mt-3 mb-1'
								>
									<small className='fw-bold'>SEND RECEIPT</small>
								</Button>
								<Button
									variant='default'
									className='w-100 text-primary text-center'
									onClick={onClose}
								>
									<small className='fw-bold me-2'>NO THANKS</small>
									<FaChevronRight />
								</Button>
							</div>
						</Col>
					</Row>
				</Form>

				{!isBiggerThanLG && (
					<div className='text-center pt-5'>
						<Image src={Logo} alt='mtrx logo' />
						<p className='text-center fs-sub mt-3'>
							Powered by Mobile Tech RX <br />
							Mobitech International Inc. | © 2020
						</p>
					</div>
				)}
			</Modal.Body>
		</Modal>
	)
}
export default Receipt
