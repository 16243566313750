import { Image, Button } from 'react-bootstrap'
import { IoDocumentOutline } from 'react-icons/io5'
import { useMutation } from 'react-query'

import ServicesDesktop from './Services.Desktop'
import ImgVehicleMobile from '../../assets/images/car/vehicle-desktop.svg'
import PhotosDesktop from './Photo.desktop'
import { numberFormatCurrency } from '../../utils/helpers'
import { getCarInfo } from '../../utils/invoice'
import { useApi, useLoading } from '../../hooks' 
import toast from '../toast'
import { VEHICLE_STATUS } from '../../core/constants1'
import DontWorkVehicleIcon from '../../assets/images/icons/dont-work-vehicle.svg'
import ApproveForWork from '../../assets/images/icons/approve-for-work.svg'

const ViewDesktop = ({
	vehicle,
	index,
	onShowPhoto,
	record = {},
	isShowSupplement,
	isShowParts,
	showEstAdasChecklists,
	showInvAdasChecklists,
	handleApproveOrRejectVehicle,
	isEstimateDetail = false,
	isShowButtonApproveOrReject = false,
}) => {
	const { currency = 'usd' } = record
	const { image, year = '', vin = '', stock = '', total = 0, serviceLines = {}, status } = vehicle
	const isRejectVehicle = status === VEHICLE_STATUS.DECLINED && isEstimateDetail;
	const isApprovedVehicle = status === VEHICLE_STATUS.APPROVED && isEstimateDetail;
	const { pdfService } = useApi()
	const { isLoading, mutate: downloadPdf } = useMutation(({ recordId, type }) => {
    const services = (type === 'parts') ? pdfService.estimatePartsPDF : (type === 'supplement') ? pdfService.estimateSupplementPDF : ((type === 'adas_estimate')) ? pdfService.estimateADASPDF : pdfService.invoiceADASPDF
    return services({ recordId }, false)
  }, {
		onError: error => toast.error({ title: 'View PDF errors', body: error.message })
	})

	function pad(d) {
    	return (d < 10) ? '0' + d.toString() : d.toString();
	}

	useLoading(isLoading);

	return (
		<div className='m-4 shadow'>
			{ isRejectVehicle && <div className='reject-vehicle rounded-top' /> }
			{ isApprovedVehicle && <div className='approved-vehicle rounded-top' /> }
			<div className={`p-3 ${isRejectVehicle && 'position-relative'}`}>
				{ isRejectVehicle && <div className='blur' /> }
				<div className='d-flex'>
					<span className='d-inlone-block fw-bold'>{pad(Number(index) + 1)}</span>
					<div className='' style={{ width: '200px', height: '104px' }}>
						<Image
							src={image ? image.replace('color', 'colorCropped') : ImgVehicleMobile}
							className='w-100 h-100'
							style={{ objectFit: 'contain' }}
						/>
					</div>
					<div className='px-3 flex-grow-1'>
						<span className='d-block'>{year}</span>
						<h5 className='fw-bold'>{getCarInfo(vehicle)}</h5>
						<div className='d-flex fs-sub flex-wrap'>
							<span className='d-inline-block border-end pe-2 me-2'>
								STOCK/RO: {stock || 'None Added'}
							</span>
							<span>VIN: {vin || 'None Added'}</span>
						</div>
					</div>
					<div className='text-end'>
						{ isRejectVehicle && (
							<div className='btn-reject'>Rejected</div>
						)}
						{ isApprovedVehicle && (
							<div className='btn-approved'>Approved</div>
						)}
						<h5 className='fw-bold'>{numberFormatCurrency(currency, total)}</h5>
						{isShowSupplement && (
							<Button
								variant='default'
								onClick={() => downloadPdf({ recordId: record._id, type: 'supplement' })}
								className='text-primary p-0 fw-bold fs-sub text-uppercase'
							>
								View insurance supplement
								<IoDocumentOutline size={16} className='ms-2' />
							</Button>
						)}
						<br />

						{isShowParts && (
							<Button
								variant='default'
								onClick={() => downloadPdf({ recordId: record._id, type: 'parts' })}
								className='text-primary p-0 fw-bold fs-sub text-uppercase'
							>
								Parts List
								<IoDocumentOutline size={16} className='ms-2' />
							</Button>
						)}

						{showEstAdasChecklists && (
							<Button
								variant='default'
								onClick={() => downloadPdf({ recordId: record._id, type: 'adas_estimate' })}
								className='text-primary p-0 fw-bold fs-sub text-uppercase'
							>
								ADAS Checklists
								<IoDocumentOutline size={16} className='ms-2' />
							</Button>
						)}

						{showInvAdasChecklists && (
							<Button
								variant='default'
								onClick={() => downloadPdf({ recordId: record._id, type: 'adas_invoice' })}
								className='text-primary p-0 fw-bold fs-sub text-uppercase'
							>
								ADAS Checklists
								<IoDocumentOutline size={16} className='ms-2' />
							</Button>
						)}

					</div>
				</div>

				<ServicesDesktop serviceLines={serviceLines} />

				{
					isShowButtonApproveOrReject ? (
						<div>
							<div className={`${status === VEHICLE_STATUS.DECLINED ? 'border-top' : 'border-top border-bottom'}`}>
								{
									status === VEHICLE_STATUS.UNDECIDED ? (
										<div className='d-flex justify-content-around approve-or-reject-desktop'>
											<Button 
												variant='white' 
												className='d-flex align-items-center' 
												onClick={() => handleApproveOrRejectVehicle({ ...vehicle, status: VEHICLE_STATUS.APPROVED })}
											>
												<span className='mr-1 text-primary'>APPROVE FOR WORK</span>
												<Image src={ApproveForWork} />
											</Button>
											<Button 
												className='d-flex align-items-center' 
												onClick={() => handleApproveOrRejectVehicle({ ...vehicle, status: VEHICLE_STATUS.DECLINED })}
												variant='white'
											>
												<span className='mr-1 text-reject-vehicle'>DON'T WORK ON THIS VEHICLE</span>
												<Image src={DontWorkVehicleIcon}/>
											</Button>
										</div>
									) : null
								}
								{
									status === VEHICLE_STATUS.APPROVED ? (
										<div className='d-flex justify-content-end'>
											<Button 
												className='d-flex align-items-center' 
												onClick={() => handleApproveOrRejectVehicle({ ...vehicle, status: VEHICLE_STATUS.DECLINED })}
												variant='white'
											>
												<span className='mr-1 text-reject-vehicle'>DON'T WORK ON THIS VEHICLE</span>
												<Image src={DontWorkVehicleIcon}/>
											</Button>
										</div>
									) : null
								}
							</div>
						</div>
					) : <div className='border-bottom' />
				}

				<PhotosDesktop onShowPhoto={onShowPhoto} vehicle={vehicle} />

			</div>
			{
				isShowButtonApproveOrReject ? (
					<div className='d-flex justify-content-center pb-2'>
						{
							status === VEHICLE_STATUS.DECLINED ? (
								<Button 
									variant='white' 
									className='d-flex align-items-center' 
									onClick={() => handleApproveOrRejectVehicle({ ...vehicle, status: VEHICLE_STATUS.APPROVED })}
								>
									<span className='mr-1 text-primary'>APPROVE FOR WORK</span>
									<Image src={ApproveForWork} />
								</Button>
							) : null
						}
					</div>
				) : null
			}
		</div>
	)
}

export default ViewDesktop
