import { forwardRef, useEffect, useRef, useState } from 'react'
import { Button, Dropdown } from 'react-bootstrap'

import { FaChevronDown } from 'react-icons/fa'
import { useOnClickOutSide } from '../../hooks'
import useAppContext from '../app'

const Toggle = forwardRef(({ children, onClick }, ref) => (
	<div
		className='text-primary p-0'
		ref={ref}
		role='button'
		onClick={e => {
			e.preventDefault()
			onClick(e)
		}}
	>
		{children}
	</div>
))

const FilterDropdown = ({ currentFilter, originFilter, onFilter, children }) => {
	const { setIsBackdrop } = useAppContext()
	const [show, setShow] = useState(false)
	const dropDownRef = useRef(null)

	const [filterState, setFilter] = useState(currentFilter)
	useEffect(() => {
		setIsBackdrop(show)
		return () => {
			setIsBackdrop(false)
		}
	}, [setIsBackdrop, show])

	useOnClickOutSide(dropDownRef, () => {
		setTimeout(() => {
			setShow(false)
		}, 200)
	})

	const resetFilter= () => {
		setFilter(originFilter)
		onFilter(originFilter)
		setShow(false)
	}

	const handleFilter = () => {
		onFilter(filterState)
		setShow(false)
	}

	return (
		<div className='d-inline-block' ref={dropDownRef}>
			<Dropdown show={show}>
				<Dropdown.Toggle as={Toggle}>
					<Button
						variant='default'
						className='text-primary d-flex align-items-center'
						onClick={() => {
							setShow(!show)
						}}
					>
						<span className='fs-base fw-bold'>FILTER</span>
						<FaChevronDown className='ms-2' />
					</Button>
				</Dropdown.Toggle>
				<Dropdown.Menu
					alignRight
					className='p-3 border-0 shadow'
					style={{ minWidth: '200px' }}
				>
					<div className='d-flex justify-content-between'>
						<strong className='fs-base'>Filter By:</strong>
						<Button
							variant='default'
							className='fs-base text-uppercase text-primary p-0'
							onClick={resetFilter}
						>
							Clear
						</Button>
					</div>
					<div className='pt-2 pb-1'>
						<div className='pb-3 mb-3'>
							{children(filterState, setFilter, show)}
						</div>

						<div className='text-end'>
							<Button
								variant='default'
								className='fs-base text-primary p-0 fw-bold'
								onClick={handleFilter}
							>
								DONE
							</Button>
						</div>
					</div>
				</Dropdown.Menu>
			</Dropdown>
		</div>
	)
}

export default FilterDropdown
