import * as Yup from 'yup'

export default Yup.object().shape({
	updatePassword: Yup.boolean(),
	firstName: Yup.string().required('First Name must Required'),
	lastName: Yup.string().required('Last Name must Required'),
	email: Yup.string().email('Invalid email').required('Required'),
	password: Yup.string()
	.when('updatePassword', {
		is: true,
		then: Yup.string()
			.min(8, 'Your password needs to have at least 8 characters.')
			.required('Email is required'),
	})
})
