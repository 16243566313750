import React from 'react'
import { Container } from 'react-bootstrap'
import AccountSettings from '../components/presentations/accountSetting'
import Layout from '../layout'

const AccountSeetingContainer = () => {
	return (
		<Layout>
			<Container>
				<AccountSettings />
			</Container>
		</Layout>
	)
}

export default AccountSeetingContainer
