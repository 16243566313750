import moment from 'moment'
import { DATE_TIME, CURRENCYS } from '../core/constants/app'

export function getFormattedDateTime(date = new Date()) {
	return `${
		date.getMonth() + 1
	}/${date.getDate()} ${date.getHours()}:${padLeadingZero(
		date.getMinutes(),
	)}:${padLeadingZero(date.getSeconds())}`
}

export function padLeadingZero(value) {
	return value > 9 ? value : `0${value}`
}

export function formatToUTCOffset(date) {
	if (typeof date == 'object') {
		return moment(date).format()
	}
	return undefined
}

export function formatToLocalTime(date, local = localStorage.getItem('currency')) {
	if (local === CURRENCYS.USD) {
		return moment(date).format(DATE_TIME.US)
	} else {
		return moment(date).format(DATE_TIME.UK)
	}
}

export function convertTimeZoneOffset(date, offset) {
	try {
		if (!date) return date
		return `${date.substring(0, date.length - 6)}${offset}`
	} catch (error) {
		return date
	}
}

export const startADay = (today = new Date()) => {
	return moment(today).set({
		hour: 0, minute: 0, second: 0, millisecond: 0,
	})
}

export const endADay = (today = new Date()) => {
	return moment(today).set({
		hour: 23, minute: 59, second: 59, millisecond: 999,
	})
}
