import React from 'react'
import InvoiceDetail from '../components/presentations/InvoiceDetail'
// import { useBreakpoint } from '../hooks'
import Layout from '../layout'

const InvoiceDetailContainer = () => {
	// const { BREAKPOINT, screenBiggerThan} = useBreakpoint()
	return (
		<Layout>
			<InvoiceDetail />
		</Layout>
	)
}

export default InvoiceDetailContainer
