import React from 'react'
import EstimatesDetail from '../components/presentations/estimateDetail'

import Layout from '../layout'

const EstimatesDetailContainer = () => {
	return (
		<Layout>
			<EstimatesDetail />
		</Layout>
	)
}

export default EstimatesDetailContainer
