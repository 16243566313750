import React from 'react'
import qs from 'qs'
import { Button, Col, Container, Form, Image } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import { useMutation } from 'react-query'
import { useApi, useLoading, useYupForm } from '../../../hooks'
import { FormPassword } from '../../formControl'
import schema from './schema'
import clsx from 'clsx'
import LayoutAuth from '../../LayoutAuth'

const ResetPassword = () => {
	const { mainService } = useApi()
	const location = useLocation()
	const query = qs.parse(location.search.slice(1))

	const { isLoading, isSuccess, isError, error, mutate: newPassword } = useMutation(mainService.newPassword)

	useLoading(isLoading)

	const useForm = useYupForm(schema, {
		password: '',
		confirmPassword: '',
	})
	const {
		register,
		handleSubmit,
		formState: { isDirty, isValid, errors },
	} = useForm

	const onSubmit = data => {
		newPassword({ ...data, token: query?.token })
	}

	// useLoading(isLoading)
	return (
		<LayoutAuth>
			<Container
				style={{ maxWidth: '350px' }}
				className='flex-grow-1 d-flex flex-column justify-content-center align-items-center mb-5'
			>
				<Col sm={12} className='text-center w-100'>
					<div className='fs-small text-center'>
						<strong className='d-block'>Reset password</strong>
						<span>Don't worry, happens to the best of us</span>
					</div>
					{isSuccess && (
						<div className='text-center mt-4 fs-sub'>
							<span className='d-block'>Your password has been reset.</span>
							<span>Please{' '}
								<Link to='/login' className='mt-3'>
									<small className='text-uppercase fw-bold fs-small fw-bold'>Log In</small>
								</Link>{' '}
								with your new password.
							</span>
						</div>
					)}
					{isError && (
						<div className='fs-small text-center text-danger mt-3'>
							<span className='text-capitalize'>{error.message}</span>
						</div>
					)}
					<Form
						className={clsx('form form-default py-4 px-3', {
							'd-none': isSuccess,
						})}
						onSubmit={handleSubmit(onSubmit)}
					>
						<FormPassword
							basic
							label='Create a new password'
							placeholder='Enter new password'
							error={!!errors.password}
							errorMessage={errors.password?.message}
							wrapperClass='mb-4 mt-3'
							togglePassword
							{...register('password')}
						/>
						<FormPassword
							basic
							label='Confirm password'
							placeholder='Enter confirm password'
							error={!!errors.confirmPassword}
							errorMessage={errors.confirmPassword?.message}
							wrapperClass='mb-4 mt-3'
							togglePassword
							{...register('confirmPassword')}
						/>
						<Button
							className='w-100'
							type='submit'
							disabled={!isDirty || !isValid}
						>
							<small className='fw-bold text-uppercase'>ok</small>
						</Button>
					</Form>
				</Col>
			</Container>
		</LayoutAuth>
	)
}
export default ResetPassword
