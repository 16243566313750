import { Button, Image, Modal } from 'react-bootstrap'

import CarouselPhotoList from './CarouselPhotoList'
import Logo from '../../assets/images/logo.svg'
import NavigationMobile from '../../layout/Navigation.mobile'

import { useBreakpoint } from '../../hooks'
import './style.scss'
import { useMemo, useState } from 'react'
import { downloadPhoto, downloadZipPhotos, getPhotosVehicle } from '../../utils/invoice'
import useAppContext from '../app'
import Download from '../../assets/images/icons/download-all.svg'

const ModalPhotoList = ({ initialSlide = 0, vehicle, onClose, isInvoice = false }) => {
  const { BREAKPOINT, screenBiggerThan } = useBreakpoint()
  const isBiggerThanMD = screenBiggerThan(BREAKPOINT.MD)

  const [slideCurrent, setSlideCurrent] = useState(initialSlide)

  const { setIsLoading } = useAppContext()

	const photos = useMemo(() => {
		return getPhotosVehicle(vehicle)
	}, [vehicle])

  const downloadPreview = (e) => {
    e.preventDefault();
		setIsLoading(true)
    const photoObj = photos[slideCurrent]
    downloadPhoto(photoObj, () => {
			setIsLoading(false)
		})
  }

  return (
    <Modal
      show
      className='override_header'
      dialogClassName='modal-fullscreen-lg-down bg-body modal-lg d-xl-flex '
      onHide={onClose}
    >
      {!isBiggerThanMD && (
        <Modal.Header className='p-0 text-start'>
          <NavigationMobile />
        </Modal.Header>
      )}
      <Modal.Body className='bg-body p-0  position-sticky top-0 rounded'>
        <div className='bg-white p-4 m-4 rounded shadow-sm'>
          <CarouselPhotoList
            vehicle={vehicle}
            initialSlide={initialSlide}
            photos={photos}
            onChange={currentIndex => setSlideCurrent(currentIndex)}
          />
          <div className='text-center'>
            <Button
              variant='default'
              className='text-primary text-uppercase'
              onClick={() => {
                setIsLoading(true)
                downloadZipPhotos(vehicle, () => {
                  setIsLoading(false)
                })
              }}
            >
              Download all Media
							<Image src={Download} className='ms-2'/>
            </Button>
          </div>
          <div className='text-center'>
            <Button
              className='px-5'
              style={{ 'maxWidth': '327px' }}
              onClick={downloadPreview}
            >
              <span className='text-uppercase'>{photos[slideCurrent].videoURL ? 'Download file' : 'Download this Photo'}</span>
            </Button>
          </div>
        </div>

        <div className='text-center mt-4'>
          <Button variant='default' className='text-primary fs-sub text-uppercase' onClick={() => onClose()}>Back to {isInvoice ? 'invoice' : 'estimate'}</Button>
        </div>

        <div className='text-center pt-4'>
          <Image src={Logo} alt='mtrx logo' />
          <p className='text-center fs-sub mt-3'>Powered by Mobile Tech RX <br />
            Mobitech International Inc. | © 2020
          </p>
        </div>
      </Modal.Body>
    </Modal>
  )
}
export default ModalPhotoList
