import { Button, Form, Image } from 'react-bootstrap'
import { useMutation, useQueryClient } from 'react-query'

import { FormInput } from '../../formControl'
import floppydisk from '../../../assets/images/icons/Vector.png'
import { useApi, useLoading, useYupForm } from '../../../hooks'
import { schemaInvite } from './schema'
import toast from '../../toast'

const InviteUser = () => {
  const queryClient = useQueryClient()
  const { mainService } = useApi()

  const defaultValue = {
		email: '',
		firstName: '',
		lastName: '',
	}

	const { handleSubmit, register, reset, formState: { errors } } = useYupForm(schemaInvite, defaultValue)

  const { isLoading, mutate: invite } = useMutation(async (data) => {
    await mainService.inviteUsers(data)
    await queryClient.invalidateQueries(['list-user'])
  }, {
    onSuccess: () => {
      toast.success({ title: 'Invite User', body: 'success' })
      reset(defaultValue);
    },
    onError: () => toast.success({ title: 'Invite User', body: 'Fail' })
  })

  useLoading(isLoading)

	return (
		<Form onSubmit={handleSubmit(invite)} className='form form-default py-4 px-3 mt-3'>
			<div className='fs-6 text-start pb-3'>
				<strong>Add New</strong>
			</div>
			<FormInput
				basic
				label='Email'
        error={errors?.email}
        errorMessage={errors?.email?.message}
				placeholder='Type email here'
				wrapperClass='mb-3'
        {...register('email')}
			/>
			<FormInput
				basic
				label='First Name'
				placeholder='Type First Name'
        error={errors?.firstName}
        errorMessage={errors?.firstName?.message}
				wrapperClass='mb-3'
        {...register('firstName')}
			/>

			<FormInput
        basic
        label='Last Name'
        placeholder='Type Last Name'
        error={errors?.lastName}
        errorMessage={errors?.lastName?.message}
        {...register('lastName')}
      />

			<Button
				variant='default'
				className='text-primary mt-4 text-uppercase bg-transparent'
				type='submit'
			>
				<Image src={floppydisk} className='me-2' alt='floppy disk icon' />
				<strong className='saveUser'>Save User</strong>
			</Button>
		</Form>
	)
}

export default InviteUser
