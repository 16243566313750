import React from 'react'
import { Container } from 'react-bootstrap'

import Invoices from '../components/presentations/invoices'
import { useBreakpoint } from '../hooks'
import Layout from '../layout'

const EstimateContainer = () => {
	const { BREAKPOINT, screenBiggerThan} = useBreakpoint()
	const isBiggerThanXL = screenBiggerThan(BREAKPOINT.XL)

	return (
		<Layout>
			<Container fluid={!isBiggerThanXL}>
				<Invoices />
			</Container>
		</Layout>
	)
}

export default EstimateContainer
