import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useApi } from '../../../hooks'

import Invoices from './Invoices'
import { sortToObject } from '../../../utils/sort'
import { ContainerProvider } from '../../containerContext'
import { FILTER_DATE_RANGE } from '../../../core/constants/app'

import { PAYMENT_STATUS } from '../../../core/constants/filter'

const queryOrigin = {
	query: {
		skip: 0,
		limit: 10,
		filter: {
			dateRange: FILTER_DATE_RANGE.ALLTIME,
			vendor: 'all',
			payment: PAYMENT_STATUS.ALL
		}
	},
	sorted: {
		sortBy: 'dateCreated',
		sortDirection: 'desc'
	},
}

const Index = () => {
	const [state, setState] = useState({ ...queryOrigin })
	const { invoiceService } = useApi()

	const { isLoading, data = [] } = useQuery(['invoices', state.query, state.sorted], () => {
		const { filter, ...otherQuery } = state.query
		const params = {
			...otherQuery,
			sort: {...sortToObject(state.sorted)},
			filter: {
				// company: clientPortalUser.clientPortalCompany,
				payment: [filter.payment]
			}
		}

		const { startDate, endDate, vendor } = filter
		if (startDate && endDate) params.filter = { ...params.filter, startDate, endDate}
		if (vendor !== 'all') params.filter.company = vendor

		return invoiceService.getMany(params).then(res => res.invoices)
	}, {
		refetchOnWindowFocus: false
	})

	return (
		<ContainerProvider
			state={state}
			setState={setState}
			data={data || []}
			stateOrigin={queryOrigin}
			isLoading={isLoading}
		>
			<Invoices />
		</ContainerProvider>
	)
}

export default Index
