import clsx from 'clsx'
import { getAvatarName } from '../../utils/helpers'
import './avatarName.scss'

const AvatarName = ({ show = true, wrapperClass, className, value, size = 'medium' }) => {
	if (!show || !value) return ''

	return (
		<div className={clsx('d-inline-block', wrapperClass)}>
			<div
				className={clsx(
					'avatar-name text-center rounded-circle d-flex text-white bg-dark mb-0 align-items-center justify-content-center',
					size,
					className,
				)}
			>
				{getAvatarName(value)}
			</div>
		</div>

	)
}

export default AvatarName
