import React, { useEffect, useState } from 'react'
import { orderBy, uniqBy } from 'lodash';
import { useBreakpoint } from '../../../hooks';
import useContainerContext from '../../containerContext';
import Filters from './filter';
import { accessWorkflow } from './utils';
import WorkflowDesktop from './WorkFlow.desktop';
import WorkflowMobile from './WorkFlow.mobile';

const WorkFlow = () => {
    const [listStages, setListStages] = useState([])
    const { BREAKPOINT, screenBiggerThan, screen: { isMobile } } = useBreakpoint()
	const isScreenBiggerThanMD = screenBiggerThan(BREAKPOINT.MD)
    const { workflow, vehicles, isLoading, currentVendor, firstLoad } = useContainerContext()

    useEffect(() => {
            let finalVehicles = uniqBy(vehicles.filter(vehicle => vehicle.activeStage), vehicle => [vehicle._id+ vehicle.activeStage].join());
            const stagesData = orderBy(workflow?.stages, ['placement'], 'asc').map((stage) => {
                stage.vehicles = [];
                finalVehicles.forEach((vehicle) => {
                    if (vehicle.activeStage === stage._id) {
                        stage.vehicles.push(vehicle);
                    }
                })
                return stage;
            });
            setListStages(stagesData)
    }, [vehicles, workflow]);

    return (
        <div>
            {isLoading && (
				<div className='loading'>
					<div className='loader' />
				</div>
			)}
            <div className='mt-5'>
                <h5 className='text-center text-md-start fw-bold'>VEHICLES</h5>

                <Filters isScreenBiggerThanMD={isScreenBiggerThanMD} />

                {
                    accessWorkflow(currentVendor) && workflow ? (
                        <>
                            {
                                isMobile ? (
                                    <WorkflowMobile listStages={listStages} />
                                ) : (
                                    <WorkflowDesktop listStages={listStages} />
                                )
                            }
                        </>
                    ) : (
                        <>
                            {
                                !firstLoad && <div className='workflow-null-data'>No data available for this vendor.</div>
                            }
                        </>
                    )
                }
            </div>
        </div>
    )
}

export default WorkFlow
