import { useRef } from 'react'
import Slider from 'react-slick'
import { Image } from 'react-bootstrap'
import IconArrowLeft from '../../assets/images/icons/arrowLeftSlider.svg'
import IconArrowRight from '../../assets/images/icons/arrowRightSlider.svg'
import { SERVER_DATA } from '../../core/constants/app'

const CarouselPhotoList = ({ initialSlide = 0, vehicle = {}, photos = [], onChange }) => {
  const SamplePrevArrow = (props) => {
    const { style, onClick } = props;
    return (
      <Image
        className='position-absolute start-0 ms-4'
        style={{ ...style, zIndex: '1', cursor: 'pointer', top: '40%' }}
        onClick={onClick}
        src={IconArrowLeft}
      />
    );
  }

  const SampleNextArrow = (props) => {
    const { style, onClick } = props;
    return (
      <Image
        className='position-absolute end-0 me-4'
        style={{ ...style, zIndex: '1', cursor: 'pointer', top: '40%' }}
        onClick={onClick}
        src={IconArrowRight}
      />
    );
  }

	const vidRefs= useRef([]);

  const settings = {
    initialSlide: initialSlide,
    className: 'p-relative',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    beforeChange: (old, next) => {
			if (vidRefs.current[old]) {
				vidRefs.current[old].pause();
			}
      onChange(next)
    }
  };

  return (
    <>
      <Slider {...settings}>
        {photos.map((photo, index) => {
          return (
            <div key={index.toString()}>
							<div className='media-container'>
								<div className='media-play'>
								{photo.videoURL ? (
									<video ref={el => (vidRefs.current[index] = el)} controls>
										<source src={`${SERVER_DATA}/${photo.videoURL}`} type="video/mp4" />
										Your browser does not support the video tag.
									</video>
								) : (
									<Image
										src={`${SERVER_DATA}/${photo.url}`}
										className='w-100' alt={photo.name}
									/>
								)}
								</div>
							</div>
              <div className='text-center mt-4 w-100'>
                <h4 className='fw-bold'>{photo.name}</h4>
                <p><span className='fw-bold'>{vehicle.year} {vehicle.make} |</span> Model: {vehicle.model}. </p>
                <p className='text-sub mb-0'>{index + 1} of {photos.length} media</p>
              </div>
            </div>
          )
        })}
      </Slider>
    </>
  )
}
export default CarouselPhotoList
