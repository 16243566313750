import { Col, Container, Row } from 'react-bootstrap'
import React, { useState } from 'react'

import { useApi } from '../../../hooks'
import { useQuery } from 'react-query'
import { ContainerProvider } from '../../containerContext'
import InviteUser from './InviteUser'
import TeamMembers from './TeamMembers'
import UpdateUser from './UpdateUser'

function ManageUsers() {
	const [managerState, setManagerState] = useState({
		memberSelected: null
	})

	const updateManagerState = (obj) => {
		setManagerState(prev => {
			return { ...prev, ...obj }
		})
	}

	const { mainService } = useApi()
	const { isLoading, data = { clientPortalUsers: [] } } = useQuery(['list-user'], () => {
		return mainService.getUsers()
	})

	return (
		<ContainerProvider
			managerState={managerState}
			updateManagerState={updateManagerState}
			isLoading={isLoading}
			teamMembers={data.clientPortalUsers}
			memberSelected={managerState.memberSelected}
		>
			<Container style={{ maxWidth: '400px' }} className='mt-5 h-100'>
				<Row className='py-5 text-center h-100 login'>
					<Col sm={12} className='my-0 mx-auto'>
						{!managerState.memberSelected && (
							<>
								<div className='fs-4'>
									<strong className='d-block'>Manage Users</strong>
								</div>
								<InviteUser />
								<TeamMembers />
							</>
						)}
						{managerState.memberSelected && <UpdateUser />}

					</Col>
				</Row>
			</Container>
		</ContainerProvider>
		
	)
}
export default ManageUsers
