import React from 'react'
import { Image } from 'react-bootstrap'
import Copyright from '../Copyright'
import logo from '../../assets/images/logo.svg'
import './layoutAuth.scss'

const LayoutAuth = ({ children }) => {
	return (
		<div className='d-flex flex-column vh-100'>
			<header className='bg-white py-4 shadow-sm text-center'>
				<Image src={logo} />
			</header>
			{children}
			<div className='text-light text-center py-4'>
				<Copyright />
			</div>
		</div>
	)
}

export default LayoutAuth
