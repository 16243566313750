import * as routesUtils from '../utils/RoutesUtils'
import { API } from '../core/app.routes'
import httpClient from '../core/httpClient'

function downloadLink(fileName, link, targetBlank = true) {
	const a = document.createElement('a')
	// a.style = 'display: none'
	a.style.cssText = 'display: none'
	a.href = link
	document.body.appendChild(a)
	a.setAttribute('download', fileName)
	if (targetBlank) a.setAttribute('target', '_blank')
	a.click()
	document.body.removeChild(a)
}

function printLink(link) {
	return fetch(link)
		.then((res) => { return res.blob(); })
		.then((blob) => {
			const iframe = document.querySelector('#print');
			iframe.src = window.URL.createObjectURL(blob);
			iframe.onload = () => {
				iframe.contentWindow.focus();
				iframe.contentWindow.print();
				document.querySelector('.backdrop').classList.remove('show');
				document.body.classList.remove('unscrollable');
				return 'success';
			};
		});
}

const commonService = () => {
	const getJobId = id => {
		const path = routesUtils.getApiPath(API.GET_JOB)
		return httpClient.get(path + id)
	}

	const jobPolling = (jobID, allowedAttempts = 10, milliseconds = 3000) => {
		let attempts = 0
		return new Promise((resolve, reject) => {
			const polling = setInterval(async () => {
				attempts++

				const result = await getJobId(jobID)
				const {
					job: { status },
				} = result
				if (attempts > allowedAttempts) {
					clearInterval(polling)
					reject(new Error('Error: Timed Out'))
				}
				if (!status) {
					clearInterval(polling)
					resolve(result)
				}
				return result
			}, milliseconds)
		})
	}

	return {
		downloadLink,
		printLink,
		getJobId,
		jobPolling,
	}
}

export default commonService
