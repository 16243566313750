import React from 'react'
import { toast as toastify } from 'react-toastify'
import { IoIosCheckmarkCircle, IoMdInformationCircle } from 'react-icons/io'
import { RiCloseCircleFill } from 'react-icons/ri'
import { IoWarningOutline } from 'react-icons/io5'


export const ToastWrapper = (props) => {
	const { type = 'success', title, body } = props
	return (
		<div className='d-flex align-items-start'>
			{type === 'success' && (
				<IoIosCheckmarkCircle size={32} className='me-2 pb-2' />
			)}
			{type === 'error' && (
				<RiCloseCircleFill size={32} className='me-2 pb-2' />
			)}
			{type === 'info' && (
				<IoMdInformationCircle size={32} className='me-2 pb-2' />
			)}
			{type === 'warning' && (
				<IoWarningOutline size={32} className='me-2 pb-2' />
			)}
			<div className='flex-grow-1'>
				{title && <h5 className='toast-custom--title  fw-bold'>{title}</h5>}
				{body && <p className={`toast-custom--body ${title ? 'mtb-7px' : ''}`}>{body}</p>}
			</div>
		</div>
	)
}

const parseNotify = (notify = {}) => {
	let title
	let body

	if (typeof notify === 'string') {
		body = notify
	} else {
		title = notify.title
		body = notify.body
	}

	return {
		title,
		body,
	}
}

const success = (notify) => {
	const { title = '', body = '' } = parseNotify(notify)
	return toastify.success(<ToastWrapper type='success' title={title} body={body} />)
}

const error = (notify) => {
	const { title = '', body = '' } = parseNotify(notify)
	return toastify.error(<ToastWrapper type='error' title={title} body={body} />)
}

const info = (notify) => {
	const { title = '', body = '' } = parseNotify(notify)
	return toastify.info(<ToastWrapper type='info' title={title} body={body} />)
}

const warning = (notify) => {
	const { title = '', body = '' } = parseNotify(notify)
	return toastify.warning(<ToastWrapper type='warning' title={title} body={body} />)
}

const toast = {
	success,
	error,
	info,
	warning,
}

export default toast
