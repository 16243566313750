import { useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import { BsThreeDotsVertical, BsTrash } from 'react-icons/bs'
import { useMutation, useQueryClient } from 'react-query'

import { useApi } from '../../../hooks'
import useOnClickOutside from '../../../hooks/useOnClickOutSide'
import useAppContext from '../../app'

import useContainerContext from '../../containerContext'
import toast from '../../toast'

const Actions = ({ member }) => {
  // const { updateManagerState } = useContainerContext()
  const { setIsLoading } = useAppContext()
  const [show, setShow] = useState(false)
  const actionRef = useRef()
  const { mainService } = useApi()
  const queryClient = useQueryClient()

  const { mutate: deleteMember } = useMutation(async () => {
    setIsLoading(true)
    await mainService.deleteMember({ userID: member._id })
    await queryClient.invalidateQueries(['list-user'])
  }, {
    onSuccess: () => toast.success({ title: 'Remove member', body: 'Success' }),
    onError: () => toast.error({ title: 'Remove member', body: 'Fail' }),
    onSettled: () => setIsLoading(false)
  })

  useOnClickOutside(actionRef, () => setShow(false))
  // const selectUser = () => updateManagerState({ memberSelected: member})

	return (
		<div
			className='di-inline-block py-0 px-3 position-relative pointer'
			onClick={() => setShow(!show)}
		>
      <BsThreeDotsVertical size={20} className='text-secondary' />
        {show && (
          <div className='pop-up' ref={actionRef}>
            <div
              style={{ width: '144px', top: '-20px' }}
              className='bg-white rounded shadow position-absolute end-0'
            >
              <div className='text-start p-3'>
                {/* <Button variant='default' className='p-0 mb-2 fw-600' onClick={selectUser}>
                  <AiOutlineEdit size={16} className='me-2' />
                  Edit user
                </Button> */}
                <Button variant='default' className='p-0 text-danger fw-600' onClick={deleteMember}>
                  <BsTrash className='me-2' />
                  Delete user
                </Button>
              </div>
            </div>
          </div>
        )}
		</div>
	)
}

const TeamMembers = () => {
  const { teamMembers = []} = useContainerContext()
	return (
    <div className='px-3'>
      <div className='fs-6 text-start pb-4'>
        <strong>Team Members</strong>
      </div>
      {teamMembers.map((member) => {
        const { _id, firstName, lastName, email } = member
        return (
          <div className='d-flex justify-content-between align-items-center border-bottom py-3' key={_id}>
            <div className='team-info text-start ms-3'>
              <h3 className='fs-small fw-600'>{`${firstName} ${lastName}`}</h3>
              <p className='fs-small mb-0'>{email}</p>
            </div>
            <Actions member={member} />
          </div>
        )
      })}
    </div>
	)
}

export default TeamMembers
