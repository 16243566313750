import * as routesUtils from '../utils/RoutesUtils'
import { API } from '../core/app.routes'
import httpClient, { paymentEstimate } from '../core/httpClient'

const paymentDepositService = () => {
	return {
    create: body => {
      const path = routesUtils.getApiPath(API.PAYMENTS)
			return paymentEstimate.post(path, body)
    },
    sendReceipt: (params, body) => {
      const path = routesUtils.getApiPath(API.PAYMENTS_SEND_RECEIPT, params)
			return httpClient.put(path, body)
    }
	}
}

export default paymentDepositService
