import React, { useEffect, useState } from 'react'

import WorkFlow from './WorkFlow'
import { ContainerProvider } from '../../containerContext'
import { useApi } from '../../../hooks'
import { LIMIT, SKIP, STAGE_GROUPING, WORKFLOW_DOCTYPE } from '../../../core/constants/workflow.constant'
import { accessWorkflow, isEmpty } from './utils'
import "./index.scss"
import { useQuery } from 'react-query'
import { orderBy } from 'lodash'

const queryOrigin = {
    skip: 0,
    limit: LIMIT,
    view: 'workflow-board',
    sort: '-dateCreated',
	filter: {
        currency: ['usd'],
	},
}

const Index = () => {
    const [state, setState] = useState({ ...queryOrigin })
    const { workflowService, mainService } = useApi()
    const [workflow, setWorkflow] = useState({});
    const [vehicles, setVehicles] = useState([]);
    const [stopLoadMore, setStopLoadMore] = useState({});
    const [companies, setCompanies] = useState([]);
    const [filterEst, setFilterEst] = useState({
        ...queryOrigin,
        filter: {
            ...queryOrigin.filter,
            status: true,
        }
    });
    const [filterWO, setFilterWO] = useState({
        ...queryOrigin,
        filter: {
            ...queryOrigin.filter,
            status: true,
        }
    });
    const [filterInvs, setFilterInvs] = useState({
        ...queryOrigin,
        filter: {
            ...queryOrigin.filter,
        }
    });
    const [isSearch, setIsSearch] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [currentVendor, setCurrentVendor] = useState({});

    useQuery(
        ['companies'],
        () => mainService.getCompanies({ projection: { name: 1 } }), {
          onSuccess: ({ companies }) => {
            companies = orderBy(companies, ['name'], 'asc');
            setCompanies(companies)
          },
          refetchOnWindowFocus: false
    });

    const gatherVehicles = () => {
        setIsLoading(true);
        const allVehicles = [];

        const gatherEstimates = workflow.stages
        .filter((stage) => stage.grouping === STAGE_GROUPING.ONE)
        .map(async(stage) => {
            const filter = {
                ...filterEst,
                companyId: workflow.company,
                filter: {
                    ...filterEst.filter,
                    'vehicles.activeStage': stage._id
                },
            };
            const { estimates } = await workflowService.getEstimates(filter);
            if (estimates && estimates.length) {
                estimates.forEach(estimate => {
                    if (estimate.vehicles) {
                        estimate.vehicles.forEach(vehicle => {
                            allVehicles.push({
                                ...vehicle,
                                createdBy: estimate.createdBy,
                                client: estimate.client,
                                docID: estimate._id,
                                docType: WORKFLOW_DOCTYPE.ESTIMATE,
                                number: estimate.number,
                                hasEvent: !isEmpty(estimate?.event),
                            })
                        })
                    }
                });
            }
        });
        const gatherWorkOrders = workflow.stages
        .filter((stage) => stage.grouping === STAGE_GROUPING.TWO)
        .map(async(stage) => {
            const filter = {
                ...filterWO,
                companyId: workflow.company,
                filter: {
                    ...filterWO.filter,
                    'vehicle.activeStage': stage._id
                },
            };

            const { workOrders } = await workflowService.getWorkOrders(filter);
            if (workOrders && workOrders.length) {
                workOrders.forEach((workOrder) => {
                    if (workOrder.vehicle) {
                        allVehicles.push({
                            ...workOrder.vehicle,
                            assignedTo: workOrder.assignedTo,
                            client: workOrder.client,
                            docID: workOrder._id,
                            docType: WORKFLOW_DOCTYPE.WORK_ORDER,
                            number: workOrder.number,
                            hasEvent: !isEmpty(workOrder?.event),
                        });
                    }
                });
            }
        });
        const gatherInvoices = workflow.stages
        .filter((stage) => stage.grouping === STAGE_GROUPING.THREE)
        .map(async(stage) => {
            const filter = {
                ...filterInvs,
                companyId: workflow.company,
                filter: {
                    ...filterInvs.filter,
                    'vehicles.activeStage': stage._id
                },
            };

            const { invoices } = await workflowService.getInvoices(filter);
            if (invoices && invoices.length) {
                invoices?.forEach((invoice) => {
                    if (invoice.vehicles) {
                        invoice.vehicles.forEach((vehicle) => {
                            allVehicles.push({
                                ...vehicle,
                                client: invoice.client,
                                company: invoice.company,
                                docID: invoice._id,
                                docType: WORKFLOW_DOCTYPE.INVOICE,
                                number: invoice.number,
                                invoiceClose: !invoice.status,
                                hasEvent: !isEmpty(invoice?.event),
                            });
                        });
                    }
                });
            }
        });

        Promise.all([...gatherEstimates, ...gatherWorkOrders, ...gatherInvoices])
        .then(() => {
            setVehicles(allVehicles);
        })
        .catch((error) => console.log({error}))
        .finally(() => {
            setIsSearch(false);
            setIsLoading(false);
            setFirstLoad(false);
        })
    }

    const handleSearch = (value) => {
        if (value === '') {
			delete filterEst.text;
			delete filterInvs.text;
			delete filterWO.text;
		} else {
			filterEst.text = value;
			filterInvs.text = value;
			filterWO.text = value;
		}
		setFilterEst({ ...filterEst });
		setFilterInvs({ ...filterInvs });
		setFilterWO({ ...filterWO });

		setIsSearch(true);
    }

    const handleOnScroll = async (stageId) => {
        if (stopLoadMore[stageId]) {
            return;
        }
        setIsLoading(true);

        SKIP[stageId] = (SKIP[stageId] || 0) + LIMIT;

        const allVehicles = [];
        const stage = workflow.stages.find(st => st._id === stageId);

        switch (stage.grouping) {
            case STAGE_GROUPING.ONE: {
                const filter = {
                    ...queryOrigin,
                    skip: SKIP[stageId],
                    companyId: workflow.company,
                    filter: {
                        ...queryOrigin.filter,
                        status: true,
                        'vehicles.activeStage': stageId,
                    },
                };
                const { estimates } = await workflowService.getEstimates(filter);
                if (estimates && estimates.length) {
                    estimates.forEach(estimate => {
                        if (estimate.vehicles) {
                            estimate.vehicles.forEach(vehicle => {
                                allVehicles.push({
                                    ...vehicle,
                                    createdBy: estimate.createdBy,
                                    client: estimate.client,
                                    docID: estimate._id,
                                    docType: WORKFLOW_DOCTYPE.ESTIMATE,
                                    number: estimate.number,
                                    hasEvent: !isEmpty(estimate?.event),
                                })
                            })
                        }
                    });
                }
                if (estimates.length < LIMIT) {
                    const stopScroll = {...stopLoadMore};
                    stopScroll[stageId] = true;
                    setStopLoadMore(stopScroll)
                }

                setVehicles(prev => {
                    return prev.concat(allVehicles)
                })
                setIsLoading(false);
                break;
            }
            case STAGE_GROUPING.TWO: {
                const filter = {
                    ...queryOrigin,
                    skip: SKIP[stageId],
                    companyId: workflow.company,
                    filter: {
                        ...queryOrigin.filter,
                        status: true,
                        'vehicle.activeStage': stageId,
                    },
                };
                const { workOrders } = await workflowService.getWorkOrders(filter);
                if (workOrders && workOrders.length) {
                    workOrders.forEach((workOrder) => {
                        if (workOrder.vehicle) {
                            allVehicles.push({
                                ...workOrder.vehicle,
                                assignedTo: workOrder.assignedTo,
                                client: workOrder.client,
                                docID: workOrder._id,
                                docType: WORKFLOW_DOCTYPE.WORK_ORDER,
                                number: workOrder.number,
                                hasEvent: !isEmpty(workOrder?.event),
                            });
                        }
                    });
                }
                if (workOrders.length < LIMIT) {
                    const stopScroll = {...stopLoadMore};
                    stopScroll[stageId] = true;
                    setStopLoadMore(stopScroll)
                }
                setVehicles(prev => {
                    return prev.concat(allVehicles)
                })
                setIsLoading(false);
                break;
            }
            case STAGE_GROUPING.THREE: {
                const filter = {
                    ...queryOrigin,
                    skip: SKIP[stageId],
                    companyId: workflow.company,
                    filter: {
                        ...queryOrigin.filter,
                        'vehicles.activeStage': stageId
                    },
                };
    
                const { invoices } = await workflowService.getInvoices(filter);
                if (invoices && invoices.length) {
                    invoices?.forEach((invoice) => {
                        if (invoice.vehicles) {
                            invoice.vehicles.forEach((vehicle) => {
                                allVehicles.push({
                                    ...vehicle,
                                    client: invoice.client,
                                    company: invoice.company,
                                    docID: invoice._id,
                                    docType: WORKFLOW_DOCTYPE.INVOICE,
                                    number: invoice.number,
                                    invoiceClose: !invoice.status,
                                    hasEvent: !isEmpty(invoice?.event),
                                });
                            });
                        }
                    });
                }
                if (invoices.length < LIMIT) {
                    const stopScroll = {...stopLoadMore};
                    stopScroll[stageId] = true;
                    setStopLoadMore(stopScroll)
                }
                setVehicles(prev => {
                    return prev.concat(allVehicles)
                })
                setIsLoading(false);
                break;
            }
            default:
                break;
        }
    }
    
    useEffect(() => {
        if (companies.length > 0) {
            const fetchWorkflow = async () => {
                const payload = {
                    companyId: companies[0].company
                }
                const data = await workflowService.getWorkflow(payload)
                setWorkflow(data)
                setFirstLoad(false)
            }
            fetchWorkflow()
        }
    }, [companies])

    useEffect(() => {
        if (workflow && workflow.stages) {
            accessWorkflow(currentVendor) && gatherVehicles();
        }
    }, [workflow])

    useEffect(() => {
        if (workflow.stages && isSearch) {
            gatherVehicles();
        }
    }, [isSearch])

    useEffect(() => {
        if (companies && companies.length > 0) {
            setCurrentVendor(companies[0])
        }
    }, [companies])

    return (
        <ContainerProvider
            state={state}
            workflow={workflow}
            vehicles={vehicles}
            isLoading={isLoading}
            companies={companies}
            currentVendor={currentVendor}
            firstLoad={firstLoad}
            setCurrentVendor={setCurrentVendor}
            setState={setState}
            handleOnScroll={handleOnScroll}
            handleSearch={handleSearch}
            setWorkflow={setWorkflow}
        >
            <WorkFlow />
        </ContainerProvider>
    )
}

export default Index