import { useState } from 'react'
import {
	CardExpiryElement,
	CardNumberElement,
	CardCvcElement,
} from '@stripe/react-stripe-js'

import { Form, Button, Image } from 'react-bootstrap'
import { Controller } from 'react-hook-form'
import clsx from 'clsx'

import { useApi, useYupForm } from '../../hooks'
import { FormInput } from '../formControl'
import { paymentInfoFull } from './schema'
import Tips from './Tip'
import cardDue from '../../assets/images/payment/cardDue.svg'
import useContainerContext from '../containerContext'
import useAppContext from '../app'
import { numberFormatCurrency } from '../../utils/helpers'
import toast from '../toast'

const PaymentForm = ({ stripe, elements, data, isInvoice }) => {
	const { toReceipt } = useContainerContext()
	const { setIsLoading } = useAppContext()
	const [isPaymentFull, setPaymentFull] = useState(true)

	const { paymentService } = useApi()

	const { total = 0, payment: { amount = 0 }, currency = 'usd', depositFlat = 0 } = data

	const useForm = useYupForm(paymentInfoFull, {
		name: '',
		amount: (isInvoice ? data.total - amount : data.depositFlat - amount).toFixed(2),
		tipAmount: 0,
		creditCard: false,
		cardExpiry: false,
		cardCvc: false,
	})

	const {
		register,
		control,
		handleSubmit,
		formState: { errors, isDirty },
		setValue,
		watch,
	} = useForm

	const toogleForm = () => {
		setPaymentFull(!isPaymentFull)
		if (isPaymentFull) {
			setPaymentFull(false)
			setValue('amount', 0, { shouldValidate: true, shouldDirty: true })
		} else {
			setPaymentFull(true)
			setValue('amount', isInvoice ? total - amount : depositFlat - amount, { shouldValidate: true, shouldDirty: true })
		}
	}

	const onSubmit = async ({ name, zipCode, amount, tipAmount }) => {
		setIsLoading(true)
		const cardElement = elements.getElement(CardNumberElement)

		const { error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: name,
        address: {
          postal_code: zipCode,
        },
      },
    })

		if (error) {
			toast.error({
				title: 'payment fail',
				body: error
			})
			setIsLoading(false)
			return
		}

		const payload = {
      invoice: data._id,
      amount,
			method: 'creditcard',
      tipAmount: parseFloat(tipAmount),
    }

		if (!isInvoice) {
			payload.invoice = '';
			payload['estimate'] = data._id;
		}

		await paymentService.create(payload)
			.then(({ payment }) => {
				const { clientSecret, _id: paymentId } = payment

				stripe
          .confirmCardPayment(clientSecret, {
            payment_method: { card: cardElement },
          })
          .then(function (result) {
            if (result.error) {
							console.log('result.error', result.error.message)
              toast.error({
								title: 'payment fail!',
								body: result.error.message
							})
							setIsLoading(false)
            } else {
							setIsLoading(false)
							toReceipt({ paymentId, ...payload })
            }
          })
			})
			.catch(() => {
				setIsLoading(false)
			})
	}

	return (
		<Form className='m-2' onSubmit={handleSubmit(onSubmit)}>
			{!isPaymentFull && (
				<div>
					<strong className='d-block fs-small mb-2'>Amount</strong>
					<FormInput
						basic
						placeholder='Enter $ payment amount'
						wrapperClass='mb-3'
						className='py-2'
						error={!!errors?.amount}
						{...register('amount')}
					/>
				</div>
			)}

			<div>
				<strong className='d-block fs-small mb-2'>Name on card</strong>
				<FormInput
					basic
					placeholder='Type the Name on card here'
					wrapperClass='mb-3'
					className='py-2'
					error={!!errors?.name}
					{...register('name')}
				/>
			</div>
			<div>
				<strong className='d-block fs-small mb-2'>Card details</strong>
				<div className='rounded'>
					<Controller
						control={control}
						name='creditCard'
						defaultValue=''
						render={arg => {
							const {
								field: { onChange },
							} = arg
							return (
								<Form.Group
									className={clsx('form-group ', {
										'border-danger rounded-0 rounded-top border':
											!!errors.creditCard && errors,
									})}
								>
									<CardNumberElement
										className={
											'decor-cards form-control border-bottom bg-form border-0 border-bottom-0 rounded-0'
										}
										onChange={({ complete }) => onChange(complete)}
									/>
								</Form.Group>
							)
						}}
					/>

					<div className='d-flex'>
						<div className='w-100'>
							<Controller
								control={control}
								name='cardExpiry'
								render={({ field: { onChange } }) => {
									return (
										<Form.Group className='mb-3'>
											<CardExpiryElement
												className={clsx(
													'decor-expiry bg-form form-control rounded-0',
													{
														'border-0': !errors.cardExpiry,
														'border-danger': !!errors.cardExpiry,
														'border-top-0': !!errors.creditCard,
													},
												)}
												onChange={({ complete }) => {
													onChange(complete)
												}}
											/>
										</Form.Group>
									)
								}}
							/>
						</div>
						<div className='w-100'>
							<Controller
								control={control}
								name='cardCvc'
								render={({ field: { onChange } }) => {
									return (
										<Form.Group className='mb-3'>
											<CardCvcElement
												className={clsx(
													'decor-cvc decor-cvc bg-form form-control rounded-0',
													{
														'border-0': !errors.cardCvc,
														'border-danger': !!errors.cardCvc,
														'border-start-0': !!errors.cardExpiry,
														'border-top-0': !!errors.creditCard,
													},
												)}
												onChange={({ complete }) => {
													onChange(complete)
												}}
											/>
										</Form.Group>
									)
								}}
							/>
						</div>
					</div>
				</div>
			</div>
			<div>
				<strong className='d-block fs-small mb-2'>Zip Code</strong>
				<FormInput
					type='number'
					basic
					placeholder='Enter zip code'
					wrapperClass='mb-3'
					className='py-2'
					{...register('zipCode')}
					error={!!errors.zipCode}
				/>
			</div>

			{isInvoice && (
				<>
				<Controller
					name='tipAmount'
					control={control}
					render={({ field: { onChange } }) => {
						return <Tips amount={watch('amount')} onChange={onChange} currency={currency} />
					}}
				/>

				{!isPaymentFull && watch('amount') > 0 &&  (
					<div className='text-center my-3'>
						<h2>{numberFormatCurrency(currency, watch('amount'))}</h2>
						<div className='text-light fw-600'>Future Balance due: {numberFormatCurrency(currency, total - watch('amount') < 0 ? 0 : total - watch('amount'))}</div>
					</div>
				)}
				</>
			)}


			<div className='w-100 mt-3'>
				<Button
					className='w-100'
					type='submit'
					disabled={!isDirty}
				>
					PAY
				</Button>
			</div>

			<Button
				variant='default'
				onClick={toogleForm}
				className='mx-auto w-100 m-3 p-0 text-primary text-uppercase fw-bold'
			>
				{isPaymentFull ? 'Pay a different amount' : 'Pay full balance due'}
				<Image src={cardDue} className='ms-2' />
			</Button>
		</Form>
	)
}

export default PaymentForm
