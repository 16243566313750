const routes = {
	LOGIN: '/login',
	SIGNUP: '/signup',
	SIGNUPUSER: '/user-signup',
	FORGOTPASSWORD: '/forgot-password',
	RESETPASSWORD: '/reset-password',
	VERIFY: '/verify',
	INVOICES: '/invoices',
	INVOICEDETAIL: '/invoices/:invoiceId',
	ESTIMATES: '/estimates',
	ESTIMATESDETAIL: '/estimates/:id',
	ACCOUNTSETTING: '/account-setting',
	MANAGEUSERS: '/manage-ursers',
	WORKFLOW: '/workflow',
}

export default routes
