import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { FILTER_PAYMENT_STATUS } from '../../core/constants/filter'
import { FormCheck } from '../formControl'

const FilterPayment = ({ value, onChange, isCloseMenu }) => {
	const [show, setShow] = useState(false)
	const [selected, setSelected] = useState(value || 'all')

	const Icon = show ? FaChevronUp : FaChevronDown

	useEffect(() => {
		if (isCloseMenu) setShow(false)
	}, [isCloseMenu])

	useEffect(() => {
		if (value !== selected) setSelected(value)
	}, [selected, value])

	return (
		<div>
			<div className='p-2 border rounded'>
				<div
					className='text-dark fs-small d-flex justify-content-between'
					onClick={() => setShow(!show)}
				>
					Payment
					<Icon className='ms-2' />
				</div>

				<div
					className={clsx('py-2', {
						'd-none': !show,
					})}
				>
					{FILTER_PAYMENT_STATUS.map(({ label, value }) => {
						return (
							<div key={value} className='fs-small d-flex align-items-center justify-content-between py-2'>
								<div>{label}</div>
								<span className='d-inline-block' style={{ width: '20px' }}>
									<FormCheck
										checked={selected === value}
										readOnly={selected === value}
										onChange={() => { onChange(value) }}
									/>
								</span>
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}

export default FilterPayment
