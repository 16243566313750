import * as Yup from 'yup'

export const Email = Yup.object().shape({
	email: Yup.string()
		.email('Invalid email address. Please enter a new email address')
		.required('Email is required'),
})
export const Password = Yup.object().shape({
	password: Yup.string()
		.min(8, 'Your password needs to have at least 8 characters.')
		.required('Email is required'),
})
