const ServicesMobile = ({ serviceLines }) => {
	return (
		<div className="service-mobile">
			<span className='d-block fs-small fw-600 mb-2'>Services</span>
			{Object.keys(serviceLines).map(key => {
				const service = serviceLines[key];
				const name = service.substring(0, service.indexOf(':'));
				const total = service.substring(service.indexOf('$'), service.indexOf('.') + 2).trim();
				const detail = service.substring(service.indexOf('(') + 1, service.indexOf(')') - 1).trim();

				if (!serviceLines[key].trim()) return '';

				return (
					<div className='border rounded p-2 d-flex fs-sub mt-2' key={key}>
						<div className='d-flex justify-content-between align-items-center w-100'>
							<div className='w-25 d-flex flex-column justify-content-center'>
								<span className='font-weight-bold name'>{name}</span>
								<span className='total'>{total}</span>
							</div>
							<span className='w-75 detail d-flex justify-content-end'>{detail}</span>
						</div>
					</div>
				);
			})}
		</div>
	)
}

export default ServicesMobile
