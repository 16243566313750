import qs from 'qs'

import { API } from '../core/app.routes'
import { api as apiConfig } from '../core/config'
import { SERVER_DATA } from '../core/constants/app'

/**
 * pathsCollection: URL or API. See core/common/app.routes.js for details
 */
function getPathTemplate(route, pathsCollection) {
	if (route.parent) {
		const path = `${getPathTemplate(
			pathsCollection[route.parent],
			pathsCollection,
		)}/${route.path}`
		return path
	}
	return route.path
}

/**
 * pathsCollection: URL or API. See core/common/app.routes.js for details
 */
function getPath(route, params, pathsCollection) {
	let path = getPathTemplate(route, pathsCollection)
	const rParams = path.match(/:\w+/g)
	if (!params) return path

	const query = { ...params }
	if (rParams) {
		Object.keys(rParams).forEach(p => {
			const key = rParams[p].substring(1)
			delete query[key]
			path = path.replace(`:${key}`, params[key])
		})
	}

	return (
		path +
		(query && Object.keys(query).length > 0 ? `?${qs.stringify(query)}` : '')
	)
}

export function getFullApiPath(route, params) {
	return apiConfig.mtbAPI + getPath(route, params, API)
}

export function getApiPath(route, params) {
	return getPath(route, params, API)
}

export function formatUrlPrefixS3 (url) {
	const newUrl = url.charAt(0) === '/' ? url.substring(1, url.length) : url;
	return `${SERVER_DATA}${newUrl}`;
}

export function handleResponseError(responseError) {
	const pages = [
		'client-portal-users/verify',
		'signup/user'
	]

	const { response: { config: { url }, data: { message }, status } } = responseError
	if (status === 401 || status === 403 ) {
		localStorage.removeItem('access_token')
		localStorage.removeItem('clientPortalCompany')
		localStorage.removeItem('clientPortalUser')
		if (!pages.includes(url)) return window.location.href = '/login'
	}

	if (status === 404) {
		const newError = new Error()
		newError.message = 'APi Not found'
		throw newError
	}

	if (typeof message === 'string') {
		const newError = new Error()
		newError.message = message
		throw newError
	}
	else throw responseError
}