import React from 'react'
import useContainerContext from '../../containerContext';
import Element from './Element';

const WorkflowDesktop = ({ listStages }) => {
  const { handleOnScroll } = useContainerContext()

  const handleLoadmore = (target, stageId) => {
    if (target.scrollTop + target.clientHeight >= target.scrollHeight) {
        handleOnScroll(stageId)
        console.log('loadmore');
    }
  }

  return (
    <div className='workflow-desktop'>
      {listStages.map((stage) => {
          return (
              <div className='column' key={stage._id} onScroll={({target}) => handleLoadmore(target, stage._id)}>
                  <div className='title d-flex justify-content-between align-items-center crop-text' style={{ backgroundColor: `${stage.color}` }}>
                      <div className='crop-text'>{stage.name}</div>
                  </div>
                  <Element stage={stage} />
              </div>
          );
      })}
    </div>
  )
}

export default WorkflowDesktop