import { Image } from 'react-bootstrap'
import userDefaultLogo from '../../assets/images/user_default.png'

const CompanyLogo = ({ logo, isBiggerThanMD, className }) => {
	const style = {
		width: isBiggerThanMD ? '200px' : '78px',
		height: isBiggerThanMD ? '80px' : '78px',
		marginRight: isBiggerThanMD ? '2.5rem' : 0,
		borderRadius: isBiggerThanMD ? 'unset' : '50%',
		overflow: 'hidden',
	}

	return (
		<div style={style} className={`shadow ${className}`}>
			<Image
				className='w-100 rounded-xs-circle w-100 h-100'
				src={logo || userDefaultLogo}
				style={{ objectFit: 'contain' }}
			/>
		</div>
	)
}

export default CompanyLogo
