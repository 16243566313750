import { get } from 'lodash'
import { useRef } from 'react'
import { Button, Form } from 'react-bootstrap'
import { FormInput } from '../formControl'
import SearchIcon from '../../assets/images/icons/search-icon.svg';
import './index.scss';

const SearchBox = ({ isLabel = true, onChangeMode = false, onChange, name = 'search', ...otherProps }) => {
  let timer = null
  const formRef = useRef()
  const inputRef = useRef()

  const handleSearch = (e) => {
    e.preventDefault()
    if (!onChangeMode) return
		if (timer) clearTimeout(timer)
		timer = setTimeout(() => {
      formRef.current?.click()
      inputRef.current?.blur()
		}, 700);
  }

  const search = (e) => {
    e.preventDefault()
    const value = get(e.target.elements, `${name}.value`, '')
    onChange(value)
  }

  return (
    <Form onSubmit={search} className='block-search'>
      <Button type='submit' ref={formRef} className='d-none'/>
      <img className="icon" src={SearchIcon} alt="" />
      <FormInput
        ref={inputRef}
        style={{ minHeight: '45px' }}
        className='border-0 bg-white shadow-sm form-input'
        id='search-box'
        name={name}
        label={isLabel ? () => (
          <small className='text-secondary fs-sub text-uppercase fw-bold'>
            Search
          </small>
        ) : null}
        onChange={handleSearch}
        placeholder='Search...'
        {...otherProps}
      />
    </Form>
    
  )
}

export default SearchBox
