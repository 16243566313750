import { cloneElement, isValidElement } from 'react'

const HeaderCell = ({ column, sticky = {} }) => {
	const { header } = column
	return (
		<th style={sticky} className={column.className}>
			{isValidElement(header)
				? cloneElement(header, {
						column,
				  })
				: header}
		</th>
	)
}

export default HeaderCell
