import { Container } from 'react-bootstrap'

import WorkFlow from '../components/presentations/workFlow'
import { useBreakpoint } from '../hooks'
import Layout from '../layout'

const WorkFlowContainer = () => {
	const { BREAKPOINT, screenBiggerThan} = useBreakpoint()
	const isBiggerThanXL = screenBiggerThan(BREAKPOINT.XL)

	return (
		<Layout>
			<Container fluid={!isBiggerThanXL}>
				<WorkFlow />
			</Container>
		</Layout>
	)
}

export default WorkFlowContainer
