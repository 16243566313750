import React from 'react'
import { Link } from 'react-router-dom'
import { FaChevronRight, FaCheck } from 'react-icons/fa'
import clsx from 'clsx'

import { numberFormatCurrency } from '../../../utils/helpers'
import useContainerContext from '../../containerContext'
import Table from '../../table'
import { formatToLocalTime } from '../../../utils/dates'
import { PAYMENT_TEXT } from '../../../core/constants/filter'

const TableMobile = () => {
	const { data = [], state, setState } = useContainerContext()
	return (
		<Table
			data={data}
			rowKey='_id'
			state={state}
			setState={setState}
			className='primary'
			borderless
		>
			<Table.Column
				dataKey='_id'
				render={(_id, { rowData }) => {
					const {
						company,
						number,
						payment,
						// currency = 'usd',
						total = 0,
						purchaseOrder,
						dateCreated,
						client,
					} = rowData					
					const { name } = company
					const { paid } = payment
					const active = client?._id?.group?.companyOverride?.active
					const nameClient = client?._id?.group?.companyOverride?.name
					return (
						<div className='px-4 py-3 rounded'>
							<div className='d-flex justify-content-between'>
								<div>
								<h5 className='fw-bold'>{active ? nameClient : name}</h5>
									<strong className='fs-base'>
										{numberFormatCurrency(company?.currency || 'usd', total)}
									</strong>
								</div>
								<div>
									<Link to={`/invoices/${_id}`}>
										<FaChevronRight />
									</Link>
								</div>
							</div>

							<div className='fs-base mt-3'>
								<strong>Date: </strong> {formatToLocalTime(dateCreated)}
								<strong className='d-block'>Invoice #: {number}</strong>
								<div className='d-flex justify-content-between'>
									<strong>PO Number: #{purchaseOrder}</strong>
									<div
										className={clsx(
											'text-white rounded-pill d-flex align-items-center px-4 fw-bold',
											{
												'bg-light': paid === 'unpaid',
												'bg-success': paid === 'paid',
												'bg-warning': paid === 'partial',
											},
										)}
										style={{ minHeight: '24px' }}
									>
										{paid === 'paid' && <FaCheck className='text-white me-2' />}
										<span className='mx-auto text-capitalize'>
											{PAYMENT_TEXT[paid.toUpperCase()]}
										</span>
									</div>
								</div>
							</div>
						</div>
					)
				}}
			/>
		</Table>
	)
}
export default TableMobile
