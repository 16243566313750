import { forwardRef, useEffect, useRef, useState } from 'react'
import { Button, Dropdown } from 'react-bootstrap'

import { FaChevronDown } from 'react-icons/fa'
import { useOnClickOutSide } from '../../hooks'
import useAppContext from '../app'
import { FormCheck } from '../formControl'

const Toggle = forwardRef(({ children, onClick }, ref) => (
	<div
		className='text-primary p-0'
		ref={ref}
		role='button'
		onClick={e => {
			e.preventDefault()
			onClick(e)
		}}
	>
		{children}
	</div>
))

const SortDropdown = ({ listSort = [], sorted, onSort }) => {
	const { setIsBackdrop } = useAppContext()
	const [show, setShow] = useState(false)
	const dropDownRef = useRef(null)

  const originSort = listSort.find(sort => sort.sortKey === sorted.sortBy)

	const [currentSort, setCurrentSort] = useState(sorted)

  useEffect(() => {
    setCurrentSort(sorted)
  }, [sorted])

	useEffect(() => {
		setIsBackdrop(show)
	}, [setIsBackdrop, show])

	const handleSort = () => {
		onSort(currentSort)
    setShow(false)
	}

	useOnClickOutSide(dropDownRef, () => {
		setTimeout(() => {
			setShow(false)
			setCurrentSort(sorted)
		}, 200)
	})

	return (
		<span className='' ref={dropDownRef}>
			<Dropdown show={show}>
				<Dropdown.Toggle as={Toggle}>
					<Button
						variant='default'
						className='text-primary d-flex align-items-center'
						onClick={() => {
							setShow(!show)
						}}
					>
						<span className='fs-base fw-bold text-uppercase'>{originSort.label || 'SORT BY'}</span>
						<FaChevronDown className='ms-2' />
					</Button>
				</Dropdown.Toggle>
				<Dropdown.Menu alignRight className='p-3 border-0 shadow' style={{ minWidth: '200px' }}>
					<strong className='fs-base'>Sort by:</strong>
					<div className='pt-2 pb-1'>
						<div className='pb-3 mb-3 border-bottom'>
							{listSort.map(({ label, sortKey }) => {
								return (
									<div className='d-flex mb-1' key={sortKey}>
										<div className='flex-grow-1'>{label}</div>
										<div className='d-inline-block'>
											<FormCheck
												checked={currentSort.sortBy === sortKey}
                        readOnly={currentSort.sortBy === sortKey}
												onChange={() => {
													setCurrentSort(prev => ({ ...prev, sortBy: sortKey }))
												}}
											/>
										</div>
									</div>
								)
							})}
						</div>

						<div className='d-flex mb-1'>
							<div className='flex-grow-1'>Ascending</div>
							<div className='d-inline-block'>
								<FormCheck
									checked={currentSort.sortDirection === 'asc'}
                  readOnly={currentSort.sortDirection === 'asc'}
									onChange={() => {
										setCurrentSort(prev => ({ ...prev, sortDirection: 'asc' } ))
									}}
								/>
							</div>
						</div>
						<div className='d-flex'>
							<div className='flex-grow-1'>Descending</div>
							<div className='d-inline-block'>
                <FormCheck
									checked={currentSort.sortDirection === 'desc'}
                  readOnly={currentSort.sortDirection === 'desc'}
									onChange={() => {
										setCurrentSort(prev => ({ ...prev, sortDirection: 'desc' } ))
									}}
								/>
							</div>
						</div>
					
            <div className='text-end'>
              <Button
                variant='default'
                className='fs-base text-primary p-0 mt-3 fw-bold'
                onClick={handleSort}
              >
                DONE
              </Button>
            </div>
          </div>
				</Dropdown.Menu>
			</Dropdown>
		</span>
	)
}

export default SortDropdown
