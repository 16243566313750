import qs from 'qs'
import { useLocation } from 'react-router-dom'

import routes from '../core/constants/routes'

const {
  INVOICES,
  ESTIMATES
} = routes

export const clearToken = () => {
  localStorage.removeItem('access_token')
  localStorage.removeItem('clientPortalCompany')
  localStorage.removeItem('clientPortalUser')
}

const useStorageApp = () => {
  const location = useLocation()

  const pathname = location.pathname
  const query = qs.parse(location.search.slice(1))

  const accessToken = localStorage.access_token
	const clientPortalCompany = localStorage.clientPortalCompany
	const clientPortalUser = localStorage.clientPortalUser

	const isLogin = !!(accessToken && clientPortalCompany && clientPortalUser)

  if (!isLogin) clearToken()

  if (pathname.includes(INVOICES) || pathname.includes(ESTIMATES)) {
    if ((pathname !== INVOICES || pathname !== ESTIMATES) && query.token) {
      localStorage.removeItem('clientPortalCompany')
      localStorage.removeItem('clientPortalUser')
      localStorage.setItem('access_token', query.token)
    }
  }

  return {
    token: localStorage['access_token'],
    clientPortalCompany: localStorage.clientPortalCompany ? JSON.parse(localStorage.clientPortalCompany) : null,
    clientPortalUser: localStorage.clientPortalUser ? JSON.parse(localStorage.clientPortalUser) : null
  }
}

export default useStorageApp
