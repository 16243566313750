import { useLocation, Redirect } from 'react-router-dom'
const Logout = () => {
	const location = useLocation()
	localStorage.removeItem('access_token')
	localStorage.removeItem('clientPortalCompany')
	localStorage.removeItem('clientPortalUser')

	return (
		<Redirect
			to={{
				pathname: '/login',
				state: {
					form: location.pathname,
				},
			}}
		/>
	)
}

export default Logout
