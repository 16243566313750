import { Image, Container, Row, Col } from 'react-bootstrap'
import { useLocation, Link } from 'react-router-dom'
import qs from 'qs'

import { useApi, useLoading } from '../../../hooks'
import { useQuery } from 'react-query'
import { LoginForm } from '../../loginForm'
import LayoutAuth from '../../LayoutAuth'

const Verify = () => {
	const location = useLocation()
	const { mainService } = useApi()
	const query = qs.parse(location.search.slice(1))

	const { isLoading, isSuccess, isError } = useQuery(
		['verify', query?.token],
		() => {
			if (!query.token) throw new Error('user not found')
			return mainService.verify(query.token)
		},
		{
			refetchOnWindowFocus: false,
		},
	)

	if (isLoading) {
		localStorage.removeItem('access_token')
		localStorage.removeItem('clientPortalCompany')
		localStorage.removeItem('clientPortalCompany')
	}

	useLoading(isLoading)

	return (
		<LayoutAuth>
			<Container style={{ maxWidth: '350px' }} className='flex-grow-1'>
				<Row className='py-5 text-center login h-100 align-items-center'>
					{isSuccess && (
						<Col sm={12} className='my-0 mx-auto'>
							<strong className='d-block'>Success! Your email is confirmed.</strong>
							<p>Please log in to your account</p>

							<LoginForm />
						</Col>
					)}

					{isError && (
						<Col sm={12} className='my-0 mx-auto'>
							<strong className='d-block'>
								<span className='text-uppercase fw-bold'>Failure!</span> Your email cannot be verified.
								<p>
									<Link to='/login'>Back to Login</Link>
								</p>
							</strong>
						</Col>
					)}

				</Row>
			</Container>
		</LayoutAuth>
	)
}

export default Verify
