import clsx from 'clsx'
import { FaChevronRight } from 'react-icons/fa'
import { IoIosCheckmarkCircle } from 'react-icons/io'
import { Link } from 'react-router-dom'
import { formatToLocalTime } from '../../../utils/dates'
import { numberFormatCurrency } from '../../../utils/helpers'

import useContainerContext from '../../containerContext'
import Table from '../../table'

const TableMobile = () => {
	const { data = [], state, setState } = useContainerContext()
	return (
		<Table
			data={data}
			rowKey='_id'
			state={state}
			setState={setState}
			className='primary'
			fixed
			borderless
		>
			<Table.Column
				dataKey='_id'
				id='data-row'
				className='text-start'
				render={(_id, { rowData: record }) => {
					const active = record.client?._id?.group?.companyOverride?.active
					const name = record.client?._id?.group?.companyOverride?.name

					const { payment = {}, totalApproved } = record;
					const { amount = 0 } = payment

					const isPaid = totalApproved > 0 && amount >= totalApproved
					return (
						<div className='p-4'>
							<div className='d-flex'>
								<div className='flex-grow-1'>
								<strong className='d-block'>
									{active ? name : record.company.name}
								</strong>									
									<small className='d-block fw-bold'>
										{numberFormatCurrency(
											record.company?.currency || 'usd',
											record.total || 0,
										)}
									</small>
								</div>
								<div className='text-end p-0' style={{ width: '50px' }}>
									<Link to={`/estimates/${_id}`}>
										<FaChevronRight />
									</Link>
								</div>
							</div>

							<div className='d-flex pt-4'>
								<div className='flex-grow-1'>
									<div className='fs-small'>
										<strong>Date: </strong>
										<span>{formatToLocalTime(record.dateCreated)}</span>
									</div>
									<div>
										<strong>Estimate #: </strong>
										<span>{record.number}</span>
									</div>
								</div>
								<div
									className='text-end align-self-end p-0'
									style={{ width: '50px' }}
								>
									<IoIosCheckmarkCircle
										size={25}
										className={clsx({
											'text-success': isPaid,
											'text-gray': !isPaid,
										})}
									/>
								</div>
							</div>
						</div>
					)
				}}
			/>
		</Table>
	)
}

export default TableMobile
