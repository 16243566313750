import React, { useRef, useState } from 'react'
import { Button, Image } from 'react-bootstrap'
import { GrClose } from 'react-icons/gr'

import ResetIcon from '../../../assets/images/icons/reset.svg'
import { TIER } from '../../../core/constants1'
import useContainerContext from '../../containerContext'
import './Signature.scss'

const Signature = ({}) => {
  	const [imgSignature, setImgSignature] = useState('');
	const { data, approveEst } = useContainerContext();
	const { company: { signature, rules: companyRules, stripe: { tier } }, client: { _id: { rules: clientRules } }, disclosures } = data;
	const signatureRef = useRef();
	const [isEnableButton, setIsEnableButton] = useState(true);
	const hasDisclosure = disclosures?.length > 0;
	const isActiveButtonSkip = isActiveSkipButton(companyRules, clientRules, hasDisclosure, tier)

	function capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	};

	const handleApproveEst = () => {
		const estimate = {
			...data,
			signature: imgSignature,
			status: false,
			disclosures: data.disclosures.map(discl => ({
				...discl,
				active: true,
			})),
		};
		approveEst.mutate(estimate);
	};

	const handleSkip = () => {
		const estimate = {
			...data,
			signature: '',
			status: false,
			disclosures: data.disclosures.map(discl => ({
				...discl,
				active: true,
			})),
		};
		approveEst.mutate(estimate);
	};

	const handleClearValue = () => {
		signatureRef.current.value = '';
		setIsEnableButton(true);
	};

	const handleInputSignatureChange = (event) => {
		const text = capitalizeFirstLetter(signatureRef.current.value);
		let tCtx = document.getElementById('textCanvas').getContext('2d');

		tCtx.font = '36px "La Belle Aurore", "cursive"';
		tCtx.canvas.width = tCtx.measureText(text).width * 2;
		tCtx.canvas.height = 80;
		tCtx.font = '36px "La Belle Aurore", "cursive"';
		
		tCtx.fillText(text, tCtx.canvas.width / 4, 55);
		
		setImgSignature(tCtx.canvas.toDataURL());

		setIsEnableButton(event.target.value === '');
	};

	return (
		<div className='wrapper'>
			<h4 className='fw-bold text-center mt-5'>Signature</h4>

			<div className='bg-white signature position-relative mt-5'>
				<Button
					className='btn-clear fs-sub fw-bold text-uppercase py-3 mt-3 text-primary text-uppercase d-flex justify-content-end align-items-center'
					variant='white'
					onClick={handleClearValue}
				>
					<span className='mr-1'>Clear All</span>
					<Image src={ResetIcon} />
				</Button>

				<div className='mx-auto input-body'>
					<div className='border-bottom input-wrapper mx-auto'>
						<GrClose role='button' onClick={handleClearValue} />
						{/* text to image */}
						<canvas style={{ display: 'none', width: 200, height: 80 }} id='textCanvas' />
						<img src={imgSignature} style={{ display: 'none', height: 80 }} className='mx-auto' />
						<input
							ref={signatureRef}
							className='signature-input'
							placeholder='Type your name to sign...'
							onChange={handleInputSignatureChange}
						/>
					</div>
				</div>
			</div>

			<div className='mt-5'>
				<span>
					<b>DISCLAIMER: </b>
					{signature.estimate}
				</span>
				<div className='w-50 mx-auto mt-3'>
					<Button
						className={`fs-sub fw-bold text-uppercase w-100 py-3 mt-3 ${
							isEnableButton && 'btn-dissable'
						}`}
						disabled={isEnableButton}
						onClick={handleApproveEst}
					>
						Save
					</Button>
					<Button
						className={`fs-sub fw-bold text-uppercase w-100 py-3 mt-3 ${
							!isActiveButtonSkip && 'text-primary'
						}`}
						variant='white'
						onClick={handleSkip}
						disabled={isActiveButtonSkip}
					>
						Skip
					</Button>
				</div>
			</div>
		</div>
	)
}

export default Signature

function isActiveSkipButton (companyRules, clientRules, hasDisclosure, tier) {
	const isAccessDisclosure = canAccessDisclosure(tier);

	if (!isAccessDisclosure) {
		return clientRules.active ? clientRules.estimates?.signature?.active : companyRules?.estimates?.signature?.active;
	}
	if (isAccessDisclosure) {
		if (hasDisclosure) {
			return true;
		} else {
			return clientRules.active ? clientRules.estimates?.signature?.active : companyRules?.estimates?.signature?.active;
		}
	}
}

function canAccessDisclosure(tier) {
	return tier === TIER.PRO || tier === TIER.ENTERPRISE
}

function fitTextToHeight(context, text, x, y, height) {
	let fontSize = 36;
	context.font = fontSize + "px Arial";
	let metrics = context.measureText(text);
	while (metrics.actualBoundingBoxAscent > height) {
	  fontSize--;
	  context.font = fontSize + "px Arial";
	  metrics = context.measureText(text);
	}
	context.fillText(text, x, y);
}
  