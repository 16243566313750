import { GoMail } from 'react-icons/go'

const CompanyEmail = ({ email }) => {
	return (
		<div className='mt-2'>
			<GoMail className='me-1 text-primary' />
			{email}
		</div>
	)
}

export default CompanyEmail
