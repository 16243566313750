import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Image } from 'react-bootstrap';

import useContainerContext from '../../../containerContext';
import IconBack from '../../../../assets/images/icons/IconBack.svg'
import './index.scss';

const ModalDisclosures = ({ isShow, closeModal }) => {
    const { data: { disclosures }, showSignature } = useContainerContext();
    const [isShowDiscDetail, setIsShowDiscDetail] = useState(false);
    const [discDetail, setDiscDetail] = useState({});

    const showDetailDisc = (disc) => {
        setIsShowDiscDetail(true);
        setDiscDetail(disc);
    }

    return (
        <div>
            <Modal show={isShow} onHide={closeModal} dialogClassName='modal-disclosure' backdrop='static' centered>
                <Modal.Header closeButton className='mx-3 mt-3'>
                    <h4 className='mb-3 font-weight-bold'>DISCLAIMERS</h4>
                    {!isShowDiscDetail ? <p>CLICK VIEW TO READ MORE ABOUT DISCLOSURE LANGUAGE</p> : null}

                    {isShowDiscDetail ? <Image onClick={() => setIsShowDiscDetail(false)} src={IconBack} className='icon-back' /> : null}
                </Modal.Header>
                <Modal.Body>
                    {isShowDiscDetail ? <Disclosure disclosure={discDetail} /> : (
                        <div className='wrapper'>
                            <div className='list'>
                                <Row className='px-4'>
                                    {disclosures?.map(disc => (
                                        <Col key={disc._id} md={6} className='py-2 px-4'>
                                            <div className='d-flex align-items-center'>
                                                <div className='d-flex align-items-center disclosure__item'>
                                                    <input type='checkbox' checked={true} disabled={true} />
                                                    <span className='ml-1 crop-text'>{disc.name}</span>
                                                </div>
                                                <span className='text-primary view' onClick={() => showDetailDisc(disc)}>VIEW</span>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                            <div className='w-100 d-flex justify-content-center'>
                                <Button
                                    className='btn-agree'
                                    variant='primary'
                                    onClick={() => {
                                        closeModal();
                                        showSignature();
                                    }}
                                >
                                    I AGREE
                                </Button>
                            </div>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default ModalDisclosures;

const Disclosure = ({ disclosure: { name, disclosure } }) => {
    return (
        <div className='disclosure__detail'>
            <h4 className='text-center'>{name}</h4>
            <p>{disclosure}</p>
        </div>
    );
};