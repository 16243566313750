import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

const useYupForm = (schema, defaultValues) => {
	return useForm({
		resolver: yupResolver(schema),
		defaultValues,
		mode: 'onChange',
		reValidateMode: 'onChange',
	})
}

export default useYupForm
