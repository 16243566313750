import * as routesUtils from '../utils/RoutesUtils'
import { API } from '../core/app.routes'
import httpClient from '../core/httpClient'

const invoiceService = () => {
	return {
		getMany: params => {
			const path = routesUtils.getApiPath(API.INVOICES, params)
			return httpClient.get(path)
		},
		get: invoiceId => {
			const path = routesUtils.getApiPath(API.INVOICE_DETAIL, { invoiceId })
			return httpClient.get(path)
		},
		edit: params => {
			const { invoiceId, ...other } = params
			const path = routesUtils.getApiPath(API.INVOICE_DETAIL, { invoiceId })
			return httpClient.put(path, other)
		},
	}
}

export default invoiceService
