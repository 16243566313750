import { Col, Container, Image, Row } from 'react-bootstrap'
import LayoutAuth from '../../LayoutAuth'
import { LoginForm } from '../../loginForm'

const Login = () => {
	return (
		<LayoutAuth>
			<Container style={{ maxWidth: '400px' }}  className='flex-grow-1'>
				<Row className='py-5 text-center login h-100 align-items-center'>
					<Col sm={12} className='my-0 mx-auto'>
						<div className='fs-small'>
							<h5 className='fw-bold d-block'>
								Login
							</h5>
						</div>
						<LoginForm isRedirect />
					</Col>
				</Row>
			</Container>
		</LayoutAuth>
	)
}

export default Login
