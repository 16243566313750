import clsx from 'clsx'
import { get } from 'lodash-es'
import { Button } from 'react-bootstrap'
import { IoWarningOutline } from 'react-icons/io5'
import { numberFormatCurrency } from '../../../utils/helpers'
import useContainerContext from '../../containerContext'

const GroupButton = ({ isBiggerThanMD, isShowLinkToPay }) => {
	const { toPayment, data } = useContainerContext()
	const { total, payment, currency, company: { stripe }, status } = data

	const paymentAmount = get(payment, 'amount', 0.00)
	const balance = total - paymentAmount === 0 ? 0.00 : total - paymentAmount

	return (
		<div
			className={clsx({
				'mt-5': !isBiggerThanMD,
			})}
		>
			{payment.paid !== 'paid' && (
				<>
					<Button
						variant='warning'
						className='fs-small mx-auto text-white fw-bold py-2 px-4 text-uppercase rounded-pill d-flex align-items-center'
					>
						<IoWarningOutline size={20} className='me-2' />
						Balance Due {numberFormatCurrency(currency, balance)}
					</Button>
					{isShowLinkToPay && stripe && stripe.userID && (
						<Button
							className='fs-sub fw-bold text-uppercase w-100 py-3 mt-3'
							onClick={toPayment}
							>
							Pay invoice
						</Button>
					)}
				</>
			)}

			{payment.paid === 'paid' && (
				<Button
					variant='success'
					className='fs-small mx-auto text-white fw-bold py-2 px-4 text-uppercase rounded-pill d-flex align-items-center justify-content-center'
					style={{ minWidth: '100px' }}
				>
					Fully Paid
				</Button>
			)}
		</div>
	)
}

export default GroupButton
