import { isEmpty } from "lodash"

export function currencySymbol(currency) {
	switch (currency) {
		case 'gbp':
			currency = '\u00A3'
			break
		case 'chf':
			currency = 'CHF'
			break
		case 'eur':
			currency = '\u20ac'
			break
		default:
			currency = '$'
			break
	}
	return currency
}

export function getAvatarName(name) {
	const arr = name.split(' ')
	const regex = /^[\w\d]+$/
	let tmp = []
	arr.map(str => {
		if (regex.test(str)) {
			tmp[tmp.length] = str
		}
		return ''
	})
	if (tmp.length === 0) {
		tmp = arr
	}

	let result = ''
	if (tmp.length > 0) {
		result = tmp[0].charAt(0)
		if (tmp[1]) {
			result += tmp[1].charAt(0)
		} else {
			result += tmp[0].charAt(1)
		}
	}
	return result.toUpperCase()
}

export const formatCurrency = (currency, value) => {
	// return `${currencySymbol(currency)}${(Math.round(value * 100) / 100).toFixed(2)}`;
	return `${currencySymbol(currency)}${((value * 100) / 100).toFixed(2)}`
}

export const numberFormatCurrency = (currency, value) => {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency,
		minimumFractionDigits: 2,
	})
	return formatter.format(value)
}

export const hasPDR = (vehicle) => {
	return !isEmpty(vehicle.pdr) && (!isEmpty(vehicle.pdr.panels) || !isEmpty(vehicle.pdr.notes) || vehicle.pdr.flatRate > 0)
}

export const hasHail = (vehicle) => {
	return !isEmpty(vehicle.hail);
}
export const hasParts = (vehicle) => {
	const { parts } = vehicle;
	return parts && parts.length > 0;
}

export const hasGlass = (vehicle) => {
	const { glass } = vehicle;
	if ((glass?.amount > 0 && glass?.glassPricing === 0) || (glass?.panels && glass?.panels.length > 0)) {
		return true;
	}
	return false;
}


export const hasDetail = (vehicle) => {
	const hasPackage = (packages) => {
		return packages.some((pkg) => pkg.active);
	};

	const hasNote = (notes) => {
		return notes.some((note) => note.active);
	};

	const hasService = (services) => {
		return services.some((service) => service.active);
	};

	return (vehicle?.detail?.packages && hasPackage(vehicle?.detail?.packages)) ||
		(vehicle?.detail?.notes && hasNote(vehicle?.detail?.notes)) ||
		(vehicle?.detail?.services && hasService(vehicle?.detail?.services))
}

const detailSelected = detail => {
	const packagesName = []
	const servicesName = []

	detail.packages.forEach(pck => {
		packagesName.push(pck.name)
	})
	detail.services.forEach(service => {
		servicesName.push(service.name)
	})

	return {
		packagesName,
		servicesName,
	}
}

export const hasInterior = (vehicle) => {
	const { interior } = vehicle;
	return (interior?.amount > 0 || ((interior?.panels && interior?.panels.length > 0) && interior?.panels.some((panel) => panel.active)))
} 


export const hasTint = (vehicle) => {
	return vehicle?.tint?.amount > 0 || 
		(vehicle?.tint?.notes && vehicle?.tint?.notes.length > 0) ||
		(vehicle?.tint?.panels && vehicle?.tint?.panels.length > 0);
}

export const hasRi = (vehicle) => {
	const { removeInstall } = vehicle;
	const hasRIPanelItem = (items) => {
		return items.some(item => item.active)
	};

	if (!isEmpty(removeInstall?.panels)) {
		return removeInstall.panels.some(panel => hasRIPanelItem(panel.items));
	}
	return false;
}


export const hasWheels = (vehicle) => {
	const { wheels } = vehicle;
	const hasRim = (rim) => {
		return rim?.rimSpoke > 0 ||
			!isEmpty(rim?.wheelId) ||
			!isEmpty(rim?.rimColor) ||
			!isEmpty(rim?.rimFinish) ||
			!isEmpty(rim?.rimClear)
	};

	const hasHollander = (hollander) => {
		return !isEmpty(hollander?.hollanderNumber) ||
			!isEmpty(hollander?.tireBrand) ||
			!isEmpty(hollander?.tireModel) ||
			!isEmpty(hollander?.tireSize)
	};

	return !isEmpty(wheels) || !isEmpty(wheels?.internalNotes) || !isEmpty(wheels?.notes) || wheels?.sendOutCost > 0 || wheels?.amount > 0 || (wheels?.damageStatus?.toUpperCase() === 'unrepairable'.toUpperCase()) ||
		hasHollander(wheels?.hollander) || hasRim(wheels?.rim) || !isEmpty(wheels?.driveDropoff) || !isEmpty(wheels?.drivePickup) || !isEmpty(wheels?.services);
}


export const hasPackage = (vehicle) => {
	if (vehicle.package) { // package feature
		return vehicle.package?.amount > 0 || vehicle.package?.active;
	}
	return false;
}


export const hasPaint = (vehicle) => {
	const { paintAndBody } = vehicle;

	const hasDamage = (panel) => {
		return !isEmpty(panel?.items) || !isEmpty(panel?.notes) || panel?.amount > 0;
	}

	const getTotal = (paintAndBody) => {
		let total = 0;
		if (paintAndBody?.pricing === 0) { // === 0  because same logic on app, PricingType.PAINT_FLAT.getCode() === 0
			return paintAndBody?.amount;
		} 
		paintAndBody?.panels.forEach((panel) => {
			if (hasDamage(panel)) {
				total += panel.amount;
			}
		});

		return total;
	}

	return paintAndBody?.panels.some(panel => hasDamage(panel)) || getTotal(paintAndBody) > 0;
}


export const hasPriceADent = (vehicle) => {
	const hasData = (panel) => {
		return !isEmpty(panel.dents) || panel?.smallDing?.quantity > 0;
	};

	return vehicle.priceADent?.panels.some(panel => hasData(panel));
}


export const hasMisc = (vehicle) => {
	return vehicle.misc && vehicle.misc.length > 0;
}

const miscSelected = misc => {
	const servicesName = []
	misc.forEach(item => {
		servicesName.push(item.name)
	})

	return {
		servicesName,
	}
}

export const hasPPF = (vehicle) => {
	const { ppfVinyl } = vehicle;

	return ppfVinyl?.amount > 0 ||
		(!isEmpty(ppfVinyl?.packages) && !isEmpty(ppfVinyl?.packages?.items)) ||
		(!isEmpty(ppfVinyl?.panels) && !isEmpty(ppfVinyl?.panels?.items)) ||
		(!isEmpty(ppfVinyl?.materials) && !isEmpty(ppfVinyl?.materials?.items)) ||
		(!isEmpty(ppfVinyl?.notes));
}


export const hasCarADAS = (vehicle) => {
	const hasVehicleInfo = (vehicleInformation) => {
		if (vehicleInformation.documents && vehicleInformation.documents.some((document) => document.files && document.files.length > 0)) {
			return true;
		}

		return vehicleInformation.vehicleItems && 
			vehicleInformation.vehicleItems.some((vehicleItem) => vehicleItem.subItems && vehicleItem.subItems.some((subItem) => subItem.status))
	}

	return (vehicle.carADAS.documents && vehicle.carADAS.documents.length > 0) || 
		(vehicle.carADAS.packages && vehicle.carADAS.packages.length > 0) || 
		(vehicle.carADAS.photos && vehicle.carADAS.photos.length > 0 ) || 
		(vehicle.carADAS.documents && vehicle.carADAS.documents.length > 0) || 
		(vehicle.carADAS.services && vehicle.carADAS.services.length > 0) || 
		(vehicle.carADAS.vehicleInformation && hasVehicleInfo(vehicle.carADAS.vehicleInformation)) || false;
}

export const getServiceList = (vehicle) => {
	let services = [];
	// calculate totals for each service
	const pdr = hasPDR(vehicle);
	const hail = hasHail(vehicle);
	const parts = hasParts(vehicle);
	const glass = hasGlass(vehicle);
	const detail = hasDetail(vehicle);
	const interior = hasInterior(vehicle);
	const tint = hasTint(vehicle);
	const ri = hasRi(vehicle);
	const wheels = hasWheels(vehicle);
	const pkg = hasPackage(vehicle);
	const paint = hasPaint(vehicle);
	const priceADent = hasPriceADent(vehicle);
	const misc = hasMisc(vehicle);
	const PPF = hasPPF(vehicle);
	const carADAS = hasCarADAS(vehicle);

	if (pdr) services.push('PDR');
	if (hail) services.push('Hail');
	if (priceADent) services.push('Price A Dent');
	if (ri) services.push('R&I/R&R');
	if (parts) services.push('Parts');
	if (paint) services.push('Paint & Body');
	if (wheels) services.push('Wheels');
	if (glass) services.push('Glass');
	if (tint) services.push('Tint');
	if (misc) {
		const { servicesName } = miscSelected(vehicle.misc)
		services = services.concat(servicesName)
	}
	if (detail) {
		const { packagesName, servicesName } = detailSelected(vehicle.detail)
		services = services.concat(packagesName).concat(servicesName)
	}
	if (interior) services.push('Interior');
	if (PPF) services.push('PPF / Vinyl');
	if (pkg) services.push('Package');
	if (carADAS) services.push('CAR ADAS');

	return services;
};
