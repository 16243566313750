import useContainerContext from '../../containerContext'
import FilterGroup from '../../filterGroup'

import { FilterPaymentStatus, FilterCompanies } from '../../filterMobile'
const FilterMobile = () => {
	const {
		state: {
			query: { filter },
		},
		setState,
		stateOrigin,
	} = useContainerContext()

	const onFilter = filterData => {
		setState(prev => {
			return {
				...prev,
				query: {
					...prev.query,
					filter: filterData,
				},
			}
		})
	}

	return (
		<FilterGroup currentFilter={filter} originFilter={stateOrigin.query.filter} onFilter={onFilter}>
			{(filterState, setFilter, show) => {
				return (
					<>
						<div className='mt-2'>
							<FilterPaymentStatus
								isCloseMenu={!show}
								value={filterState.payment}
								onChange={payment => {
									setFilter(prev => ({ ...prev, payment }))
								}}
							/>
						</div>

						<div className='mt-2'>
							<FilterCompanies
								isCloseMenu={!show}
								value={filterState.vendor}
								onChange={vendor => {
									setFilter(prev => ({ ...prev, vendor }))
								}}
							/>
						</div>
					</>
				)
			}}
		</FilterGroup>
	)
}

export default FilterMobile
